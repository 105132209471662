const godsOfEvil = require('./../constants/godsOfEvil')
const {ALIGNMENT_LE} = require('./../../aligmentList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_DEEP_DUERRA} = require('./../../godIdList')
const {PANTHEON_MORNDINSAMMAN} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  DOMAIN_ARCANA,
  DOMAIN_WAR,
} = require('./../../domainList')

module.exports = {
  id: GOD_DEEP_DUERRA,
  nameEn: 'Deep Duerra',
  nameShort: {
    nominative: 'Глубинная Дуэрра',
    genitive: 'Глубинной Дуэрры',
    dative: 'Глубинной Дуэрре',
    accusative: 'Глубинную Дуэрру',
    instrumental: 'Глубинной Дуэррой',
    prepositional: 'Глубинной Дуэрре',
  },
  alignmentId: ALIGNMENT_LE,
  symbolList: 'Череп иллитида',
  description: godsOfEvil,
  domainIdList: [DOMAIN_ARCANA, DOMAIN_WAR],
  pantheonId: PANTHEON_MORNDINSAMMAN,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_SCAG,
    page: 23,
  },
}
