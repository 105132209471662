const homeKeepers = require('./../constants/homeKeepers')
const {ALIGNMENT_LG} = require('./../../aligmentList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_CYRROLLALEE} = require('./../../godIdList')
const {PANTHEON_HIN} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  DOMAIN_LIFE,
  DOMAIN_PEACE,
  DOMAIN_UNITY,
} = require('./../../domainList')

module.exports = {
  id: GOD_CYRROLLALEE,
  nameEn: 'Cyrrollalee',
  nameShort: {
    nominative: `Цирроллали`,
    genitive: `Цирроллали`,
    dative: `Цирроллали`,
    accusative: `Цирроллали`,
    instrumental: `Цирроллали`,
    prepositional: `Цирроллали`,
  },
  godOfWhat: [
    `дома`,
    `очага`,
    `уюта`,
    `гостеприимства`,
    `доверия`,
    `ремёсел`,
  ],
  alignmentId: ALIGNMENT_LG,
  symbolList: `Открытая дверь`,
  description: homeKeepers,
  domainIdList: [DOMAIN_LIFE, DOMAIN_UNITY, DOMAIN_PEACE],
  pantheonId: PANTHEON_HIN,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_SCAG,
    page: 24,
  },
}
