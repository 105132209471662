const goddessOfWisdom = require('./../constants/goddessOfWisdom')
const {ALIGNMENT_CG} = require('./../../aligmentList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_ANGHARRADH} = require('./../../godIdList')
const {PANTHEON_SELDARINE} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  DOMAIN_KNOWLEDGE,
  DOMAIN_LIFE,
  DOMAIN_PEACE,
} = require('./../../domainList')

module.exports = {
  id: GOD_ANGHARRADH,
  nameEn: 'Angharradh',
  nameShort: {
    nominative: `Ангаррад`,
    genitive: `Ангаррад`,
    dative: `Ангаррад`,
    accusative: `Ангаррад`,
    instrumental: `Ангаррад`,
    prepositional: `Ангаррад`,
  },
  godOfWhat: [
    `мудрости`,
  ],
  alignmentId: ALIGNMENT_CG,
  symbolList: `Треугольник с тремя переплетёнными кольцами внутри`,
  description: goddessOfWisdom,
  domainIdList: [DOMAIN_LIFE, DOMAIN_KNOWLEDGE, DOMAIN_PEACE],
  pantheonId: PANTHEON_SELDARINE,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_SCAG,
    page: 24,
  },
}
