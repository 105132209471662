const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_TYR,
} = require('./../../../../godIdList')

const Tyr_Symbol_SCAG_img = require('./../../../../../images/gods/tyr-symbol-SCAG.png')
const Tyr_Symbol_DND3_FnP_img = require('./../../../../../images/gods/tyr-symbol-DND3_FnP.webp')
const Tyr_DND3_FnP_img = require('./../../../../../images/gods/tyr-DND3_FnP.webp')

module.exports = {
  [GOD_TYR]: [
    {
      src: Tyr_Symbol_SCAG_img,
      text: 'Символ Тира',
      source: {
        id: SOURCE_SCAG,
        page: 41,
      },
    },
    {
      src: Tyr_Symbol_DND3_FnP_img,
      text: 'Символ Тира',
      source: {
        id: SOURCE_DND3_FnP,
        page: 81,
      },
    },
    {
      src: Tyr_DND3_FnP_img,
      text: 'Тир Грозноречивый',
      source: {
        id: SOURCE_DND3_FnP,
        page: 82,
      },
    },
  ],
}
