import arrify from 'arrify'
import React from 'react'

import { domainCollection } from '@/constants/domainList'
import { pcSubClassIdByDomainIdCollection, pcSubClassCollection } from '@/constants/pcSubClassList'

import generatePcSubClassPageUrlById from '@/utils/generatePcSubClassPageUrlById'

import DomainListComponent from './DomainListComponent'

const DomainListContainer = ({ domainId }) => {
  if (domainId) {
    const list = arrify(domainId).map(
      id => {
        const pcSubClassId = pcSubClassIdByDomainIdCollection[id]
        const { isReady } = pcSubClassCollection[pcSubClassId]
        const url = isReady
          ? generatePcSubClassPageUrlById(pcSubClassId)
          : ''
        const text = domainCollection[id].name

        return {
          id,
          url,
          text,
        }
      }
    )

    const headerText = list.length === 1
      ? 'Домен'
      : 'Домены'

    return (
      <DomainListComponent
        headerText={headerText}
        list={list}
      />
    )
  }

  return null
}

export default DomainListContainer
