const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_LATHANDER,
} = require('./../../../../godIdList')
const {
  Cleric_of_Lathander_Human_Male_SCAG_data,
  Dawnbringer_cleric_MTG_CLB_data,
} = require('./../../../commonImageCollection')

const Lathander_Symbol_SCAG_img = require('./../../../../../images/gods/lathander-symbol-SCAG.png')
const Lathander_Symbol_DND3_FnP_img = require('./../../../../../images/gods/lathander-symbol-DND3_FnP.webp')
const Lathander_DND3_FnP_img = require('./../../../../../images/gods/lathander-DND3_FnP.webp')

module.exports = {
  [GOD_LATHANDER]: [
    {
      src: Lathander_Symbol_SCAG_img,
      text: 'Символ Латандера',
      source: {
        id: SOURCE_SCAG,
        page: 33,
      },
    },
    {
      src: Lathander_Symbol_DND3_FnP_img,
      text: 'Символ Латандера',
      source: {
        id: SOURCE_DND3_FnP,
        page: 39,
      },
    },
    {
      src: Lathander_DND3_FnP_img,
      text: 'Латандер, Утренний Лорд',
      source: {
        id: SOURCE_DND3_FnP,
        page: 40,
      },
    },
    Cleric_of_Lathander_Human_Male_SCAG_data,
    Dawnbringer_cleric_MTG_CLB_data,
  ],
}
