const {SOURCE_SCAG} = require('./../../sourceList')
const {
  GOD_DEEP_DUERRA,
  GOD_LADUGUER,
  GOD_MORADIN,
} = require('./../../godIdList')

module.exports = {
  header: 'Боги зла',
  text: `[Ладугуэр](GOD:${GOD_LADUGUER}) — покровитель двергаров, бог магии и тех ремёсел, которыми не управляет [Морадин](GOD:${GOD_MORADIN}).

Также почитаема среди двергаров [Глубинная Дуэрра](GOD:${GOD_DEEP_DUERRA}) — богиня завоеваний и силы разума.`,
  source: {
    id: SOURCE_SCAG,
    page: 106,
  },
}
