const shadowGods = require('./../constants/shadowGods')
const {ALIGNMENT_LN} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_UROGALAN} = require('./../../godIdList')
const {PANTHEON_HIN} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  DOMAIN_DEATH,
  DOMAIN_KNOWLEDGE,
} = require('./../../domainList')

module.exports = {
  id: GOD_UROGALAN,
  nameEn: 'Urogalan',
  nameShort: {
    nominative: `Урогалан`,
    genitive: `Урогалана`,
    dative: `Урогалану`,
    accusative: `Урогалана`,
    instrumental: `Урогаланом`,
    prepositional: `Урогалане`,
  },
  godOfWhat: [
    `земли`,
    `смерти`,
  ],
  description: shadowGods,
  alignmentId: ALIGNMENT_LN,
  symbolList: `силуэт собачьей головы`,
  domainIdList: [DOMAIN_DEATH, DOMAIN_KNOWLEDGE],
  pantheonId: PANTHEON_HIN,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_SCAG,
      page: 24,
    },
    {
      id: SOURCE_SCAG,
      page: 111,
    },
  ],
}
