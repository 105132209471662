const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_ILNEVAL,
} = require('./../../../../godIdList')
const {
  Bahgtru_Ilneval_Gruumsh_Luthic_Shargaas_Yurtrus_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Ilneval_DND3_FnP_img = require('./../../../../../images/gods/ilneval-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_ILNEVAL]: [
    {
      src: Ilneval_DND3_FnP_img,
      text: 'Символ Илневала',
      source: {
        id: SOURCE_DND3_FnP,
        page: 151,
      },
    },
    Bahgtru_Ilneval_Gruumsh_Luthic_Shargaas_Yurtrus_DND3_FnP_data,
  ],
}
