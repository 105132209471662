import React from 'react'
import { Link } from 'gatsby'

import RenderList from '@/components/RenderList'

import './DomainListtyles.less'

const DomainListComponent = (
  {
    headerText,
    list,
  }
) => (
  <section className='DomainList'>
    <header className='DomainList_header'>{headerText}:</header>
    {' '}
    <ul className='DomainList_list'>
      <RenderList
        list={list}
        renderer={({ id, url, text }) => (
          <li
            key={id}
            className='DomainList_item'
          >
            {
              url
                ? (
                  <Link
                    className='DomainList_name DomainList_name-link'
                    to={url}
                  >
                    {text}
                  </Link>
                )
                : (
                  <span className='DomainList_name'>
                      {text}
                    </span>
                )
            }
          </li>
        )}
      />
    </ul>
  </section>
)

export default DomainListComponent
