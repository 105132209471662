const {ALIGNMENT_NE} = require('./../../aligmentList')
const {DOMAIN_DEATH} = require('./../../domainList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_BLIBDOOLPOOLP} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_PHB} = require('./../../sourceList')

module.exports = {
  id: GOD_BLIBDOOLPOOLP,
  nameEn: 'Blibdoolpoolp',
  nameShort: {
    nominative: `Блибдульпульп`,
    genitive: `Блибдульпульп`,
    dative: `Блибдульпульп`,
    accusative: `Блибдульпульп`,
    instrumental: `Блибдульпульп`,
    prepositional: `Блибдульпульп`,
  },
  godOfWhat: [
    `куо-тоа`,
  ],
  alignmentId: ALIGNMENT_NE,
  symbolList: [
    `голова лобстера`,
    `чёрная жемчужина`,
  ],
  domainIdList: DOMAIN_DEATH,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_PHB,
    page: 297,
  },
}
