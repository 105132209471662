const {ALIGNMENT_N} = require('./../../aligmentList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_WAUKEEN} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  DOMAIN_KNOWLEDGE,
  DOMAIN_TRICKERY,
} = require('./../../domainList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_WAUKEEN,
  nameEn: 'Waukeen',
  nameShort: {
    nominative: 'Вокин',
    genitive: 'Вокин',
    dative: 'Вокин',
    accusative: 'Вокин',
    instrumental: 'Вокин',
    prepositional: 'Вокин',
  },
  godOfWhat: [
    `торговли`,
  ],
  nameAlt: [
    `Наша Леди Золота`,
    `Дева Монет`,
    `Друг Торговца`,
  ],
  description: `Вокин является богиней богатства и торговли, по обеим сторонам закона. К её самым ярким поклонникам относятся владельцы магазинов, уличные торговцы, богатые купцы, проводники караванов, странствующие коробейники, менялы и контрабандисты. Она интересуется всем, что развивает торговлю и увеличивает приток денег, будь то новые торговые пути, новые изобретения, или изменения моды. Тех, кто принимает Вокин как покровительницу можно резонно рассматривать как жадных, но Дева Монет неодобрительно отзывается скрягам и благодушна к трудолюбивым и расточительным, таким образом священников, которые несут её святой символ радушно встретят во многих городах и селениях.

Храмы Вокин напоминают гильдии и часто служат местом встреч для торговых альянсов. Те, кто следуют духу Вокин стремятся создать как можно больше возможностей для всех и конкурентоспособную среду во имя богатства, в качестве одного из основных средств двигателя прогресса общества. Таким образом, верующие Нашей Леди Золота часто оказываются в противоречии с торговыми гильдиями и теми, кто стремится образовать монополию. Обычная практика среди тех, кто ищет благосклонность Вокин — выделить десятину от своей прибыли, но не храму, а чтобы помочь удержать на плаву бизнес, профинансировать новое начинание или, если все остальное терпит неудачу, на легкомысленное веселье.`,
  alignmentId: ALIGNMENT_N,
  symbolList: 'Монета с изображением Вокин в профиль, смотрящей влево',
  domainIdList: [DOMAIN_TRICKERY, DOMAIN_KNOWLEDGE],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 42,
    },
  ],
}
