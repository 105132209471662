const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_CORELLON_LARETHIAN,
} = require('./../../../../godIdList')
const {
  Angharradh_Aerdrie_Corellon_Fenmarel_Sashelas_Erevan_DND3_FnP_data,
  Corellon_Takes_the_Eye_of_Gruumsh_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Corellon_Symbol_DND3_FnP_img = require('./../../../../../images/gods/corellon-symbol-DND3_FnP.jpg')

module.exports = {
  [GOD_CORELLON_LARETHIAN]: [
    {
      src: Corellon_Symbol_DND3_FnP_img,
      text: 'Символ Кореллона Ларетиана',
      source: {
        id: SOURCE_DND3_FnP,
        page: 126,
      },
    },
    Angharradh_Aerdrie_Corellon_Fenmarel_Sashelas_Erevan_DND3_FnP_data,
    Corellon_Takes_the_Eye_of_Gruumsh_DND3_FnP_data,
  ],
}
