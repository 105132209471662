const fatherOfForgeAndRespectedMother = require('./../constants/fatherOfForgeAndRespectedMother')
const {ALIGNMENT_LG} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {
  GOD_LADUGUER,
  GOD_MORADIN,
} = require('./../../godIdList')
const {PANTHEON_MORNDINSAMMAN} = require('./../../pantheonList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  DOMAIN_FORGE,
  DOMAIN_KNOWLEDGE,
} = require('./../../domainList')

module.exports = {
  id: GOD_MORADIN,
  nameShort: {
    nominative: `Морадин`,
    genitive: `Морадина`,
    dative: `Морадину`,
    accusative: `Морадина`,
    instrumental: `Морадином`,
    prepositional: `Морадине`,
  },
  worshipperRace: `дварфов`,
  godOfWhat: [
    `творения`,
  ],
  nameEn: 'Moradin',
  nameAlt: [
    'Великий Молот',
    'Всеотец',
    'Кователь Душ',
    'Отец Дварфов',
    'Отец кузни',
  ],
  description: [
    fatherOfForgeAndRespectedMother,
    {
      header: `Морадин`,
      text: `Морадин Великий Молот — божество дварфов. Ему поклоняются кузнецы, ремесленники и рудокопы. Находится в постоянной вражде c [Ладугуэром](GOD:${GOD_LADUGUER}).`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_LG,
  symbolList: `Молот и наковальня`,
  domainIdList: [DOMAIN_FORGE, DOMAIN_KNOWLEDGE],
  pantheonId: PANTHEON_MORNDINSAMMAN,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 297,
    },
    {
      id: SOURCE_SCAG,
      page: 23,
    },
  ],
}
