const {
  SOURCE_MTG_CLB,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_MYRKUL,
} = require('./../../../../godIdList')

const Myrkul_Symbol_SCAG_img = require('./../../../../../images/gods/myrkul-symbol-SCAG.png')
const Myrkul_MTG_CLB_img = require('./../../../../../images/gods/myrkul_MTG_CLB.jpeg')
const Myrkul_sigil_MTG_CLB_img = require('./../../../../../images/gods/myrkul-sigil-MTG_CLB.png')

module.exports = {
  [GOD_MYRKUL]: [
    {
      src: Myrkul_MTG_CLB_img,
      text: 'Миркул, Владыка Костей',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
    {
      src: Myrkul_Symbol_SCAG_img,
      text: 'Символ Миркула',
      source: {
        id: SOURCE_SCAG,
        page: 36,
      },
    },
    {
      src: Myrkul_sigil_MTG_CLB_img,
      text: 'Символ Миркула',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
}
