const craftsAndInventions = require('./../constants/craftsAndInventions')
const ladiesOfTheGoldenHills = require('./../constants/ladiesOfTheGoldenHills')
const {ALIGNMENT_CG} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_NEBELUN} = require('./../../godIdList')
const {PANTHEON_GNOME} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  DOMAIN_KNOWLEDGE,
  DOMAIN_TRICKERY,
} = require('./../../domainList')

module.exports = {
  id: GOD_NEBELUN,
  nameEn: 'Nebelun',
  nameShort: {
    nominative: `Небелун`,
    genitive: `Небелуна`,
    dative: `Небелуну`,
    accusative: `Небелуна`,
    instrumental: `Небелуном`,
    prepositional: `Небелуне`,
  },
  godOfWhat: [
    `удачи`,
    `изобретений`,
  ],
  nameAlt: [
    'Бесстрашный',
    'Назойливый',
  ],
  description: [
    craftsAndInventions,
    ladiesOfTheGoldenHills,
  ],
  alignmentId: ALIGNMENT_CG,
  symbolList: `кузнечные меха и хвост ящерицы`,
  domainIdList: [DOMAIN_KNOWLEDGE, DOMAIN_TRICKERY],
  pantheonId: PANTHEON_GNOME,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 25,
  },
}
