const {
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_LEIRA,
} = require('./../../../../godIdList')

const Leira_Symbol_SCAG_img = require('./../../../../../images/gods/leira-symbol-SCAG.png')

module.exports = {
  [GOD_LEIRA]: {
    src: Leira_Symbol_SCAG_img,
    text: 'Символ Лейры',
    source: {
      id: SOURCE_SCAG,
      page: 33,
    },
  },
}
