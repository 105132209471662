const {GENDER_MALE} = require('./../../genderList')
const {GOD_SSETH} = require('./../../godIdList')
const {godSsethDescriptionList} = require('./../../textCommonParts')
const {PANTHEON_SNAKE} = require('./../../pantheonList')

module.exports = {
  id: GOD_SSETH,
  nameEn: 'Sseth',
  nameShort: {
    nominative: 'Ссет',
    genitive: 'Ссета',
    dative: 'Ссету',
    accusative: 'Ссета',
    instrumental: 'Ссетом',
    prepositional: 'Ссете',
  },
  worshipperRace: `юань-ти`,
  nameAlt: [
    `Шипящая Смерть`,
  ],
  description: godSsethDescriptionList,
  pantheonId: PANTHEON_SNAKE,
  genderId: GENDER_MALE,
}
