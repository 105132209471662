const godsOfCrafts = require('./../constants/godsOfCrafts')
const {ALIGNMENT_N} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_DUMATHOIN} = require('./../../godIdList')
const {PANTHEON_MORNDINSAMMAN} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  DOMAIN_DEATH,
  DOMAIN_KNOWLEDGE,
} = require('./../../domainList')

module.exports = {
  id: GOD_DUMATHOIN,
  nameEn: 'Dumathoin',
  nameShort: {
    nominative: `Думатойн`,
    genitive: `Думатойна`,
    dative: `Думатойну`,
    accusative: `Думатойна`,
    instrumental: `Думатойном`,
    prepositional: `Думатойне`,
  },
  godOfWhat: [
    `сокрытий секретов`,
    `захороненного богатства`,
    `горной промышленности`,
    `исследований`,
    `драгоценных камней`,
  ],
  nameAlt: [
    `Хранитель Секретов под Горой`,
  ],
  alignmentId: ALIGNMENT_N,
  description: godsOfCrafts,
  symbolList: 'Силуэт горы с драгоценным камнем в центре',
  domainIdList: [DOMAIN_KNOWLEDGE, DOMAIN_DEATH],
  pantheonId: PANTHEON_MORNDINSAMMAN,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 23,
  },
}
