const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_BAHGTRU,
} = require('./../../../../godIdList')
const {
  Bahgtru_Ilneval_Gruumsh_Luthic_Shargaas_Yurtrus_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Bahgtru_Symbol_DND3_FnP_img = require('./../../../../../images/gods/bahgtru-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_BAHGTRU]: [
    {
      src: Bahgtru_Symbol_DND3_FnP_img,
      text: 'Символ Богтру',
      source: {
        id: SOURCE_DND3_FnP,
        page: 149,
      },
    },
    Bahgtru_Ilneval_Gruumsh_Luthic_Shargaas_Yurtrus_DND3_FnP_data,
  ],
}
