const elvenDarkGods = require('./../constants/elvenDarkGods')
const {ALIGNMENT_CG} = require('./../../aligmentList')
const {DOMAIN_TRICKERY} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_FENMAREL_MESTARINE} = require('./../../godIdList')
const {PANTHEON_SELDARINE} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_FENMAREL_MESTARINE,
  nameEn: 'Fenmarel Mestarine',
  nameShort: {
    nominative: `Фенмарел Местарин`,
    genitive: `Фенмарела Местарина`,
    dative: `Фенмарелу Местарину`,
    accusative: `Фенмарела Местарина`,
    instrumental: `Фенмарелом Местарином`,
    prepositional: `Фенмареле Местарине`,
  },
  godOfWhat: [
    `изгоев`,
    `отверженных`,
  ],
  alignmentId: ALIGNMENT_CG,
  description: elvenDarkGods,
  symbolList: `серебряная стрела с зелёным оперением`,
  domainIdList: DOMAIN_TRICKERY,
  pantheonId: PANTHEON_SELDARINE,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 24,
  },
}
