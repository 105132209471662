const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_SHEVARASH,
} = require('./../../../../godIdList')
const {
  Hanali_Labelas_Rillifane_Sehanine_Shevarash_Solonor_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Shevarash_Symbol_DND3_FnP_img = require('./../../../../../images/gods/shevarash-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_SHEVARASH]: [
    {
      src: Shevarash_Symbol_DND3_FnP_img,
      text: 'Символ Шевараша',
      source: {
        id: SOURCE_DND3_FnP,
        page: 131,
      },
    },
    Hanali_Labelas_Rillifane_Sehanine_Shevarash_Solonor_DND3_FnP_data,
  ],
}
