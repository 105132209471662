const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_VERGADAIN,
} = require('./../../../../godIdList')
const {
  Haela_Moradin_Laduguer_Sharindlar_Marthammer_Thard_Vergadain_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Vergadain_Symbol_DND3_FnP_img = require('./../../../../../images/gods/vergadain-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_VERGADAIN]: [
    {
      src: Vergadain_Symbol_DND3_FnP_img,
      text: 'Символ Вергадэйна',
      source: {
        id: SOURCE_DND3_FnP,
        page: 124,
      },
    },
    Haela_Moradin_Laduguer_Sharindlar_Marthammer_Thard_Vergadain_DND3_FnP_data,
  ],
}
