const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_ILMATER,
} = require('./../../../../godIdList')

const Ilmater_Symbol_SCAG_img = require('./../../../../../images/gods/ilmater-symbol-SCAG.png')
const Ilmater_Symbol_DND3_FnP_img = require('./../../../../../images/gods/ilmater-symbol-DND3_FnP.webp')
const Ilmater_DND3_FnP_img = require('./../../../../../images/gods/ilmater-DND3_FnP.webp')

module.exports = {
  [GOD_ILMATER]: [
    {
      src: Ilmater_Symbol_SCAG_img,
      text: 'Символ Ильматера',
      source: {
        id: SOURCE_SCAG,
        page: 31,
      },
    },
    {
      src: Ilmater_Symbol_DND3_FnP_img,
      text: 'Символ Ильматера',
      source: {
        id: SOURCE_DND3_FnP,
        page: 32,
      },
    },
    {
      src: Ilmater_DND3_FnP_img,
      text: 'Ильматер Терпящий',
      source: {
        id: SOURCE_DND3_FnP,
        page: 33,
      },
    },
  ],
}
