const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_TYMORA,
} = require('./../../../../godIdList')

const Tymora_Symbol_DND3_FnP_img = require('./../../../../../images/gods/tymora-symbol-DND3_FnP.jpg')
const Tymora_DND3_FnP_img = require('./../../../../../images/gods/tymora-DND3_FnP.jpg')

module.exports = {
  [GOD_TYMORA]: [
    {
      src: Tymora_Symbol_DND3_FnP_img,
      text: 'Символ Тиморы',
      source: {
        id: SOURCE_DND3_FnP,
        page: 78,
      },
    },
    {
      src: Tymora_DND3_FnP_img,
      text: 'Тимора, Леди Удачи',
      source: {
        id: SOURCE_DND3_FnP,
        page: 79,
      },
    },
  ],
}
