const drowDarkGods = require('./../constants/drowDarkGods')
const {ALIGNMENT_CE} = require('./../../aligmentList')
const {DOMAIN_WAR} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_SELVETARM} = require('./../../godIdList')
const {PANTHEON_DROW} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_SELVETARM,
  nameEn: 'Selvetarm',
  nameShort: {
    nominative: `Селветарм`,
    genitive: `Селветарма`,
    dative: `Селветарму`,
    accusative: `Селветарма`,
    instrumental: `Селветармом`,
    prepositional: `Селветарме`,
  },
  worshipperRace: `дроу`,
  godOfWhat: [
    `воинов`,
  ],
  description: drowDarkGods,
  alignmentId: ALIGNMENT_CE,
  symbolList: `Паук поверх перекрещенных булавы и меча`,
  domainIdList: DOMAIN_WAR,
  pantheonId: PANTHEON_DROW,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_SCAG,
      page: 24,
    },
    {
      id: SOURCE_SCAG,
      page: 109,
    },
  ]
}
