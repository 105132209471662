const goddessOfWisdom = require('./../constants/goddessOfWisdom')
const {ALIGNMENT_CG} = require('./../../aligmentList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_AERDRIE_FAENYA} = require('./../../godIdList')
const {PANTHEON_SELDARINE} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  DOMAIN_TEMPEST,
  DOMAIN_TRICKERY,
} = require('./../../domainList')

module.exports = {
  id: GOD_AERDRIE_FAENYA,
  nameEn: 'Aerdrie Faenya',
  nameShort: {
    nominative: `Аэрдри Фэйня`,
    genitive: `Аэрдри Фэйни`,
    dative: `Аэрдри Фэйне`,
    accusative: `Аэрдри Фэйню`,
    instrumental: `Аэрдри Фэйней`,
    prepositional: `Аэрдри Фэйне`,
  },
  godOfWhat: [
    `неба`,
    `ветра`,
    `погоды`,
  ],
  alignmentId: ALIGNMENT_CG,
  description: goddessOfWisdom,
  symbolList: `Силуэт птицы на фоне облака`,
  domainIdList: [DOMAIN_TEMPEST, DOMAIN_TRICKERY],
  pantheonId: PANTHEON_SELDARINE,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_SCAG,
    page: 24,
  },
}
