const {ALIGNMENT_N} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {
  GOD_GOND,
  GOD_MYSTRA,
} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {PC_RACE_GNOME} = require('./../../pcRaceIdList')
const {
  DOMAIN_FORGE,
  DOMAIN_KNOWLEDGE,
} = require('./../../domainList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_GOND,
  nameEn: 'Gond',
  nameShort: {
    nominative: 'Гонд',
    genitive: 'Гонда',
    dative: 'Гонду',
    accusative: 'Гонда',
    instrumental: 'Гондом',
    prepositional: 'Гонде',
  },
  godOfWhat: [
    `ремесла`,
    `создания`,
    `строительства`,
  ],
  nameAlt: [
    `Аранлэрус`,
    `Балатенг`,
    `Божественный Вдохновитель`,
    `Дэросдэрос`,
    `Кланнауда`,
    `Мрангор`,
    `Небелун`,
    `Повелитель Кузнецов`,
    `Святой Всесоздатель`,
    `Таттаба`,
    `Чудотворец`,
  ],
  description: [
    {
      header: 'Гонд',
      text: `Гонд — бог создания, ремесла и строительства. Его почитают кузнецы, плотники, инженеры и изобретатели. Любой, кто создаёт что-то может вознести молитву Гонду, чтобы тот направил его руку, но люди знают, что сильнее Гонд благоволит тем, кто изобретает что-то новое, полезное для остальных.

Служители Гонда странствуют по Северу в жёлто-оранжевых одеяниях, подпоясанные кушаками на которых висят шестерни, замки, крючки, кусочки стали, олова и дерева, которые вдруг могут понадобиться. Они также носят пояса из крупных соединенных между собой металлических медальонов и непомерно большие шляпы от солнца. В отдаленных поселениях странствующий священник Гонда может выступать как лудильщик, плотник и инженер в одном лице, готовый помочь построить новые ворота загона, выкопать новый колодец, починить горшки и мебель, которые уже собрались выкидывать. Все священники Гонда ведут дневники, где они записывают идеи, изобретения, инновации, которые встретились им по пути, а потом с огромным удовольствием рассказывают о них встреченным жрецам и делятся наработками. В крупных городах гондары строят храмы, которые становятся мастерскими и лабораториями изобретателей. В таких храмах странствующие жрецы передают свои дневники сидящим там писарям, которые документируют наблюдения служителей во благо следующих поколений.

Большинство почитателей Гонда принадлежат к проверенным временем профессиям, таким как кузнецы и инженеры, архитекторы и ткачи, ювелиры и кожевенники. Тем не менее вера получила ещё и репутацию пристанища безумных учёных и мечтателей.

Центром поклонения Гонду на Побережье Меча являются Врата Балдура, где верующие во славу Чудотворца возвели два монументальных здания: храм, называемый Высшим Домом Чудес, и музей ремесла и прикладного искусства названный Залом Чудес. Лантан был выдающимся местом поклонения Гонду, пока столетие назад всё население острова не исчезло, а со времени его возвращения на Побережье Меча видели лишь несколько лантанских купцов, которые не слишком много рассказали о состоянии своей родины.`,
      source: {
        id: SOURCE_SCAG,
        page: 29,
      },
    },
    {
      header: 'Многоликий Гонд',
      text: `_(Отрывок из истории Гонда — Повелителя Кузнецов)_

Что кроется в имени? Многое, особенно если речь о Гонде. Для богов менять именования — обычное дело ([Мистра](GOD:${GOD_MYSTRA}), определись уже, наконец!), но мало кто может соперничать в этом с Повелителем Кузнецов.

У него не два и не три, а шесть тайных имён (Аранлэрус, Балатенг, Дэросдэрос, Кланнауда, Мрангор и Таттаба — об этом в главе восемьдесят пятой), но [гномам](PC_RACE:${PC_RACE_GNOME}) и этого оказалось мыло. Они выбрали для Гонда собственное имя.

Небелун.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_N,
  symbolList: 'Зубчатая шестерёнка с четырьмя спицами',
  domainIdList: [DOMAIN_FORGE, DOMAIN_KNOWLEDGE],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 29,
    },
  ],
}
