const homeKeepers = require('./../constants/homeKeepers')
const {ALIGNMENT_LG} = require('./../../aligmentList')
const {DOMAIN_WAR} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_ARVOREEN} = require('./../../godIdList')
const {PANTHEON_HIN} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_ARVOREEN,
  nameEn: 'Arvoreen',
  nameShort: {
    nominative: `Арворин`,
    genitive: `Арворина`,
    dative: `Арворину`,
    accusative: `Арворина`,
    instrumental: `Арворином`,
    prepositional: `Арворине`,
  },
  godOfWhat: [
    `бдительности`,
    `войны`,
  ],
  alignmentId: ALIGNMENT_LG,
  description: homeKeepers,
  symbolList: `перекрещенные короткие мечи`,
  domainIdList: DOMAIN_WAR,
  pantheonId: PANTHEON_HIN,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 24,
  },
}
