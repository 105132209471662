const {SOURCE_SCAG} = require('./../../sourceList')
const {PC_RACE_ORC} = require('./../../pcRaceIdList')
const {
  GOD_SHARGAAS,
  GOD_YURTRUS,
} = require('./../../godIdList')

module.exports = {
  header: 'Чёрное и Белое',
  text: `Два наиболее зловещих члена [орочьего](PC_RACE:${PC_RACE_ORC}) пантеона располагаются на противоположных концах визуального спектра. Один из них — [Шаргаас](GOD:${GOD_SHARGAAS}), Ночной Лорд, бог тьмы, ночи и скрытности.

Второй — [Юртрус Белорукий](GOD:${GOD_YURTRUS}), Лорд Червей, пугающее божество болезней и смерти.`,
  source: {
    id: SOURCE_SCAG,
    page: 119,
  },
}
