const {GENDER_FEMALE} = require('./../../genderList')
const {
  PANTHEON_FORGOTTEN_REALMS,
} = require('./../../pantheonList')
const {
  DOMAIN_ARCANA,
  DOMAIN_KNOWLEDGE,
} = require('./../../domainList')
const {
  PC_CLASS_BARD,
  PC_CLASS_CLERIC,
  PC_CLASS_WARLOCK,
  PC_CLASS_WIZARD,
} = require('./../../pcClassIdList')
const {
  ALIGNMENT_NG,
} = require('./../../aligmentList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GOD_MYSTRA,
  GOD_SHAR,
  GOD_SELUNE,
} = require('./../../godIdList')

module.exports = {
  id: GOD_MYSTRA,
  nameEn: 'Mystra',
  nameShort: {
    nominative: 'Мистра',
    genitive: 'Мистры',
    dative: 'Мистре',
    accusative: 'Мистру',
    instrumental: 'Мистрой',
    prepositional: 'Мистре',
  },
  godOfWhat: [
    `магии`,
  ],
  nameAlt: [
    `Владычица Тайн`,
    `Леди Тайн`,
    `Наша Госпожа Заклинаний`,
    `Прародительница Магии`,
  ],
  description: [
    {
      header: 'Мистра',
      text: `Мистра — повелительница магии, и всего с нею связанного. Она почитаема магами и теми, кто использует волшебство или волшебные предметы в повседневной жизни. Ей возносят молитвы восхищающиеся магией или страшащиеся опасностей, что она приносит. Мистра — божество той силы, которая вообще позволяет творить магию. Она владеет Плетением и следит за ним, поддерживая этот проводник, посредством которого смертные и боги могут пользоваться чистой силой магии.
  
  Мистра почитаема почти во всех уголках Фаэруна, что немудрено для земли, настолько пропитанной магией. Среди поклоняющихся и те, кто используют магию и те, кто с ней соприкасается, например, алхимики и мудрецы. Среди облаченных в синее жрецов Мистры можно найти [волшебников](PC_CLASS:${PC_CLASS_WIZARD}), [колдунов](PC_CLASS:${PC_CLASS_WARLOCK}) и даже несколько [бардов](PC_CLASS:${PC_CLASS_BARD}). Цель этой веры очень проста — магия есть, и она должна распространяться. В норме обычая не забывать о тех, у кого высокий потенциал и находить хороших учителей для них.`,
      source: {
        id: SOURCE_SCAG,
        page: 36,
      },
    },
    {
      header: `Мистра`,
      text: `Мистра — прародительница всей магии, хранительница Плетения; она даёт тайные знания смертным заклинателям. Её [жрецы](PC_CLASS:${PC_CLASS_CLERIC}) хранят древние предания и защищают бастионы магической энергии.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Происхождение Мистры`,
      text: `Чтобы узнать о Мистре, богине магии, мы должны в первую очередь вспомнить о её более ранних воплощениях, начиная с Мистрил. Говорят, Владычица тайн появилась на свет в результате борьбы между богинями [Шар](GOD:${GOD_SHAR}) и [Селунэ](GOD:${GOD_SELUNE}). Стараясь превзойти друг друга, сёстры-близнецы непроизвольно соединили части своих противоположных энергий света и тени и сотворили существо из чистой магии — Мистрил.

Будучи воплощением магии, Мистрил заявила свои права на власть над Плетением, самой тканью магии, где ею можно управлять, ибо Плетение было продолжением её существа. Для Мистрил пользоваться магией было так же естественно, как для других дышать или шевелить рукой.

В 339 году Мистрил переродилась в Мистру после того, как силы первой были присвоены Карсусом, нетерийским владыкой, и, наверное, самым могущественным и амбициозным волшебником в истории. Мистрил предпочла лишить его неправедно добытой власти и пожертвовала собой. В этот миг всё Плетение померкло, а магия перестала существовать. Безумие Карсуса повлекло за собой падение нетерила и положило конец всем его надеждам. Но плетение не могло долго оставаться необитаемым. Спустя несколько мгновений богиня магии переродилась, став Мистрой.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Мольбы к Амберли`,
      text: `Королева Амберли, воплощение моря, я бросаюсь на скалы во имя твоё. Позволь мне влить солёную воду во славу твою в самые свои лёгкие. Преврати мой череп в раковину, мои рёбра в риф, чтобы заслужить твоё благословение, и чтобы избежать гнева твоего сахваджина.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_Мистра — владычица магов,_

_Плетению служит во благо._

_Её власть непреложна,_

_Без неё невозможно_

_В ворожбе сделать даже полшага._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_NG,
  symbolList: [
    'круг из семи звёзд',
    'девять звёзд, окружающих текущий красный туман',
    'звезда',
  ],
  domainIdList: [DOMAIN_ARCANA, DOMAIN_KNOWLEDGE],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 36,
    },
  ],
}
