import SEARCH_PROP_NAME from '@/constants/SEARCH_PROP_NAME'
import DEFAULT_FILTER_VALUE from '@/constants/DEFAULT_FILTER_VALUE'
import aligmentList from '@/constants/aligmentList'
import godList from '@/constants/godList'
import pantheonList from '@/constants/pantheonList'
import domainList from '@/constants/domainList'
import {
  FILTER_TYPE_INPUT,
  FILTER_TYPE_SELECT,
} from '@/constants/filterTypes'

import upLetter from '@/utils/upLetter'
import sortByText from '@/utils/sortByText'
import generateNameStr from '@/utils/generateNameStr'
import { GENDER_ANY, GENDER_MALE } from '@/constants/genderList'

export default [
  {
    label: 'Пантеон',
    type: FILTER_TYPE_SELECT,
    title: 'Какой пантеон?',
    propName: 'pantheonId',
    value: DEFAULT_FILTER_VALUE,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Любой'
      },
      ...pantheonList
        .filter(
          ({ id }) => godList.some(
            ({pantheonId}) => pantheonId === id
          )
        )
        .map(
          ({ name, nameAlt, id: value }) => {
            const {header} = generateNameStr({ name, nameAlt })

            return ({ value, text: upLetter(header) })
          }
        )
        .sort(sortByText)
    ]
  },
  {
    label: 'Домен',
    type: FILTER_TYPE_SELECT,
    title: 'Какой домен у жрецов?',
    propName: 'domainIdList',
    value: DEFAULT_FILTER_VALUE,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Любой'
      },
      ...domainList
        .map(
          ({id, name}) => ({
            value: id,
            text: upLetter(name),
          })
        )
        .sort(sortByText)
    ]
  },
  {
    label: 'Мировоззрение',
    type: FILTER_TYPE_SELECT,
    propName: 'alignmentId',
    value: DEFAULT_FILTER_VALUE,
    list: [
      {
        value: DEFAULT_FILTER_VALUE,
        text: 'Любое'
      },
      ...aligmentList
        .filter(
          ({ showInList }) => showInList
        )
        .map(
          ({ name, id, children }) => {
            const {nominative: text} = (name[GENDER_MALE] || name[GENDER_ANY])

            return {
              text: upLetter(text),
              value: [id, ...children],
            }
          }
        )
        .sort(sortByText)
    ]
  },
  {
    label: 'Введите имя бога на русском или английском',
    type: FILTER_TYPE_INPUT,
    propName: SEARCH_PROP_NAME,
    value: DEFAULT_FILTER_VALUE,
  },
]
