const {ALIGNMENT_NG} = require('./../../aligmentList')
const {DOMAIN_LIFE} = require('./../../domainList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_CHAUNTEA} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_CHAUNTEA,
  nameEn: 'Chauntea',
  nameShort: {
    nominative: 'Чонтея',
    genitive: 'Чонтеи',
    dative: 'Чонтее',
    accusative: 'Чонтею',
    instrumental: 'Чонтеей',
    prepositional: 'Чонтее',
  },
  godOfWhat: [
    `земледелия`,
    `сева`,
    `жатвы`,
    `разведения`,
    `забоя`,
    `стрижки`,
    `ткачества`,
  ],
  nameAlt: [
    `Великая Мать`,
    `Богиня Зерна`,
  ],
  description: [
    `Чонтея — богиня земледелия: сева и жатвы, разведения и забоя, стрижки и ткачества. В этом аспекте она скорее сельское божество, которому редко молятся в стенах города, исключая разве что кухонный персонал. Но Чонтея также и Великая Мать, богиня колыбели, очага и дома. И в этой ипостаси её поминают в каждом доме перед приёмом пищи, при рождении ребёнка, люди воздают ей хвалу, когда нежатся у очага или чувствуют себя любимыми и спокойными.

Вера Чонтеи это воспитание и рост. Её учение основывается на сельской мудрости и традициях хозяйства. Посев и жатва, вечный цикл — обычные темы её веры. Уничтожение ради уничтожения, переделка без восстановления — анафемы для её догм.

Храмы Чонтеи хранят большой пласт знаний о сельском хозяйстве и культивации. Её жрецы тесно сотрудничают с сельскими общинами, и они готовы засучить рукава и испачкать руки в земле.`,
    {
      header: 'Мать-Земля',
      text: `Друиды островов Муншае поклоняются Матери Земле — выражающей силу самой земли.

Люди с материка считают Мать-Землю аспектом или проявлением Чонтеи, но для ффолков она просто Мать-Земля, и так будет всегда. Лунные колодцы — её священные места и её окна в мир.`,
      source: {
        id: SOURCE_SCAG,
        page: 28,
      },
    },
  ],
  alignmentId: ALIGNMENT_NG,
  symbolList: [
    'сноп колосьев',
    'цветущая роза перед колосьями',
  ],
  domainIdList: DOMAIN_LIFE,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 28,
    },
  ],
}
