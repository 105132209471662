const {ALIGNMENT_CE} = require('./../../aligmentList')
const {DOMAIN_WAR} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_HRUGGEK} = require('./../../godIdList')
const {hruggekDescription} = require('./../../textCommonParts')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_PHB} = require('./../../sourceList')

module.exports = {
  id: GOD_HRUGGEK,
  nameEn: 'Hruggek',
  nameShort: {
    nominative: `Хруггек`,
    genitive: `Хруггека`,
    dative: `Хруггеку`,
    accusative: `Хруггека`,
    instrumental: `Хруггеком`,
    prepositional: `Хруггеке`,
  },
  worshipperRace: `багбиров`,
  godOfWhat: [
    `жестокости`,
  ],
  alignmentId: ALIGNMENT_CE,
  symbolList: `Моргенштерн`,
  domainIdList: DOMAIN_WAR,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  description: hruggekDescription,
  source: {
    id: SOURCE_PHB,
    page: 297,
  },
}
