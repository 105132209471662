const {ALIGNMENT_CG} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_STRONMAUS} = require('./../../godIdList')
const {PANTHEON_GIANT} = require('./../../pantheonList')
const {
  giantGodsCommonDescriptionList,
  godStronmausOnlyDescriptionList,
} = require('./../../textCommonParts')

module.exports = {
  id: GOD_STRONMAUS,
  nameEn: 'Stronmaus',
  nameShort: {
    nominative: `Стронмаус`,
    genitive: `Стронмауса`,
    dative: `Стронмаусу`,
    accusative: `Стронмауса`,
    instrumental: `Стронмаусом`,
    prepositional: `Стронмаусе`,
  },
  worshipperRace: `штормовых великанов`,
  description: [
    ...godStronmausOnlyDescriptionList,
    ...giantGodsCommonDescriptionList,
  ],
  alignmentId: ALIGNMENT_CG,
  pantheonId: PANTHEON_GIANT,
  genderId: GENDER_MALE,
}
