const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_DEEP_DUERRA,
} = require('./../../../../godIdList')
const {
  Abbathor_Berronar_Clangeddin_Duerra_Dumathoin_Dugmaren_Gorm_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Duerra_Symbol_DND3_FnP_img = require('./../../../../../images/gods/duerra-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_DEEP_DUERRA]: [
    {
      src: Duerra_Symbol_DND3_FnP_img,
      text: 'Символ Глубинной Дуэрры',
      source: {
        id: SOURCE_DND3_FnP,
        page: 117,
      },
    },
    Abbathor_Berronar_Clangeddin_Duerra_Dumathoin_Dugmaren_Gorm_DND3_FnP_data,
  ],
}
