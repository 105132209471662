const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_MILIL,
} = require('./../../../../godIdList')
const {
  Istishia_Jergal_Gwaeron_Hoar_Milil_DND3_FnP_data,
} = require('./../../../commonImageCollection')
const Milil_Symbol_DND3_FnP_img = require('./../../../../../images/gods/milil-symbol-DND3_FnP.png')

module.exports = {
  [GOD_MILIL]: [
    {
      src: Milil_Symbol_DND3_FnP_img,
      text: 'Символ Милила',
      source: {
        id: SOURCE_DND3_FnP,
        page: 102,
      },
    },
    Istishia_Jergal_Gwaeron_Hoar_Milil_DND3_FnP_data,
  ],
}
