const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_TALONA,
} = require('./../../../../godIdList')

const Talona_Symbol_SCAG_img = require('./../../../../../images/gods/talona-symbol-SCAG.png')
const Talona_Symbol_DND3_FnP_img = require('./../../../../../images/gods/talona-symbol-DND3_FnP.png')
const Talona_DND3_FnP_img = require('./../../../../../images/gods/talona-DND3_FnP.webp')

module.exports = {
  [GOD_TALONA]: [
    {
      src: Talona_Symbol_SCAG_img,
      text: 'Символ Талоны',
      source: {
        id: SOURCE_SCAG,
        page: 39,
      },
    },
    {
      src: Talona_Symbol_DND3_FnP_img,
      text: 'Символ Талоны',
      source: {
        id: SOURCE_DND3_FnP,
        page: 107,
      },
    },
    {
      src: Talona_DND3_FnP_img,
      text: 'Талона, Чумная Старуха',
      source: {
        id: SOURCE_DND3_FnP,
        page: 108,
      },
    },
  ],
}
