const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_ISTISHIA,
} = require('./../../../../godIdList')
const {
  Istishia_Jergal_Gwaeron_Hoar_Milil_DND3_FnP_data,
} = require('./../../../commonImageCollection')
const Istishia_Symbol_DND3_FnP_img = require('./../../../../../images/gods/istishia-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_ISTISHIA]: [
    {
      src: Istishia_Symbol_DND3_FnP_img,
      text: 'Знак Истишии',
      source: {
        id: SOURCE_DND3_FnP,
      },
    },
    Istishia_Jergal_Gwaeron_Hoar_Milil_DND3_FnP_data,
  ],
}
