const tichLegend = require('./../constants/tichLegend')
const {ALIGNMENT_CE} = require('./../../aligmentList')
const {DOMAIN_TRICKERY} = require('./../../domainList')
const {GENDER_FEMALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  PC_CLASS_CLERIC,
  PC_CLASS_DRUID,
} = require('./../../pcClassIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GOD_BESHABA,
  GOD_TYMORA,
} = require('./../../godIdList')

module.exports = {
  id: GOD_BESHABA,
  nameEn: 'Beshaba',
  nameShort: {
    nominative: 'Бешаба',
    genitive: 'Бешабы',
    dative: 'Бешабе',
    accusative: 'Бешабу',
    instrumental: 'Бешабой',
    prepositional: 'Бешабе',
  },
  godOfWhat: [
    `несчастья`,
  ],
  nameAlt: [
    `Дева Неудачи`,
    `Леди Рок`,
    `Чёрная Бесс`,
  ],
  description: [
    `Бешаба является противоположностью [Тиморы](GOD:${GOD_TYMORA}), но в повседневной жизни пользуется не меньшим признанием, чем её более благожелательная «сестра». Она считается жестокой и капризной богиней, которую необходимо умилостивить, чтобы избежать её недоброго внимания и интереса.

К Бешабе взывают, когда кто-то страдает от невезения — как незначительного, вроде ушибленного пальца ноги или сломавшегося колеса повозки, так и катастрофического, вроде того, когда поскальзываешься и нечаянно падаешь с обрыва. К ней также взывают, чтобы отвратить ее внимание, делая нечто такое, в чём удача не будет играть большой роли, а невезение — может. Например, кидая кости, игрок будет призывать [Тимору](GOD:${GOD_TYMORA}), желая, чтобы случайный шанс обернулся в его пользу. Но тот, кто собирается пересечь непрочный мост, будет просить Бешабу сохранить мост целым.

Люди делают знак Бешабы, сгибая большие и широко разводя в стороны остальные пальцы одной или обеих рук (имитируя рога её священного символа), чтобы отогнать несчастья. Тот же самый жест, поднятый к голове означает приветствие; когда же им указывают на кого-то, «рога» показывают дурное расположение к этому человеку.

Многие [друиды](PC_CLASS:${PC_CLASS_DRUID}) поклоняются Бешабе как одной из Первого Круга. Они пытаются умилостивить её танцами, во время которых носят обгоревшие дочерна оленьи рога, смоченные в крови. По убеждению этих [друидов](PC_CLASS:${PC_CLASS_DRUID}), священным символом Бешабы стали рога оленя, потому что в те времена, когда её только начали почитать, люди были простыми охотниками и собирателями; считалось, что она могла принести охотнику беду, — например, быть пронзённым оленьими рогами.

Хотя большинство людей дрожит от страха при одной лишь мысли о появлении Бешабы, её почти всегда формально призывают и приветствуют во вступительных речах и церемониях во время официальных торжеств, таких как браки и коронации, состязания в спортивной или военной доблести и обряд именования детей. Если же она не приглашена на такое событие, она может обидеться и причинить большие неприятности всем его участникам.

Храмы Бешабы практически неизвестны. Тем не менее, у сельского народа широко распространен обычай устанавливать столб с укреплёнными на нём оленьими рогами, на месте какого-нибудь убийства или несчастного случая в дороге. В городах, где рога найти трудно, а убийства и несчастные случаи происходят гораздо чаще, чёрные рога Бешабы рисуют углём на соседней стене, оставляя их на всеобщее обозрение до тех пор, пока рисунок не будет уничтожен непогодой. Такие «святыни», как бы они не выглядели, служат предупреждением о местах неудачи и невезения.

Более официальные святыни Бешабы существуют там, где люди часто молятся о предотвращении несчастий. В таких местах, как правило, установлены окрашенные в красное камни с прикрепленными к ним почерневшими оленьими рогами, или красная, треугольной формы настенная доска — также с оленьими рогами на ней. Оба типа святилищ имеют каменную или бронзовую чашу, в которую можно бросить монеты или использовать её для огненного жертвоприношения. Красные Волшебники Тэя обычно возводят такие святыни у порогов своих ритуальных помещений для защиты от несчастных ошибок.

Мало кто осмелится взять Бешабу в качестве покровителя. Редкими [жрецами](PC_CLASS:${PC_CLASS_CLERIC}) Девы Неудачи становятся те, кто пострадал от великих бед, и кто стремится предупредить других о том, что несправедливость — неотъемлемая часть жизни, или сделать их жертвой этой несправедливости.`,
    tichLegend,
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_Злая ведьма по кличке Бешаба —_

_Отвратительно мерзкая баба:_

_Не подмажешь молитвой — _

_Выйдешь голый на битву…_

_И замёрзнешь, врагам на забаву._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_CE,
  symbolList: 'Чёрные оленьи рога',
  domainIdList: DOMAIN_TRICKERY,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 27,
    },
  ],
}
