const {ALIGNMENT_N} = require('./../../aligmentList')
const {DOMAIN_TEMPEST} = require('./../../domainList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_AKADI} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_AKADI,
  nameEn: 'Akadi',
  nameShort: {
    nominative: 'Акади',
    genitive: 'Акади',
    dative: 'Акади',
    accusative: 'Акади',
    instrumental: 'Акади',
    prepositional: 'Акади',
  },
  godOfWhat: [
    `воздуха`,
  ],
  alignmentId: ALIGNMENT_N,
  symbolList: 'Облако',
  domainIdList: DOMAIN_TEMPEST,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_SCAG,
    page: 22,
  },
}
