const {ALIGNMENT_CE} = require('./../../aligmentList')
const {DOMAIN_TEMPEST} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_TALOS} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_TALOS,
  nameEn: 'Talos',
  nameShort: {
    nominative: 'Талос',
    genitive: 'Талоса',
    dative: 'Талосу',
    accusative: 'Талоса',
    instrumental: 'Талосом',
    prepositional: 'Талосе',
  },
  godOfWhat: [
    `бурь`,
  ],
  nameAlt: [
    `Штормовой Лорд`,
    `Разрушитель`,
  ],
  description: [
    {
      header: `Талос`,
      text: `Талос — это тёмная сторона природы, безразличная и разрушительная сила, которая может нагрянуть в любой момент. Он бог штормов, лесных пожаров, землетрясений, ураганов и прочих бедствий. Среди его последователей множество разорителей, грабителей, воров и разбойников. Те, кто почитают его, считают жизнь лишь удачным стечением обстоятельств в море хаоса, потому они должны взять всё, что смогут — ведь никто не знает, когда Талос нанесёт удар и отправит их в посмертие.

Талос изображается как широкоплечий, бородатый, молодой мужчина с тёмной повязкой на одном глазу. Говорят, он носит три посоха — из первого срубленного дерева, из первого отлитого серебра и из первого кованного железа. Он использует эти посохи в гневе, чтобы призывать разрушительные ветра, создавать ужасные шторма и разделять твердь. Три молнии — его святой символ — изображают эти три посоха, и когда он обращает свой гнев на мир, считается, что он, как молнии, швыряет их с небес.

Хотя Талос и популярное божество, его имя поминают чаще из страха, чем с почтением. Его жречество — это бродячие буревестники, которые предупреждают о грядущих катастрофах и принимают пожертвования в обмен на благословения и покровительство. Многие из его верующих носят чёрную повязку на глазу, даже если оба глаза на месте.`,
      source: {
        id: SOURCE_SCAG,
        page: 39,
      },
    },
    {
      header: `Талос`,
      text: `Талос представляет равнодушные деструктивные природные явления. Его поклонники смотрят на жизнь как на россыпь случайных событий в море хаоса и стараются выхватить из потока всё, что удаётся — ибо кто знает, когда Талос ударит вновь?`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_CE,
  symbolList: 'Три молнии, бьющие из одной точки',
  domainIdList: DOMAIN_TEMPEST,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 39,
    },
  ],
}
