const jergalLegendList = require('./../constants/jergalLegendList')
const {ALIGNMENT_LE} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_BANE} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {baneFollowerList} = require('./../../textCommonParts')
const {
  DOMAIN_ORDER,
  DOMAIN_WAR,
} = require('./../../domainList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_BANE,
  nameEn: 'Bane',
  nameShort: {
    nominative: 'Бэйн',
    genitive: 'Бэйна',
    dative: 'Бэйну',
    accusative: 'Бэйна',
    instrumental: 'Бэйном',
    prepositional: 'Бэйне',
  },
  godOfWhat: [
    'тирании',
  ],
  nameAlt: [
    'Бейн',
    'Владыка Тьмы',
    'Чёрная Рука',
    'Чёрный Властитель',
    'Верховный Тиран',
    'Ужас',
    'Ненависть',
  ],
  description: [
    {
      header: `Бэйн`,
      text: `Догма Бэйна проста: сильный не только вправе, но и обязан править слабым. Тиран, способный захватить власть, должен сделать это, потому что это принесет пользу не только ему, но и тем, кто оказывается под его правлением. Когда правитель разваливается от коррупции, декаданса и ветхости, другой, более сильный, поднимется на его место.

Бэйна чернили во множестве легенд. На протяжении истории, те кто был его фаворитом, творили чёрные дела в его имя, но большинство людей не поклоняются Бэйну из злобы. Бэйн олицетворяет амбиции и контроль для тех, кто поднялся на вершину, но и находящиеся у подножья молят его о силе. Говорят, он благоволит тем, кто мужественен и решителен, и что он помогает тем, кто желает стать завоевателем, поднимая королевства из дикой земли, и принося порядок в беззаконие.

Множество раз, в разных местах Фаэруна преданных Бэйну видели как спасителей, уничтожающих налётчиков, свергающих коррумпированных правителей, или защищающих армии от полного разгрома. Но, в таком же количестве мест, последователи Бэйна создавали и поддерживали жестокие диктатуры, помогали торговым монополиям и вводили рабство там, где его до того не было.`,
      source: {
        id: SOURCE_SCAG,
        page: 27,
      },
    },
    ...baneFollowerList,
    ...jergalLegendList,
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_Бэйн, владыка раздоров и страха,_

_Правит плетью, и дыбой, и плахой._

_Возвышает тиранов_

_И огромные страны_

_Превращает в пустыни из праха._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  note: {
    text: 'Владыка Тьмы возвышает лишь тех, кто служит.',
    author: 'Заклинательница Бэйна',
  },
  alignmentId: ALIGNMENT_LE,
  symbolList: 'Чёрная ладонь, обращённая пальцами вверх',
  domainIdList: [DOMAIN_ORDER, DOMAIN_WAR],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 27,
    },
  ],
}
