const {ALIGNMENT_N} = require('./../../aligmentList')
const {DOMAIN_LIFE} = require('./../../domainList')
const {GENDER_MIDDLE} = require('./../../genderList')
const {GOD_SEMUANYA} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_PHB} = require('./../../sourceList')

module.exports = {
  id: GOD_SEMUANYA,
  nameEn: 'Semuanya',
  nameShort: {
    nominative: `Семуанья`,
    genitive: `Семуаньи`,
    dative: `Семуанье`,
    accusative: `Семуанью`,
    instrumental: `Семуаньей`,
    prepositional: `Семуанье`,
  },
  worshipperRace: `людоящеров`,
  godOfWhat: [
    `выживания`,
  ],
  alignmentId: ALIGNMENT_N,
  symbolList: `Яйцо`,
  domainIdList: DOMAIN_LIFE,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MIDDLE,
  source: {
    id: SOURCE_PHB,
    page: 297,
  },
}
