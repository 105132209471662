const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_MARTHAMMOR,
} = require('./../../../../godIdList')

const Marthammor_Symbol_DND3_FnP_img = require('./../../../../../images/gods/marthammor-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_MARTHAMMOR]: {
    src: Marthammor_Symbol_DND3_FnP_img,
    text: 'Символ Мартаммора Дьюина',
    source: {
      id: SOURCE_DND3_FnP,
      page: 121,
    },
  },
}
