const {ALIGNMENT_LG} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {
  GOD_GOND,
  GOD_MYSTRA,
  GOD_TYR,
} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const tyrTormIlmater = require('./../constants/tyrTormIlmater')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  DOMAIN_ORDER,
  DOMAIN_PROTECTION,
  DOMAIN_WAR,
} = require('./../../domainList')

module.exports = {
  id: GOD_TYR,
  nameEn: 'Tyr',
  nameShort: {
    nominative: 'Тир',
    genitive: 'Тира',
    dative: 'Тиру',
    accusative: 'Тира',
    instrumental: 'Тиром',
    prepositional: 'Тире',
  },
  godOfWhat: [
    `правосудия`,
    `исправления несправедливости`,
    `праведного мщения`,
  ],
  nameAlt: [
    `Владыка Правосудия`,
    `Грозноречивый`,
    `Однорукий`,
    `Покалеченный Бог`,
    `Раненный`,
    `Слепец`,
    `Слепой`,
  ],
  description: [
    {
      header: 'Тир',
      text: `Тир Грозноречивый, Тир Однорукий, Раненный Тир, Покалеченный Бог, Слепец, Слепой Тир, Владыка Правосудия — все эти имена говорят о природе фаэрунского бога правосудия. Тир выглядит как благородный воин, без десницы, которую потерял из-за смелой жертвы Кезефу, Гончей Хаоса, и с повязкой на глазах в знак слепоты от раны, нанесенной ему Ао, когда Тир засомневался в справедливости действий Сверхбога.
  
  Последователи Тира посвящают себя делу правосудия, исправления несправедливости и праведного мщения. Это дело не обязательно может быть связанно с равенством или честностью, но скорее относится к открытию истины и наказании виновных. Предпочитающие Тира склонны быть радикальными в вопросах теологии и толковании законов, разделяя всё на чёрное и белое. Кредо Тира о законопослушности и честности весьма требовательно, и жрецы его часто напоминают своей пастве не презирать других, не живущих согласно его заветам — ведь это не было бы так благородно, если бы каждый мог, собрав свою волю, жить по его законам.
  
  Многие рыцарские ордена посвящены Тиру, например, Рыцари Святого Правосудия или Рыцари Милостивого Меча. Такие рыцари — также как судьи и жрецы, клирики и паладины, поклоняющиеся Тиру — иногда носят тонкие повязки прозрачной ткани на глазах, чтобы напомнить другим о слепоте правосудия.`,
      source: {
        id: SOURCE_SCAG,
        page: 41,
      },
    },
    {
      header: `Тир`,
      text: `Слепой Тир — владыка закона и правосудия. Он ждёт от своих последователей храбрых деяний и неустанно преследует нарушителей клятв.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Гончая и десница`,
      text: `Праведный бог Тир потерял свою правую руку в пасти Кезефа, Пса Хаоса, пожирателя душ и одного из первобытных зол. Боги, заключившие союз и твёрдо вознамерившиеся уничтожить Кезефа, загнали пса в Пустоши Гибели и Отчаяния и предложили соглашение. Если Кезеф сможет разорвать цепи, выкованные богом-кузнецом [Гондом](GOD:${GOD_GOND}), то боги распустят свой союз и оставят Кезефа в покое.

Кезеф был настроен скептически, но согласился… при условии, что Тир засунет ему руку в пасть. Тир посмотрел на оскаленные зубы, каждый из которых был длиннее копья, и сразу же согласился.

Конечно же, Кезеф не знал, как прочна цепь, закреплённая в адском Коцитосе и благословлённая богиней магии [Мистрой](GOD:${GOD_MYSTRA}). Не в силах освободиться, он начисто откусил Тиру руку. Тогда бог залечил кровоточащий обрубок и навсегда остался одноруким, считая это своим знаком чести — да это и был знак чести, а ещё смелости и отваги.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    tyrTormIlmater,
  ],
  alignmentId: ALIGNMENT_LG,
  symbolList: 'Сбалансированные весы на боевом молоте',
  domainIdList: [DOMAIN_ORDER, DOMAIN_PROTECTION, DOMAIN_WAR],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 41,
    },
  ],
}
