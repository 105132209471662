const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_WAUKEEN,
} = require('./../../../../godIdList')

const Waukeen_Symbol_SCAG_img = require('./../../../../../images/gods/waukeen-symbol-SCAG.png')
const Waukeen_Symbol_DND3_FnP_img = require('./../../../../../images/gods/waukeen-symbol-DND3_FnP.webp')
const Waukeen_DND3_FnP_img = require('./../../../../../images/gods/waukeen-DND3_FnP.jpg')

module.exports = {
  [GOD_WAUKEEN]: [
    {
      src: Waukeen_Symbol_SCAG_img,
      text: 'Символ Вокин',
      source: {
        id: SOURCE_SCAG,
        page: 42,
      },
    },
    {
      src: Waukeen_Symbol_DND3_FnP_img,
      text: 'Символ Вокин',
      source: {
        id: SOURCE_DND3_FnP,
        page: 88,
      },
    },
    {
      src: Waukeen_DND3_FnP_img,
      text: 'Вокин, Друг Торговца',
      source: {
        id: SOURCE_DND3_FnP,
        page: 89,
      },
    },
  ],
}
