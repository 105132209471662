const {
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_AMAUNATOR,
} = require('./../../../../godIdList')

const Amaunator_Symbol_SCAG_img = require('./../../../../../images/gods/amaunator-symbol-SCAG.webp')

module.exports = {
  [GOD_AMAUNATOR]: {
    src: Amaunator_Symbol_SCAG_img,
    text: 'Символ Амонатора',
    source: {
      id: SOURCE_SCAG,
      page: 25,
    },
  },
}
