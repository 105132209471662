const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_AERDRIE_FAENYA,
} = require('./../../../../godIdList')
const {
  Angharradh_Aerdrie_Corellon_Fenmarel_Sashelas_Erevan_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Aerdrie_Faenya_Symbol_DND3_FnP_img = require('./../../../../../images/gods/aerdrie_faenya-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_AERDRIE_FAENYA]: [
    {
      src: Aerdrie_Faenya_Symbol_DND3_FnP_img,
      text: 'Символ Аэрдри Фэйни',
      source: {
        id: SOURCE_DND3_FnP,
        page: 125,
      },
    },
    Angharradh_Aerdrie_Corellon_Fenmarel_Sashelas_Erevan_DND3_FnP_data,
  ],
}
