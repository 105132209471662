const ladiesOfTheGoldenHills = require('./../constants/ladiesOfTheGoldenHills')
const shadowAndStone = require('./../constants/shadowAndStone')
const {ALIGNMENT_NG} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_BARAVAR_CLOAKSHADOW} = require('./../../godIdList')
const {PANTHEON_GNOME} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  DOMAIN_ARCANA,
  DOMAIN_TRICKERY,
} = require('./../../domainList')

module.exports = {
  id: GOD_BARAVAR_CLOAKSHADOW,
  nameEn: 'Baravar Cloakshadow',
  nameShort: {
    nominative: `Баравар`,
    genitive: `Баравара`,
    dative: `Баравару`,
    accusative: `Баравара`,
    instrumental: `Бараваром`,
    prepositional: `Бараваре`,
  },
  godOfWhat: [
    `иллюзий`,
    `обмана`,
  ],
  nameAlt: [
    'Теневой Плащ',
    'Лукавый',
  ],
  description: [
    shadowAndStone,
    ladiesOfTheGoldenHills,
  ],
  alignmentId: ALIGNMENT_NG,
  symbolList: `кинжал на фоне плаща с капюшоном`,
  domainIdList: [DOMAIN_ARCANA, DOMAIN_TRICKERY],
  pantheonId: PANTHEON_GNOME,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 25,
  },
}
