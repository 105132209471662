const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_AKADI,
} = require('./../../../../godIdList')
const {
  Akadi_Gargauth_Eldath_Finder_Grumbar_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Akadi_Symbol_DND3_FnP_img = require('./../../../../../images/gods/akadi-symbol-DND3_FnP.png')

module.exports = {
  [GOD_AKADI]: [
    {
      src: Akadi_Symbol_DND3_FnP_img,
      text: 'Символ Акади',
      source: {
        id: SOURCE_DND3_FnP,
        page: 25,
      },
    },
    Akadi_Gargauth_Eldath_Finder_Grumbar_DND3_FnP_data,
  ],
}
