const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_LOLTH,
} = require('./../../../../godIdList')
const {
  Kiaransalee_Vhaeraun_Lolth_Selvetarm_Ghaunadaur_Eilistraee_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Lolth_DND3_FnP_img = require('./../../../../../images/gods/lolth-DND3_FnP.webp')
const Lolth_Symbol_DND3_FnP_img = require('./../../../../../images/gods/lolth-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_LOLTH]: [
    {
      src: Lolth_Symbol_DND3_FnP_img,
      text: 'Символ Ллос во время Смутного времени',
      source: {
        id: SOURCE_DND3_FnP,
        page: 42,
      },
    },
    {
      src: Lolth_DND3_FnP_img,
      text: 'Ллос, Паучья Королева',
      source: {
        id: SOURCE_DND3_FnP,
        page: 43,
      },
    },
    Kiaransalee_Vhaeraun_Lolth_Selvetarm_Ghaunadaur_Eilistraee_DND3_FnP_data,
  ],
}
