const {SOURCE_SCAG} = require('./../../sourceList')
const {
  GOD_DEEP_SASHELAS,
  GOD_LABELAS_ENORETH,
  GOD_RILLIFANE_RALLATHIL,
  GOD_SOLONOR_THELANDIRA,
} = require('./../../godIdList')

module.exports =  {
  header: `Боги природы`,
  text: `[Глубинный Сашелас](GOD:${GOD_DEEP_SASHELAS}) — морской бог, владыка морских эльфов и дельфинов.

[Лабелас Энорет](GOD:${GOD_LABELAS_ENORETH}) — бог-философ, божество времени и истории, чей дар транса исключительно важен для идентичности и выживания эльфийского народа.

[Риллифэйн Раллатил](GOD:${GOD_RILLIFANE_RALLATHIL}) — бог лесов и диких мест, отец лесных эльфов и покровитель друидов. Его ближайший союзник — [Солонор Теландира](GOD:${GOD_SOLONOR_THELANDIRA}) — бог охоты, стрельбы из лука и знания леса.`,
  source: {
    id: SOURCE_SCAG,
    page: 109,
  },
}
