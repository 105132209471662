const {
  GOD_SELUNE,
  GOD_SHAR,
} = require('./../../godIdList')
const {SOURCE_GAME_BG_3} = require('./../../sourceList')

module.exports = [
  {
    header: `Открытая ересь`,
    text: `Мало кто признает [Шар](GOD:${GOD_SHAR}) богиней-созидательницей, а [Селунэ](GOD:${GOD_SELUNE}) богиней-разрушительницей, и тем не менее верно и то и другое.

Рождённые во времена первозданного хаоса времени, они вместе создали Абейр с Торилом — и возникла Шонти, Матерь всего сущего. Свет и тьма находились в идеальном равновесии, покоясь в объятиях [Шар](GOD:${GOD_SHAR}) под серебряным светом [Селунэ](GOD:${GOD_SELUNE}).

Но всё же их творения были холодны и пустынны — и тогда Шонти взмолилась о тепле. Эта простая просьба разделила сестёр, навсегда посеяв между ними раздор — ибо Селунэ обрушила истинное пламя в Абейр-Торил и зажгла солнце.

Как могла [Шар](GOD:${GOD_SHAR}) остаться равнодушной к подобному предательству?! Теперь ей пришлось сражаться и против света солнца, и против света луны. Пока богини рвали друг друга в клочья, из света возникли новые боги и новые раздоры — всё во имя толики тепла.

Здесь сокрыт урок: безрассудно ставить эгоистичные желания превыше судеб мира. Слух [Госпожи Утрат](GOD:${GOD_SHAR}) всегда открыт для тех, кто это понимает.

— Этот текст написан крупным жирным почерком, чтобы его было легче читать. На листке нет подписи, за исключением чёрного круга внизу страницы.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
]
