const {ALIGNMENT_NG} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_LATHANDER} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  DOMAIN_LIFE,
  DOMAIN_LIGHT,
} = require('./../../domainList')
const homePray = require('./../constants/homePray')

module.exports = {
  id: GOD_LATHANDER,
  nameEn: 'Lathander',
  nameShort: {
    nominative: 'Латандер',
    genitive: 'Латандера',
    dative: 'Латандеру',
    accusative: 'Латандера',
    instrumental: 'Латандером',
    prepositional: 'Латандере',
  },
  godOfWhat: [
    `весны`,
    `зачатия`,
    `здоровья`,
    `молодости`,
    `обновления`,
    `рассвета`,
    `рождения`,
    `самосовершенствования`,
  ],
  nameAlt: [
    `Утренний Лорд`,
    `Вдохновенный Рассвет`,
    `Розово-золотой Бог`,
  ],
  description: [
    {
      header: `Латандер`,
      text: `Латандер — бог весны, рождения и обновления, божество зачатия, здоровья, молодости и самосовершенствования. Он бог не солнца, но рассвета, который представляется началом нового дня, полного новых возможностей.

Латандер — бог начинаний. Люди часто возносят ему свои молитвы, прежде чем начать какое-то дело или отправиться в путь. Имя Латандера поминают, заключая союзы и основывая предприятия и компании. Это привело к повышенной его популярности среди купечества, от чего его церковь не мало выиграла.

Восходящее солнце — его символ, а розовый, золотой и фиалковый — его цвета. Храмы и святыни Латандера выполняют различные функции, как муниципальные, так и частные. В таких местах проходят церемонии бракосочетаний на рассвете, оглашаются начала гражданских проектов, и даже принимаются роды, когда такое возможно, для того, чтобы ребёнок родился «под счастливой звездой».

Последователи Латандера приветствуют основание новых поселений и рост цивилизации, пока это обеспечивает всех потенциалом для успеха. Они презирают нежить, видя в ней искажение естественного порядка и отрицание новшеств, так как нежить цепляется за своё старое существование, вместо того, чтобы двигаться далее.`,
      source: {
        id: SOURCE_SCAG,
        page: 33,
      },
    },
    {
      header: `Латандер`,
      text: `Повелитель Утренней Зари — покровитель весны и рассвета, рождения и любого начала. Его призывают. чтобы приветствовать новую жизнь и новые начинания. Его последователи приветствуют рост и обновление и презирают нежить.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    homePray,
  ],
  alignmentId: ALIGNMENT_NG,
  symbolList: 'Дорога, ведущая к восходу',
  domainIdList: [DOMAIN_LIFE, DOMAIN_LIGHT],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 33,
    },
  ],
}
