const {ALIGNMENT_CG} = require('./../../aligmentList')
const {DOMAIN_LIFE} = require('./../../domainList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_LLIIRA} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_LLIIRA,
  nameEn: 'Lliira',
  nameShort: {
    nominative: 'Ллиира',
    genitive: 'Ллииры',
    dative: 'Ллиире',
    accusative: 'Ллииру',
    instrumental: 'Ллиирой',
    prepositional: 'Ллиире',
  },
  godOfWhat: [
    `довольства`,
    `лёгкости`,
    `празднеств`,
    `радости`,
    `свободы`,
    `танца`,
  ],
  nameAlt: [
    `Леди Радости`,
    `Несущая Радость`,
    `Хозяйка Пирушек`,
  ],
  description: [
    {
      header: `Ллиира`,
      text: `Ллииру любят, она — богиня довольства, лёгкости, радости, танца и свободы. Покровительница празднеств — Ллиира чествуется на любом торжестве. И танец — лучший способ выразить ей благодарность. Хозяйка пиршеств отвергает любую жестокость — схватка, драка, угрозы (разве что на церемониях) лишают тебя её благосклонности. Это долг жизни — для жрецов и жриц (несущих радость) — делать других счастливыми. Даже если ненадолго.

В одеждах жрецов всегда присутствует хотя бы один радостный, яркий цвет, и одеяния приличествуют более празднику и торжеству, чем церемониальным традициям. Леди почитает рубины и сапфиры, так что, если они в ваших одеждах — жрец Ллииры обязательно благословит вас.

Но — они не легкомысленные люди. Для этих жрецов божественная радость — самый ценный подарок смертным. Они будут сражаться со всеми, кто приносит страдания и несчастье. Они неистовы к врагам, и весело празднуют завершение дела.`,
      source: {
        id: SOURCE_SCAG,
        page: 34,
      },
    },
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_От Ллииры идущая радость_

_Прогоняет печаль и усталость._

_Её ветреный смех_

_Средь пиров и утех_

_Подхватить нам порой удавалось._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_CG,
  symbolList: 'Треугольник из трёх шестиконечных звёзд',
  domainIdList: DOMAIN_LIFE,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 34,
    },
  ],
}
