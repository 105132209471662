const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_GARL_GLITTERGOLD,
} = require('./../../../../godIdList')
const {
  Baervan_Baravar_Callarduran_Flandal_Gaerdal_Garl_Segojan_Urdlen_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Garl_Glittergold_Symbol_DND3_FnP_img = require('./../../../../../images/gods/garl_glittergold-symbol-DND3_FnP.jpg')

module.exports = {
  [GOD_GARL_GLITTERGOLD]: [
    {
      src: Garl_Glittergold_Symbol_DND3_FnP_img,
      text: 'Символ Гарла Златоблеска',
      source: {
        id: SOURCE_DND3_FnP,
        page: 136,
      },
    },
    Baervan_Baravar_Callarduran_Flandal_Gaerdal_Garl_Segojan_Urdlen_DND3_FnP_data,
  ],
}
