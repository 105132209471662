const godsOfWealth = require('./../constants/godsOfWealth')
const {ALIGNMENT_N} = require('./../../aligmentList')
const {DOMAIN_TRICKERY} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_VERGADAIN} = require('./../../godIdList')
const {PANTHEON_MORNDINSAMMAN} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_VERGADAIN,
  nameEn: 'Vergadain',
  nameShort: {
    nominative: 'Вергадэйн',
    genitive: 'Вергадэйна',
    dative: 'Вергадэйну',
    accusative: 'Вергадэйна',
    instrumental: 'Вергадэйном',
    prepositional: 'Вергадэйне',
  },
  godOfWhat: [
    `удачи`,
    `богатства`,
    `воров`,
    `шанса`,
    `торговли`,
    `переговоров`,
  ],
  nameAlt: `Торговый Король`,
  alignmentId: ALIGNMENT_N,
  symbolList: 'Золотая монета с дварфийским лицом',
  description: godsOfWealth,
  domainIdList: DOMAIN_TRICKERY,
  pantheonId: PANTHEON_MORNDINSAMMAN,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 23,
  },
}
