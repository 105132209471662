const drowDarkGods = require('./../constants/drowDarkGods')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_GHAUNADAUR} = require('./../../godIdList')
const {PANTHEON_DROW} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_GHAUNADAUR,
  nameEn: 'Ghaunadaur',
  nameShort: {
    nominative: `Гонадор`,
    genitive: `Гонадора`,
    dative: `Гонадору`,
    accusative: `Гонадора`,
    instrumental: `Гонадором`,
    prepositional: `Гонадоре`,
  },
  worshipperRace: 'дроу',
  godOfWhat: [
    `тины`,
    `грязи`,
    `бунтовщиков`,
    `изгнанников`,
  ],
  nameAlt: [
    'Тот, Кто Скрыт',
    'Гибельная сила',
    'Древнее Око',
  ],
  description: [
    drowDarkGods,
    {
      header: 'Гонадор',
      text: `Тот Кто Скрыт, бог аберраций Подземья, также известный как Древнее Око. Ему поклоняются (если вообще можно так выразиться) слизи и сходные существа.`,
      source: {
        id: SOURCE_SCAG,
        page: 140,
      },
    },
  ],
  pantheonId: PANTHEON_DROW,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 140,
  },
}
