const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_DENEIR,
} = require('./../../../../godIdList')

const Deneir_Symbol_SCAG_img = require('./../../../../../images/gods/deneir-symbol-SCAG.png')
const Deneir_Symbol_DND3_FnP_img = require('./../../../../../images/gods/deneir-symbol-DND3_FnP.jpg')

module.exports = {
  [GOD_DENEIR]: [
    {
      src: Deneir_Symbol_SCAG_img,
      text: 'Символ Денеира',
      source: {
        id: SOURCE_SCAG,
        page: 29,
      },
    },
    {
      src: Deneir_Symbol_DND3_FnP_img,
      text: 'Символ Денеира',
      source: {
        id: SOURCE_DND3_FnP,
        page: 94,
      },
    },
  ],
}
