const {ALIGNMENT_LN} = require('./../../aligmentList')
const {CREATURE_NAGPA} = require('./../../creatureIdList')
const {GENDER_FEMALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  GOD_CORELLON_LARETHIAN,
  GOD_LOLTH,
  GOD_RAVEN_QUEEN,
} = require('./../../godIdList')
const {
  PC_CLASS_SORCERER,
  PC_CLASS_WARLOCK,
  PC_CLASS_WIZARD,
} = require('./../../pcClassIdList')
const {
  SOURCE_DMG,
  SOURCE_MTOF,
} = require('./../../sourceList')
const {
  DOMAIN_LIFE,
  DOMAIN_DEATH,
} = require('./../../domainList')
const {
  ravenQueenAndShadarKaiDescriptionList,
  ravenQueenAndNagpaDescriptionList,
} = require('./../../textCommonParts')

module.exports = {
  id: GOD_RAVEN_QUEEN,
  nameEn: 'Raven Queen',
  nameShort: {
    nominative: 'Королева Воронов',
    genitive: 'Королевы Воронов',
    dative: 'Королеве Воронов',
    accusative: 'Королеву Воронов',
    instrumental: 'Королевой Воронов',
    prepositional: 'Королеве Воронов',
  },
  worshipperRace: 'шадар-каев',
  godOfWhat: 'смерти',
  alignmentId: ALIGNMENT_LN,
  symbolList: `голова ворона в профиль, повёрнута влево`,
  domainIdList: [DOMAIN_LIFE, DOMAIN_DEATH],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  description: [
    {
      header: `Королева Воронов`,
      text: `Королева Воронов — личность довольная мистическая. Опытные волшебники говорят о ней шёпотом, и с немалым страхом, но даже они не могут сказать, какой властью она обладает в своём царстве… слишком непостижима эта власть для разума простых смертных. Имеются много слухов, касающихся её нынешнего облика… многие из них исходят из уст сумасшедших и описывают множество устрашающих образов, к примеру: образ ужасной тени, что цепляется за их самые сокровенные мысли, или образ бледной и величественной эльфийки, которая исчезает, оставляя за собой дюжину воронов, или существо с неуклюже движущимися корнями и ветками, что выглядят пугающе, или образ неизвестной сущности, чей вопль вселял страх в услышавших.

Несмотря на все попытки раскрыть тайну её личности, Королева Воронов до сих пор осталась очередной неразгаданной загадкой. Она правит со своего Трона Воронов из Крепости Воспоминаний — лабиринтоподобного замка, расположенного глубоко внутри мрачного Царства Теней. Оттуда она посылает своих воронов, чтобы найти души, вызывающие интерес для неё, которые она может вырвать из разных планов существования. Как только они попадают в Царство Теней, она наблюдает за тем, как эти души пытаются разгадать тайну своего бытия — и в конце концов сходят с ума в процессе.`,
      source: {
        id: SOURCE_MTOF,
        page: 58,
      },
    },
    {
      header: `Происхождение Королевы Воронов`,
      text: `Для тех, кто стремится разгадать тайну Королевы Воронов, история её происхождения восходит к древней истории эльфов. Говорят, что она когда-то была королевой эльфов, чьи люди любили её больше, чем самих богов. Её истинное имя было потеряно во времени. Но, исходя из отрывков из её истории, она была той, что пыталась использовать дух и магию своего народа для возвышения самой себя до божественного статуса, спасая таким образом расколотый пантеон эльфов, пока [Кореллон](GOD:${GOD_CORELLON_LARETHIAN}) и [Ллос](GOD:${GOD_LOLTH}) враждовали друг с другом. Впоследствии, по легендам, она пыталась призвать [Кореллона](GOD:${GOD_CORELLON_LARETHIAN}) и [Ллос](GOD:${GOD_LOLTH}) прекратить вражду. Но сведения из этих отрезков, к сожалению, неполны, и до сих пор непонятны истинные намерения Королевы.`,
      source: {
        id: SOURCE_MTOF,
        page: 58,
      },
    },
    {
      header: `Падение в Тень`,
      text: `Так как могущество королевы возросло, многие эльфы вдохновились ею и открыто предложили ей свои души и магические способности, чтобы помочь ей достигнуть цели. Эта группа преданных последователей назвала себя шадар-каями, и они собрали вокруг королевы других единомышленников в надежде, что как только она достигнет божественности, она объединит всех эльфов. План королевы состоял в том, чтобы использовать души шадар-каев для того, чтобы проложить путь через Страну Фей в Арвандор, усиливая, при этом своё собственное влияние.

С увеличением числа шадар-каев, объединившиеся злые волшебники из числа её последователей увидели возможность выкачать энергию шадар-каев для своих целей, проведя свой собственный ритуал, который даст им магические силы, могущественнее чем у самых великих эльфийских волшебников из легенд. Но приблизившись ко входу в Арвандор, королева поняла, что сделали эти волшебники и выпустила свой гнев на них, когда ритуал шёл полным ходом. Поскольку она к тому моменту уже была квазибожественной сущностью, её сверхъестественный гнев исказил ритуал в явление, которое получило собственную ужасную силу.

К тому моменту, когда королева осознала свою ошибку и почувствовала, что искривлённая магическая энергия охватила её, она была уже бессильна остановить это. В панике она обратилась к душам шадар-каев в надежде получить больше силы и спасти себя, но тяжесть заклинания стала непреодолимой. Оно втянуло королеву и всех, кто находился под её влиянием, в Царство Теней, где она мгновенно погибла. А из её разорённого тела и разума родилась Королева Воронов.`,
      source: {
        id: SOURCE_MTOF,
        page: 58,
      },
    },
    {
      header: `Создание нагп`,
      text: `Когда их ритуал потерпел неудачу с катастрофическими результатами, волшебники в консорциуме были втянуты в Царство Теней вместе с королевой и шадар-каями, но их невзгоды на этом не закончились. Их бывшая королева возникла в центре лабиринта из пепла и вместе с криком извергла из уст клубы чёрного дыма, что пронизали плоть и разум волшебников, очерняя их кости и раздирая их души.

Их крики боли слились с её собственным, а когда её крик исчез, волшебники были видоизменены и искажены в уродливых существ подобно стервятникам, известных как [нагпы](CREATURE:${CREATURE_NAGPA}). Теперь они блуждают по планам как презренные чудовища, навеки помеченные проклятием Королевы Воронов и изгнанные ею.`,
      source: {
        id: SOURCE_MTOF,
        page: 58,
      },
    },
    {
      header: `После падения`,
      text: `После того, как [нагпы](CREATURE:${CREATURE_NAGPA}) были созданы, а затем изгнаны Королевой Воронов, шадар-каи наблюдали как она впадала всё глубже и глубже в божественное безумие. Её боль и смятение от предательства её волшебников, уничтожения собственного королевства, а также неудача в достижении божественности — всё это крайне обескуражило её. В то же самое время, энергия искажённого ритуала всё ещё изменяла её, разделяя её облик из физического тела в сущность, состоящую из символов, изображений и ощущений.

Чтобы не дать себе полностью раствориться в небытие, королева использовала последние остатки своей собственной силы, чтобы вытянуть из Царства Теней мёртвые воспоминания о себе, создав завесу личностей, что удерживали её. Веками эти тёмные воспоминания накоплялись и сливались друг с другом, создавая образ нынешней сущности, чьё имя теперь Королева Воронов.`,
      source: {
        id: SOURCE_MTOF,
        page: 58,
      },
    },
    {
      header: `Крепость Воспоминаний`,
      text: `С момента достижения божественности, Королева Воронов начала заполнять своё королевство тенями и воспоминаниями, одержимо собирая эссенцию из остатков мёртвых богов и смертных, что были раскиданы по всему Царству Теней. Из этих метафизических осколков она сформировала свой новый дом, искривлённый замок, который шадар-каи называют Крепостью Воспоминаний. Эта крепость — весьма мрачное место, заполненное непрекращающимся эхом прошлого. Стаи воронов, что служат её глазами и ушами, затмевают небеса вокруг крепости, залетая внутрь неё, и передают ей зашифрованные сообщения и знамения из всей мультивселенной.`,
      source: {
        id: SOURCE_MTOF,
        page: 59,
      },
    },
    {
      header: `Причудливый Зверинец`,
      text: `В Крепости Воспоминаний имеется множество безделушек и предметов, которые Королева Воронов считает неотразимыми, а также обрывки человеческих воспоминаний о прошлом, содержащие в себе глубокие чувства боли, горя, тоски, вины или раскаяния. Эти предметы шадар-каи преподносят ей в качестве даров. Эти безделушки могут включать в себя мебель, часы, зеркала, драгоценности и игрушки.

Также в крепости обитают призрачные видения людей, мест и домашних животных. Любая из этих вещей может незамедлительно дать информацию о ней, а каждый объект или явление может в переносном смысле стать изложением какой-либо истории (большой или маленькой), что насыщена острыми эмоциями.`,
      source: {
        id: SOURCE_MTOF,
        page: 59,
      },
    },
    {
      header: `Столкновение с Королевой Воронов`,
      text: `У смертных, что вступают во владения Королевы Воронов, почти сразу же возникают проблески собственного внутреннего мира. Из-за своей одержимости эмоциями, Королева Воронов проникает в сознание своих посетителей, демонстрируя странные видения из глубин их разума. Некоторые из этих посетителей являются невольными духами покойных людей, что были затянуты сюда в лапы Королевы Воронов, а другие — астральные путешественники, пойманные и застрявшие в ловушке посреди Царства Теней благодаря её магии, но есть и те, кто пришёл по собственной воле в поисках знаний или свободы от тёмного прошлого.

Большинство из этих смелых индивидов являются искателями приключений, которые знают об ужасающей силе Королевы Ворона, но всё равно прибывают в Царство Теней, чтобы пройти её испытания, тем самым находя свою собственную цель в жизни. Существует множество причин, почему они решили оказаться в таком ужасном месте, среди них:

* Освобождение самого себя от тёмного и ужасного прошлого. Ходят слухи, что Королева Воронов может помочь вам в преодолении собственных страхов;
* Раскрытие тайны мертвеца. Искателям приключений скорее всего понадобится пойти в Царство Теней с целью найти душу, которая потребовалась Королеве Воронов, надеясь раскрыть его воспоминания.
* Поиск ответов, которые известны только Королеве Воронов. Царство Королевы Воронов содержит бесконечное множество воспоминаний со всей мультивселенной. Может у отчаявшихся искателей приключений останется единственной надеждой найти Королеву в её царстве, либо же найдут её с помощью цепочки найденных улик`,
      source: {
        id: SOURCE_MTOF,
        page: 59,
      },
    },
    {
      header: `Задание в Крепости Воспоминаний`,
      text: `Поскольку у Королевы Воронов есть богоподобная сила, она может бросить группу искателей приключений внутрь демиплана, что был создан из подсознания одного из персонажей. При входе в Крепость Воспоминаний, либо при столкновении с Королевой Воронов, персонаж может почувствовать, что оказался в странном сказочном мире, представленный в его разуме, заполненный образами, иносказаниями и аллегориями — всё из перечисленного бросает вызов уязвимым местам, страхам и желаниям данного персонажа. Многому можно научится во время странствия в крепости и прохождении испытания Королевы Воронов, но и многое также может быть потеряно. Многие искатели приключений никогда не возвращаются из крепости и навсегда остаются в ловушке внутри мира, что был создан из собственного жизненного опыта.`,
      source: {
        id: SOURCE_MTOF,
        page: 59,
      },
    },
    {
      header: `Намерения или безумие?`,
      text: `Некоторые волшебники и другие учёные предполагают, что Королева Воронов просто безумна, а в её намерениях нет никакой логики, кроме как нервирующих поступков, не имеющих никаких оснований подобно любопытному ребёнку, наступающему ногой на муравья. Другие же считают, что Королева Воронов нуждается во всплеске эмоций, чтобы сохранять воедино свою вечно загнивающую личность. Но лишь несколько из этих мыслителей предположили, что замысел Королева Воронов имеет огромное значение, а она сама служит неким фильтром, что очищает души, которые хватаются за страх и боль, вынуждая их решать свои незаконченные дела, дабы они освободились от морального груза и смогли возвыситься для исследования Верхних планов.`,
      source: {
        id: SOURCE_MTOF,
        page: 60,
      },
    },
    {
      header: `Авторитет Королевы Воронов`,
      text: `Стремление Королевы Воронов препятствовать божественным делам было посчитали изменой и [Кореллоном](GOD:${GOD_CORELLON_LARETHIAN}) и [Ллос](GOD:${GOD_LOLTH}). В результате материальная реальность её царства была перемещена в Царство Теней, а память о её существовании была стёрта из памяти эльфов. Сначала никто из смертных не знал о ней, но веками спустя, те, кто путешествовал по Царству Теней и те, кто столкнулся с шадар-каями вживую, либо сами видели, либо слышали рассказы о тёмной крепости, о таинственной личности окружённой дохлыми слугами, и о множестве разумных воронов. Многие из тех, кто слышал о Королеве Воронов, верят в суеверия, связанные с ней, приписывая ей всевозможные странные происшествия, неудачи и совпадения. Но те, кто серьёзно изучает магию, вроде [колдунов](PC_CLASS:${PC_CLASS_WARLOCK}), [волшебников](PC_CLASS:${PC_CLASS_WIZARD}), [чародеев](PC_CLASS:${PC_CLASS_SORCERER}) и им подобных, знают, что её влияние на мир чревато более серьёзными последствиями.`,
      source: {
        id: SOURCE_MTOF,
        page: 60,
      },
    },
    {
      header: `Встреча после смерти`,
      text: `Некоторые искатели приключений утверждают, что видели Королеву Воронов после смерти — до того, как их верные друзья воскресили их. Когда они находились в загробном мире, Королева Воронов поручила им выполнить задание, получить особый предмет, или скорее всего, придти к указанному месту и просто ждать дальнейших указаний. Многие из тех, кто говорил о такой встрече, утверждают, что им бесконтрольно необходимо было выполнить её поручение, поскольку видения, в которых была Королева Воронов, ясно давали понять, что это поручение является, в некотором роде, частью их великого предназначения.

Причина для Королевы Воронов общаться таким образом является предметом некоторых споров. Некоторые мыслители полагают, что она использует таких индивидов как пешек в своей загадочной игре, правила которой известны только ей и Леди Боли. Другие предполагают, что она пытается сбалансировать мультивселенную, ставя перед смертными различные задачи, а некоторые верят, что именно благодаря таким моментам почтения в её адрес Королева Воронов восстанавливает фрагменты своей прежней личности.`,
      source: {
        id: SOURCE_MTOF,
        page: 60,
      },
    },
    ...ravenQueenAndShadarKaiDescriptionList,
    ...ravenQueenAndNagpaDescriptionList,
    {
      text: `Королева Воронов была списана с Хель из скандинавского пантеона и с Ви Джас из Серого Ястреба.`,
      source: {
        id: SOURCE_DMG,
        page: 11,
      },
    },
  ],
  note: {
    text: `Королева воронов поймана в ловушку своей одержимости прошлым. Она сидит в собственной крепости, всматриваясь среди всех воспоминаний мира лишь на те, что удовлетворяют её, словно на драгоценные камни. Многие великие волшебники пытались понять её мотивы, но, подобно ворону, она всегда остаётся загадочной, храня свои секреты вне их досягаемости.`,
    author: `Высокая Леди Алустриэль Среброрукая`,
  },
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_DMG,
    page: 10,
  },
}
