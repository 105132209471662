const {ALIGNMENT_N} = require('./../../aligmentList')
const {DOMAIN_NATURE} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_SILVANUS} = require('./../../godIdList')
const {CREATURE_GOBLIN} = require('./../../creatureIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_SILVANUS,
  nameEn: 'Silvanus',
  nameShort: {
    nominative: 'Сильванус',
    genitive: 'Сильвануса',
    dative: 'Сильванусу',
    accusative: 'Сильвануса',
    instrumental: 'Сильванусом',
    prepositional: 'Сильванусе',
  },
  godOfWhat: [
    `дикой природы`,
  ],
  nameAlt: [
    `Отец Дубов`,
    `Старый Дуб`,
    `Старое Дерево-Отец`,
  ],
  description: [
    {
      header: `Сильванус`,
      text: `Сильванус олицетворяет природу во всем её разнообразии: пустыни и леса, оленей и акул. Но люди Севера, которые в повседневной жизни чаще сталкиваются с опасностями гор, равнин и лесов, видят Сильвануса больше как бога именно таких мест. Сильвануса представляют мрачным и суровым отцом, который управляет наводнениями или засухой, огнём и льдом, жизнью и смертью в дикой местности. В легендах он часто повелевает другими природными божествами, поощряя и наказывая их по своему усмотрению.

Природа и её беспристрастная честность является центром догматов веры в Сильвануса. Его жречество пытается увидеть картину макрокосма вообще, а их точка зрения на то, что является добром, не ограничивается взглядом отдельных индивидов или стран. Потеря фермерского хутора из-за налёта [гоблинов](CREATURE:${CREATURE_GOBLIN}) для единиц — трагедия, но это событие также предоставляет возможность диким землям расшириться, а земле — снова стать плодородной, что в свою очередь бросит новый вызов для тех, кто решит вернуться и укротить её снова.

Кредо Сильвануса гласит, что природа должна быть сохранена нетронутой не ради её красот, но потому, что это и есть нормальное состояние мира. Её просторы обновляют и вдыхают новые силы в смертные души, давая глоток свежего воздуха всему миру. Многие из его последователей противостоят расширению поселений в дикую местность и считают, что чрезмерное потребление природных ресурсов не только расточительством, но и богохульством.

Сильвануса почитают путешественники по диким местам, исследователи и жители сельских селений, расположенных вдали от защиты местного лорда или крупного города. Символ Сильвануса — дубовый лист, и рощица дубов в поселении или на его окраине часто становится часовней Сильвануса. В тех местах, где дубы не растут, дубовые листья прикрепляют к коре других деревьев, чтобы обозначить священное место, посвященное ему.`,
      source: {
        id: SOURCE_SCAG,
        page: 38,
      },
    },
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_Всё живое, все твари на свете —_

_Для Сильвануса милые дети._

_Если ты их обидишь,_

_Очень скоро — увидишь! —_

_Позавидуешь даже котлете._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Псалмы Отцу деревьев`,
      text: `Его листва, и дерева — суть Он. Растить их есть наш долг. Презреть их есть презреть Отца.

Его суть травы, и их стебли — Он. Он тешит наши чувства и смягчает землю. Касаясь их, имы познаём Его.

Его — огонь, и пламень — тоже Он. Страшась огня, мы знаем Его мощь. Раздуть их в злобе — изменить Ему.

Сильванус, даруй нам мир. Сильванус, даруй нам силу. Сильванус, искорени нашу злобу.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_N,
  symbolList: 'Дубовый лист',
  domainIdList: DOMAIN_NATURE,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 38,
    },
  ],
}
