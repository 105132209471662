const {
  SOURCE_MM,
  SOURCE_SCAG,
} = require('./sourceList')
const {
  PC_RACE_DWARF,
  PC_RACE_ELF,
  PC_RACE_GNOME,
  PC_RACE_HALFLING,
  PC_RACE_HUMAN,
  PC_RACE_ORC,
} = require('./pcRaceIdList')
const {
  PC_SUBRACE_ELF_DROW,
} = require('./pcSubraceIdList')
const {
  GOD_SHAR,
  GOD_SELUNE,
} = require('./godIdList')

const listToCollectionById = require( './../utils/listToCollectionById')

const PANTHEON_DROW = 'drow'
const PANTHEON_EBERRON = 'eberron'
const PANTHEON_FORGOTTEN_REALMS = 'forgotten_realms'
const PANTHEON_GIANT = 'giant'
const PANTHEON_GNOME = 'gnome'
const PANTHEON_HIN = 'hin'
const PANTHEON_MORNDINSAMMAN = 'morndinsamman'
const PANTHEON_NON_HUMAN = 'non_human'
const PANTHEON_ORC = 'orc'
const PANTHEON_SELDARINE = 'seldarine'
const PANTHEON_SNAKE = 'snake'

const pantheonList = [
  {
    id: PANTHEON_MORNDINSAMMAN,
    name: 'Морндинсамман',
    nameAlt: 'Дварфийский пантеон',
    worshipper: {
      raceId: PC_RACE_DWARF,
    },
    description: {
      header: `Божества дварфов`,
      text: `Боги дварфов образуют пантеон или клан, известный как Морндинсамман.`,
      source: {
        id: SOURCE_SCAG,
        page: 105,
      },
    },
  },
  {
    id: PANTHEON_SELDARINE,
    name: 'Селдарин',
    nameAlt: 'Эльфийский пантеон',
    worshipper: {
      raceId: PC_RACE_ELF,
    },
    description: {
      header: 'Селдарин',
      text: `Боги Тель’Кессир, известные как Селдарин, с незапамятных времен воплощают идеалы народа эльфов. Считается, что они живут в королевстве Арвандор плана Арборея.`,
      source: {
        id: SOURCE_SCAG,
        page: 108,
      },
    },
  },
  {
    id: PANTHEON_DROW,
    name: 'Тёмные Селдарин',
    nameAlt: 'Пантеон дроу',
    worshipper: {
      subRaceId: PC_SUBRACE_ELF_DROW,
    },
    description: {
      header: 'Божества дроу',
      text: `Боги дроу капризны и коварны, как и их поклонники`,
      source: {
        id: SOURCE_SCAG,
        page: 109,
      },
    },
  },
  {
    id: PANTHEON_FORGOTTEN_REALMS,
    name: 'Пантеон Забытых Королевств',
    worshipper: {
      raceId: PC_RACE_HUMAN,
    },
    description: {
      header: 'Божества людей',
      text: `Плодовитость и разнообразие человеческой расы на Фаэруне лучше, чем где-либо отражено в разнообразии богов, коим поклоняются люди. Фаэрунский пантеон включает в себя богов каждой прослойки людей, и сферы части этих божеств пересекаются и дополняют друг друга — всё так как любят люди.

Вдоль Побережья мечей, большинство человеческих общин имеют храмы и часовни, за которыми ухаживают жрецы различных фаэрунских богов. В некоторых таких сообществах последователи богов, почитаемых правителями и властьимущими, играют большую социальную роль, чем последователи богов не столь почитаемых. В крайних случаях, поклонение, считающееся еретическим и опасным, становится незаконным — например, в регионах, где властью и силой наделены поклонники [Шар](GOD:${GOD_SHAR}), поклонение её доброму двойнику и вечной противнице [Селунэ](GOD:${GOD_SELUNE}) может быть противозаконным.`,
      source: {
        id: SOURCE_SCAG,
        page: 113,
      },
    },
  },
  {
    id: PANTHEON_HIN,
    name: 'Пантеон полуросликов',
    worshipper: {
      raceId: PC_RACE_HALFLING,
    },
    description: {
      header: 'Боги полуросликов',
      text: `Пантеон хин не так велик. Это скорее узкий, почти семейный круг богов, которых почитают также — у домашнего очага, в небольших придорожных часовнях и лесистых рощицах.`,
      source: {
        id: SOURCE_SCAG,
        page: 110,
      },
    },
  },
  {
    id: PANTHEON_GNOME,
    name: 'Лорды Золотых Холмов',
    nameAlt: 'Пантеон гномов',
    worshipper: {
      raceId: PC_RACE_GNOME,
    },
    description: {
      header: 'Гномьи боги',
      text: `Гномы почитают маленький пантеон из семи основных божеств, известных как Лорды Золотых Холмов, и ещё двух других сущностей.`,
      source: {
        id: SOURCE_SCAG,
        page: 116,
      },
    },
  },
  {
    id: PANTHEON_ORC,
    name: 'Первая семья',
    nameAlt: [
      'Пантеон орков',
      'Племя Того, Кто Зрит',
    ],
    worshipper: {
      raceId: PC_RACE_ORC,
    },
  },
  {
    id: PANTHEON_EBERRON,
    name: 'Пантеон Эберрона',
  },
  {
    id: PANTHEON_NON_HUMAN,
    name: 'Нечеловеческие божества',
  },
  {
    id: PANTHEON_SNAKE,
    name: 'Змеиные боги',
    nameAlt: 'Пантеон юань-ти',
    description: {
      header: 'Змеиные боги',
      text: `Юань-ти почитают ряд могущественных сущностей как богов.`,
      source: {
        id: SOURCE_MM,
        page: 315,
      },
    },
  },
  {
    id: PANTHEON_GIANT,
    name: 'Боги великанов',
  },
]

module.exports = pantheonList
module.exports.pantheonCollection = listToCollectionById(pantheonList)

module.exports.PANTHEON_DROW = PANTHEON_DROW
module.exports.PANTHEON_EBERRON = PANTHEON_EBERRON
module.exports.PANTHEON_FORGOTTEN_REALMS = PANTHEON_FORGOTTEN_REALMS
module.exports.PANTHEON_GIANT = PANTHEON_GIANT
module.exports.PANTHEON_GNOME = PANTHEON_GNOME
module.exports.PANTHEON_HIN = PANTHEON_HIN
module.exports.PANTHEON_MORNDINSAMMAN = PANTHEON_MORNDINSAMMAN
module.exports.PANTHEON_NON_HUMAN = PANTHEON_NON_HUMAN
module.exports.PANTHEON_ORC = PANTHEON_ORC
module.exports.PANTHEON_SELDARINE = PANTHEON_SELDARINE
module.exports.PANTHEON_SNAKE = PANTHEON_SNAKE
