const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_MORADIN,
} = require('./../../../../godIdList')
const {
  Haela_Moradin_Laduguer_Sharindlar_Marthammer_Thard_Vergadain_DND3_FnP_data,
  Dwarf_Female_Cleric_Knowledge_PHB_data,
} = require('./../../../commonImageCollection')

const Moradin_Symbol_DND3_FnP_img = require('./../../../../../images/gods/moradin-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_MORADIN]: [
    {
      src: Moradin_Symbol_DND3_FnP_img,
      text: 'Символ Морадина',
      source: {
        id: SOURCE_DND3_FnP,
        page: 121,
      },
    },
    Haela_Moradin_Laduguer_Sharindlar_Marthammer_Thard_Vergadain_DND3_FnP_data,
    Dwarf_Female_Cleric_Knowledge_PHB_data,
  ],
}
