const {SOURCE_SCAG} = require('./../../sourceList')
const {PC_RACE_DWARF} = require('./../../pcRaceIdList')
const {
  GOD_CLANGEDDIN,
  GOD_HAELA,
  GOD_GORM_GULTHYN,
} = require('./../../godIdList')

module.exports = {
  header: 'Боги битвы',
  text: `[Клангеддин Сребробородый](GOD:${GOD_CLANGEDDIN}) — дварфийский бог войны и доблести.

[Горм Галтин](GOD:${GOD_GORM_GULTHYN}), также именуемый Лордом Бронзовой Маски и Огненными Глазами, бог защиты и осторожности, хранитель [дварфов](PC_RACE:${PC_RACE_DWARF}).

[Хаэла Светлый Топор](GOD:${GOD_HAELA}) — богиня удачи в бою и покровительница дварфийских бойцов.`,
  source: {
    id: SOURCE_SCAG,
    page: 105,
  },
}
