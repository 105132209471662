const {ALIGNMENT_N} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_ANNAM} = require('./../../godIdList')
const {PANTHEON_GIANT} = require('./../../pantheonList')
const {
  godAnnamOnlyDescriptionList,
  giantGodsCommonDescriptionList,
} = require('./../../textCommonParts')

module.exports = {
  id: GOD_ANNAM,
  nameEn: 'Annam',
  nameAlt: 'Всеотец',
  nameShort: {
    nominative: `Аннам`,
    genitive: `Аннама`,
    dative: `Аннаму`,
    accusative: `Аннама`,
    instrumental: `Аннамом`,
    prepositional: `Аннаме`,
  },
  godOfWhat: [
    `всех великанов`,
  ],
  description: [
    ...godAnnamOnlyDescriptionList,
    ...giantGodsCommonDescriptionList,
  ],
  alignmentId: ALIGNMENT_N,
  pantheonId: PANTHEON_GIANT,
  genderId: GENDER_MALE,
}
