const {ALIGNMENT_CG} = require('./../../aligmentList')
const {GENDER_MIDDLE} = require('./../../genderList')
const {GOD_CORELLON_LARETHIAN} = require('./../../godIdList')
const {PANTHEON_SELDARINE} = require('./../../pantheonList')
const {
  DOMAIN_ARCANA,
  DOMAIN_LIGHT,
} = require('./../../domainList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_CORELLON_LARETHIAN,
  nameEn: 'Corellon Larethian',
  nameShort: {
    nominative: `Кореллон Ларетиан`,
    genitive: `Кореллона Ларетиана`,
    dative: `Кореллону Ларетиану`,
    accusative: `Кореллона Ларетиана`,
    instrumental: `Кореллоном Ларетианом`,
    prepositional: `Кореллоне Ларетиане`,
  },
  godOfWhat: [
    `эльфов`,
    `искусства`,
    `магии`,
    `поэзии`,
    `правления`,
    `войны`,
  ],
  alignmentId: ALIGNMENT_CG,
  symbolList: [`месяц`, `звезда`, 'полумесяц'],
  description: [
    {
      header: 'Бог эльфов',
      text: `Кореллон Ларетиан — мудрый лидер Селдарин, бог эльфов, магии, поэзии, правления и войны. Хотя он считается отцом эльфийской расы, его изображают и в женском обличии — ничуть не реже, чем в мужском.`,
      source: {
        id: SOURCE_SCAG,
        page: 108,
      },
    },
    {
      header: `Кореллон Ларетиан`,
      text: `Создатель эльфов Кореллон Ларетиан правит всем эльфийским пантеоном и благословляет тех, кто изучает искусства, магию и природу.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Ларетиан`,
      text: `„Не будет преувеличением сказать, что Кореллон Ларетиан — это главная фигура в пантеоне эльфов. Сказано, что это его слёзы, смешавшись со слезами Сеанин Лунной Радуги, фактически _создали_ эльфов.

Как и во всех мифах, здесь нас интересует обратная физиологическая жертва (которую бог приносит своему народу). Эльфийский народ сотворён не из бедра Кореллона, не из горла и не из яичек. Эльфы появились из выражения печали. В связи с этим некоторые богословы называют эльфов средством унять боль Кореллона (который в то время переживал потерю и предательство).

Более распространено мнение считать эльфов (и здесь я цитирую эльфийского мудреца Эйфира Локеллана) «настолько печальными, насколько это вообще возможно».

Для меня это звучит более правдиво. В конце концов, жизнь постоянно подбрасывает трудности тем, кто обременён обязанностью её прожить, а эльфы живут очень и очень долго.“

— предисловие к «Ларетиану» Палмеры Юнисугги`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  domainIdList: [DOMAIN_ARCANA, DOMAIN_LIGHT],
  pantheonId: PANTHEON_SELDARINE,
  genderId: GENDER_MIDDLE,
  source: [
    {
      id: SOURCE_PHB,
      page: 297,
    },
    {
      id: SOURCE_SCAG,
      page: 24,
    },
  ],
}
