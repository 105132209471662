const {GENDER_FEMALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {DOMAIN_TEMPEST} = require('./../../domainList')
const {ALIGNMENT_CE} = require('./../../aligmentList')
const {GOD_UMBERLEE} = require('./../../godIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_UMBERLEE,
  nameEn: 'Umberlee',
  nameShort: {
    nominative: 'Амберли',
    genitive: 'Амберли',
    dative: 'Амберли',
    accusative: 'Амберли',
    instrumental: 'Амберли',
    prepositional: 'Амберли',
  },
  godOfWhat: [
    `морей`,
  ],
  nameAlt: [
    `Королева Глубин`,
    `Королева-Сука`,
    `Матерь бурь`,
    `Мать Волн`,
    `Сучья королева`,
  ],
  description: [
    {
      header: 'Амберли',
      text: `Ни одно сообщество, живущее у моря, не может игнорировать влияние Амберли, гневной богини, чей крутой нрав отражает и отражается морскими водами и глубинами. Любое такое сообщество следит за тем, чтобы вовремя проводились мероприятия, чтобы умилостивить Мать Волн и получить её благосклонность. Хотя она и непостоянная нравом, она может быть щедрой с теми, кто почитает её, как и любая великая королева.

Королеву-Суку почитают в большей степени из страха, чем из восхищения — команды кораблей предлагают ей драгоценные камни, швыряя их за борт, чтобы успокоить мечущиеся штормовые волны. Как и предполагает наиболее распространённое её прозвище, она представляется капризной, жестокой и не имеющей чётких этических предпочтений; море сурово, и лучше бы тем, кто путешествуют по нём, быть готовым заплатить свою цену за вторжение в её владения.

Немногие стоят на пути организованного духовенства Амберли. Ее жрецы бродят по прибрежным городам, предупреждая о гибели и требуя свободного прохода на судах в обмен на обеспечение благосклонности богини. Часто они носят цвета морской волны или бури, они украшают себя предметами, которые напоминают о других опасностях моря: ожерелье из зубов акулы, морские водоросли, обернутые вокруг кости человека, и так далее. Сохранившаяся рука утонувшего человека считается особенно священным объектом, и некоторые из её немногих клириков используют такие отрезанные руки, как священные символы. Амберли принадлежит большое количество святынь в прибрежных городах, и моряки часто оставляют цветы или маленькие конфеты на них в надежде, что она пощадить их в следующем плавании. И в Глубоководье, и во Вратах Балдура есть истинные храмы, посвященные Амберли, где служат, в основном, вдовы погибших в море моряков.`,
      source: {
        id: SOURCE_SCAG,
        page: 41,
      },
    },
    {
      header: 'Шанти для Сучьей Царевны',
      text: `
_«Вёсла на воду!_

_Соль на руках._

_Вёсла на воду!_

_Ты солью пропах._

_Вёсла на воду!_

_Крепче штурвал!_

_Сучья королева попридержит шквал._



_Матерь бурь!_

_Прикуй нас к мачте, Матерь бурь!_

_Матерь бурь!_

_Пошли удачи, Матерь бурь!_

_Коли хочешь, утопи нас,_

_Матерь бурь!_

_В твоей власти наши кости, Матерь бурь._



_Чиним снасти, грот провис_

_Ждём-пождём захудалый бриз._

_Сучьей королеве нынче недосуг._

_Пустим чашу грога вкруг!_



_Йо-хо-хо!_

_Так и будет._

_Йо-хо-хо!_

_Она не забудет._

_Йо-хо-хо!_

_Она обещала!_

_Йо-хо-хо!_

_Она соврёт!»_

— Пропитанный солью сборник моряцких шанти, посвящённый богине моря Амберли.
`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_CE,
  symbolList: 'Волны, расходящиеся влево и вправо',
  domainIdList: DOMAIN_TEMPEST,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
  ],
}
