const {ALIGNMENT_CE} = require('./../../aligmentList')
const {DOMAIN_DEATH} = require('./../../domainList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_TALONA} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_TALONA,
  nameEn: 'Talona',
  nameShort: {
    nominative: 'Талона',
    genitive: 'Талоны',
    dative: 'Талоне',
    accusative: 'Талону',
    instrumental: 'Талоной',
    prepositional: 'Талоне',
  },
  godOfWhat: [
    `болезней`,
    `ядов`,
  ],
  nameAlt: [
    `Леди Яда`,
    `Госпожа Болезней`,
    `Чумная Старуха`,
  ],
  description: `Одно из наиболее часто задабриваемых божеств Фаэруна, Талона является богиней болезней и ядов, которую обвиняют во всём от обычного недомогания и неурожая, до высохших колодцев и чумы. Талону, изображаемую в храмовой иконографии высохшей старухой с чашей или вазой в руках, в которой находятся всевозможные болезни и яды, боятся и возносят ей множество молитв о защите от недугов и отравлений.

Различные ритуалы её умиротворения включают в себя три капли крови или три слезы, которыми следует окропить высохший колодец или костёр из засохшей жатвы, смочить носовой платок больного, влить их в рот зачумлённого или ещё что-либо подобное. Обычной практикой является маркирование ядов её святым символом — три капли в треугольнике, а во время эпидемий, люд отмечает этим символом дома заражённых.

Несмотря на многочисленность молитв, у Талоны почти нет храмов и число культов, посвятивших себя ей, исчезающе мало. Культ или её святилище могут появиться там, где прошёл мор и один или несколько выживших решат посвятить себя ей или даже стать её жрецами.`,
  alignmentId: ALIGNMENT_CE,
  symbolList: 'Три капли в треугольнике',
  domainIdList: DOMAIN_DEATH,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 39,
    },
  ],
}
