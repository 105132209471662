const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_DEEP_SASHELAS,
} = require('./../../../../godIdList')
const {
  Angharradh_Aerdrie_Corellon_Fenmarel_Sashelas_Erevan_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Deep_Sashelas_Symbol_DND3_FnP_img = require('./../../../../../images/gods/deep-sashelas-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_DEEP_SASHELAS]: [
    {
      src: Deep_Sashelas_Symbol_DND3_FnP_img,
      text: 'Символ Сашеласа Глубинного',
      source: {
        id: SOURCE_DND3_FnP,
        page: 127,
      },
    },
    Angharradh_Aerdrie_Corellon_Fenmarel_Sashelas_Erevan_DND3_FnP_data,
  ],
}
