const {SOURCE_SCAG} = require('./../../sourceList')
const {PC_RACE_DWARF} = require('./../../pcRaceIdList')
const {
  GOD_VERGADAIN,
  GOD_ABBATHOR,
} = require('./../../godIdList')

module.exports = {
  header: 'Боги богатства',
  text: `[Вергадэйн](PC_RACE:${GOD_VERGADAIN}), именуемый Торговым Королём, — бог воров (повелевший своим последователям никогда не красть у других [дварфов](PC_RACE:${PC_RACE_DWARF})), удачи и шанса, а также торговли и переговоров.

[Аббатор](PC_RACE:${GOD_ABBATHOR}) — бог жадности, иногда изображается в виде дракона, преисполненного зависти к чужому богатству, который ревностно следит за накопленными им сокровищами.`,
  source: {
    id: SOURCE_SCAG,
    page: 106,
  },
}
