const {
  CREATURE_LICH,
  CREATURE_SKELETON,
  CREATURE_WRAITH,
  CREATURE_ZOMBIE,
} = require('./../../creatureIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GOD_BANE,
  GOD_BHAAL,
  GOD_JERGAL,
  GOD_KELEMVOR,
  GOD_MALAR,
  GOD_MYRKUL,
  GOD_TYMORA,
} = require('./../../godIdList')

module.exports = [
  {
    header: 'Трое Мёртвых',
    text: `Трое Мёртвых — [Баал](GOD:${GOD_BHAAL}), [Бейн](GOD:${GOD_BANE}) и [Миркул](GOD:${GOD_MYRKUL}) — когда-то были смертными, но обрели божественность, заключив странную сделку с [Джергалом](GOD:${GOD_JERGAL}), изначальным богом смерти, убийств и раздора`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  {
    header: 'Мёртвая троица',
    text: `Мёртвой Троицей называют [Бэйна](GOD:${GOD_BANE}), [Баала](GOD:${GOD_BHAAL}) и [Миркула](GOD:${GOD_MYRKUL}), которые давным-давно прошли путь от искателей приключений до богов. С тех пор они стали ещё амбициознее. Они попытались похитить _Скрижали Судьбы_ у верховного бога Ао и с их помощью править Фаэруном и его богами. Потерпев неудачу, они погибли в Смутное время. Но с тех пор при помощи заранее продуманных хитрых планов они возродились.

Когда Раскол лишил прочих богов Забытых Королевств прямого влияния на мир, Мёртвая Троица осталась в форме смертных квазибогов. Хоть их мощь и уменьшилась, они остаются грозным трио и продолжают играть зловещую роль в событиях Фаэруна.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  {
    header: 'Смерть и божественность: проводник богов',
    text: `Смерть — слишком могучая сила, чтобы один бог мог безраздельно властвовать над нею. Бремя это передавалось из рук в руки и постепенно разделилось на несколько частей — болезни, войны, погребальные обряды, — но всегда должен был быть хранитель вечного цикла, когда жизнь подходит к своему логическому завершению.

На протяжении многих эпох им был [Джергал](GOD:${GOD_JERGAL}). Недремлющее око Владыки Конца Всего долго бдело над жизнью и смертью, но пришло время, когда усталость, наконец, взяла своё. Молодые [Баал](GOD:${GOD_BHAAL}), [Бейн](GOD:${GOD_BANE}) и [Миркул](GOD:${GOD_MYRKUL}), верно, полагали себя завоевателями, когда явились пред богом смерти, но тому их амбиции оказались лишь на руку, ибо наконец-то он мог сложить с себя тяжкое бремя.

В результате заключённой с [Джергалом](GOD:${GOD_JERGAL}) сделки прямым его наследником сделался [Миркул](GOD:${GOD_MYRKUL}), но и он не властвует над смертью в одиночку. Смерть от убийства — вотчина [Баала](GOD:${GOD_BHAAL}), в своей алчности не знающего себе равных; благородный же [Келемвор](GOD:${GOD_KELEMVOR}) вершит суд над душами, отправляющимися в загробный мир.

В конце концов, даже боги могут умереть, о чём никогда не следует забывать тем, кто избрал для себя поклонение смерти.`,
    source: {
      id: SOURCE_GAME_BG_3,
    },
  },
  {
    header: 'Легенда об игре в кости, катании черепов и пустом троне',
    text: `Давным-давно, был лишь один бог раздора, смерти и мёртвых, и звали его [Джергал](GOD:${GOD_JERGAL}), Повелитель Конца Всего Сущего. [Джергал](GOD:${GOD_JERGAL}) питался распрями как между смертными, так и между божествами. Когда существа убивали друг друга ради мощи или из ненависти, он приветствовал поверженных в своём царстве вечного мрака. Всё тленно, и со временем, всё приходило к нему — так он построил царство, против которого не мог выступить ни один бог. Но он устал от своих обязанностей, так как он знал их слишком хорошо, а без вызова, нет ничего — а там, где нет ничего, есть лишь уныние.

В таком состоянии разницу между абсолютным всемогуществом и абсолютной беспомощностью определить невозможно.

Во время этого тёмного времени появились три могучих смертных — [Бэйн](GOD:${GOD_BANE}), [Баал](GOD:${GOD_BHAAL}) и [Миркул](GOD:${GOD_MYRKUL}) — которые жаждали сил, которыми владел [Джергал](GOD:${GOD_JERGAL}). Трио заключило нечестивый договор о то, что они либо добьются такой запредельной власти, либо погибнут. Путешествуя по градам и весям их мира, в поисках могучих волшебных предметов и заклятий, они обманывали смерть на каждом шагу. Какой бы монстр или магическое испытание не оказалось перед ними, они выходили из него невредимыми. Так, троица прибыла в Серые Пустоши и увидела там Костяной Замок. Они пробивались к нему через армии [скелетов](CREATURE:${CREATURE_SKELETON}), легионы [зомби](CREATURE:${CREATURE_ZOMBIE}), орды [призраков](CREATURE:${CREATURE_WRAITH}) и отряды [личей](CREATURE:${CREATURE_LICH}).

Наконец, они достигли того, чего так жаждали — Костяного Трона.

_«Именем зла, я властелин этого трона»_, воскликнул [Бэйн-тиран](GOD:${GOD_BANE}).

_«Я уничтожу тебя раньше, чем ты пошевелишь пальцем»_, пригрозил [Джергалу](GOD:${GOD_JERGAL}) [Баал-убийца](GOD:${GOD_BHAAL}).

_«А я заточу твою сущность навечно»_, посулил ему [Миркул-некромант](GOD:${GOD_MYRKUL}).

[Джергал](GOD:${GOD_JERGAL}) устало поднялся со своего трона и молвил, _«Трон ваш. Я устал от этой пустой силы. Забирайте его, если пожелаете — я обещаю служить вам и направлять вас, как ваш сенешаль, пока Вы не освоитесь со своими владениями»_. И пока ошеломлённая троица смогла хоть что-то произнести, Владыка Мёртвых спросил, _«Кто из вас станет править?»_

Троица немедленно затеяла драку между собой, пока [Джергал](GOD:${GOD_JERGAL}) безразлично взирал на них. Когда, наконец, стало ясно, что либо они все перебьют друг друга, либо бой затянется на целую вечность, Повелитель Конца Всего Сущего вмешался.

_«После всего, чем Вы пожертвовали, Вы готовы уйти ни с чем? Почему бы вам просто не поделить сферы влияния между собой, показав свои навыки в игре?»_ предложит [Джергал](GOD:${GOD_JERGAL}).

[Бэйн](GOD:${GOD_BANE}), [Баал](GOD:${GOD_BHAAL}) и [Миркул](GOD:${GOD_MYRKUL}), обдумав его предложение, согласились. Тогда [Джергал] взял черепа самых своих мощных [личей](CREATURE:${CREATURE_LICH}) и отдал их троице, чтобы те могли посоревноваться в катании черепов. Каждый из них должен был покатить череп по Серым Пустошам, и тот, кто метнёт дальше всех, тот и стал бы победителем.

[Малар, Владыка Зверей](GOD:${GOD_MALAR}), прибыл тогда, чтобы навестить [Джергала](GOD:${GOD_JERGAL}). Быстро смекнув, что победитель может получить всю мощь [Джергала](GOD:${GOD_JERGAL}), он погнался за тремя черепами, чтобы приостановить состязание и самому получить возможность поучаствовать в борьбе за часть приза. [Бэйн](GOD:${GOD_BANE}), [Баал](GOD:${GOD_BHAAL}) и [Миркул](GOD:${GOD_MYRKUL}) снова начали сражаться, так как стало ясно, что их состязание провалилось, и тогда [Джергал](GOD:${GOD_JERGAL}) вновь вмешался. _«Почему бы не позволить [Леди Удаче](GOD:${GOD_TYMORA}) решить? Так вам не придётся делиться со Зверем»_.

Троица согласилась на этот вариант, а [Джергал](GOD:${GOD_JERGAL}) отломал свой костяной палец и отдал его кости противникам. Когда Малар вернулся из погони за черепами, он обнаружил, что троица уже окончила игру в кости.

[Бэйн](GOD:${GOD_BANE}) победно вскричал _«Как победитель, я выбираю вечно править как тиран тиранов. Буду я способен вселять ненависть и распри по собственному желанию, все в моём царстве должны будут преклоняться предо мной»_.

[Миркул](GOD:${GOD_MYRKUL}), который занял второе место, заявил, _«Тогда я выбираю мёртвых, и так я воистину победитель. Так, всё чем ты владеешь, [Бэйн](GOD:${GOD_BANE}), со временем станет моим. Все тленно — даже боги»_.

[Баал](GOD:${GOD_BHAAL}), который же занял третье место, провозгласил, _«А я выберу смерть, и так, всё чем правишь ты, Лорд Бэйн, будет отходить к Лорду Миркулу от моей руки. Оба Вы должны будете отдавать мне дань уважения и подчиняться моим желаниям, так как я могу разрушить твоё царство, [Бэйн](GOD:${GOD_BANE}), убив всех твоих подданных, и я же могу заморить твоё царство, [Миркул](GOD:${GOD_MYRKUL}), не поднимая руки»_.

[Малар](GOD:${GOD_MALAR}) взревел в гневе, но ничего не мог он поделать и вновь ему остался лишь удел зверей. А [Джергал](GOD:${GOD_JERGAL}) устало улыбнулся, так как пришло его избавление.`,
    source: {
      id: SOURCE_SCAG,
      page: 32,
    },
  },
]
