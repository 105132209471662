const drowDarkGods = require('./../constants/drowDarkGods')
const {ALIGNMENT_CE} = require('./../../aligmentList')
const {DOMAIN_ARCANA} = require('./../../domainList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_KIARANSALEE} = require('./../../godIdList')
const {PANTHEON_DROW} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_KIARANSALEE,
  nameEn: 'Kiaransalee',
  nameShort: {
    nominative: `Киарансали`,
    genitive: `Киарансали`,
    dative: `Киарансали`,
    accusative: `Киарансали`,
    instrumental: `Киарансали`,
    prepositional: `Киарансали`,
  },
  worshipperRace: `дроу`,
  godOfWhat: [
    `некромантии`,
    `нежити`,
  ],
  description: drowDarkGods,
  alignmentId: ALIGNMENT_CE,
  symbolList: `Женская рука дроу со множеством надетых колец`,
  domainIdList: DOMAIN_ARCANA,
  pantheonId: PANTHEON_DROW,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_SCAG,
    page: 24,
  },
}
