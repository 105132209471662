const godsOfWealth = require('./../constants/godsOfWealth')
const {ALIGNMENT_NE} = require('./../../aligmentList')
const {DOMAIN_TRICKERY} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_ABBATHOR} = require('./../../godIdList')
const {PANTHEON_MORNDINSAMMAN} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_ABBATHOR,
  nameEn: 'Abbathor',
  nameShort: {
    nominative: 'Аббатор',
    genitive: 'Аббатора',
    dative: 'Аббатору',
    accusative: 'Аббатора',
    instrumental: 'Аббатором',
    prepositional: 'Аббаторе',
  },
  godOfWhat: [
    `жадности`,
  ],
  alignmentId: ALIGNMENT_NE,
  symbolList: 'Украшенный самоцветами кинжал, остриём вниз',
  description: godsOfWealth,
  domainIdList: DOMAIN_TRICKERY,
  pantheonId: PANTHEON_MORNDINSAMMAN,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 23,
  },
}
