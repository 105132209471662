const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_GOND,
} = require('./../../../../godIdList')

const Gond_Symbol_SCAG_img = require('./../../../../../images/gods/gond-symbol-SCAG.png')
const Gond_Symbol_DND3_FnP_img = require('./../../../../../images/gods/gond-symbol-DND3_FnP.jpg')
const Gond_DND3_FnP_img = require('./../../../../../images/gods/gond-DND3_FnP.webp')

module.exports = {
  [GOD_GOND]: [
    {
      src: Gond_Symbol_SCAG_img,
      text: 'Символ Гонда',
      source: {
        id: SOURCE_SCAG,
        page: 30,
      },
    },
    {
      src: Gond_Symbol_DND3_FnP_img,
      text: 'Символ Гонда',
      source: {
        id: SOURCE_DND3_FnP,
        page: 27,
      },
    },
    {
      src: Gond_DND3_FnP_img,
      text: 'Гонд Чудотворец',
      source: {
        id: SOURCE_DND3_FnP,
        page: 28,
      },
    },
  ],
}
