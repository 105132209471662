const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_SUNE,
} = require('./../../../../godIdList')
const {
  Sune_s_intervention_MTG_AHBG_data,
} = require('./../../../commonImageCollection')

const Sune_Symbol_SCAG_img = require('./../../../../../images/gods/sune-symbol-SCAG.png')
const Sune_Symbol_DND3_FnP_img = require('./../../../../../images/gods/sune-symbol-DND3_FnP.webp')
const Sune_DND3_FnP_img = require('./../../../../../images/gods/sune-DND3_FnP.jpg')

module.exports = {
  [GOD_SUNE]: [
    {
      src: Sune_Symbol_SCAG_img,
      text: 'Символ Сьюни',
      source: {
        id: SOURCE_SCAG,
        page: 38,
      },
    },
    {
      src: Sune_Symbol_DND3_FnP_img,
      text: 'Символ Сьюни',
      source: {
        id: SOURCE_DND3_FnP,
        page: 67,
      },
    },
    {
      src: Sune_DND3_FnP_img,
      text: 'Сьюни, Огневласая Леди',
      source: {
        id: SOURCE_DND3_FnP,
        page: 68,
      },
    },
    Sune_s_intervention_MTG_AHBG_data,
  ],
}
