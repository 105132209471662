const {ALIGNMENT_N} = require('./../../aligmentList')
const {DOMAIN_LIGHT} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_KOSSUTH} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_KOSSUTH,
  nameEn: 'Kossuth',
  nameShort: {
    nominative: 'Коссут',
    genitive: 'Коссута',
    dative: 'Коссуту',
    accusative: 'Коссута',
    instrumental: 'Коссутом',
    prepositional: 'Коссуте',
  },
  godOfWhat: [
    `огня`,
  ],
  alignmentId: ALIGNMENT_N,
  symbolList: 'Пламя',
  domainIdList: DOMAIN_LIGHT,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 22,
  },
}
