const {
  GOD_GHAUNADAUR,
  GOD_KIARANSALEE,
  GOD_SELVETARM,
  GOD_VHAERAUN,
} = require('./../../godIdList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  header: 'Прочие тёмные силы',
  text: `[Селветарм](GOD:${GOD_SELVETARM}) — бог воинов и покровитель мужского населения дроу, впрочем, не в той же степени как [Ваэрон](GOD:${GOD_VHAERAUN}), плутоватый бог воровства, который бунтует против матриархата.

[Киарансали](GOD:${GOD_KIARANSALEE}), богиня нежити дроу, почитаемая тайными культами некромантов.

[Гонадор](GOD:${GOD_GHAUNADAUR}), известный также как Тот, Кто Скрыт, гибельная сила, сумасшедший бог аберраций, слизи, бунтовщиков и изгнанников, изредка поминаемый дроу.`,
  source: {
    id: SOURCE_SCAG,
    page: 109,
  },
}
