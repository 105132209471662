const drowDarkGods = require('./../constants/drowDarkGods')
const {ALIGNMENT_CE} = require('./../../aligmentList')
const {DOMAIN_TRICKERY} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_VHAERAUN} = require('./../../godIdList')
const {PANTHEON_DROW} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_VHAERAUN,
  nameEn: 'Vhaeraun',
  nameShort: {
    nominative: `Ваэрон`,
    genitive: `Ваэрона`,
    dative: `Ваэрону`,
    accusative: `Ваэрона`,
    instrumental: `Ваэроном`,
    prepositional: `Ваэроне`,
  },
  worshipperRace: `дроу`,
  godOfWhat: [
    `воров`,
    `воровства`,
  ],
  description: drowDarkGods,
  alignmentId: ALIGNMENT_CE,
  symbolList: `Чёрная маска с синими линзами в прорезях для глаз`,
  domainIdList: DOMAIN_TRICKERY,
  pantheonId: PANTHEON_DROW,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_SCAG,
      page: 24,
    },
    {
      id: SOURCE_SCAG,
      page: 109,
    },
  ],
}
