const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_UBTAO,
} = require('./../../../../godIdList')
const {
  Ulutiu_Ubtao_Siamorphe_Tiamat_Valkur_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Ubtao_Symbol_DND3_FnP_img = require('./../../../../../images/gods/ubtao-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_UBTAO]: [
    {
      src: Ubtao_Symbol_DND3_FnP_img,
      text: 'Символ Убтао',
      source: {
        id: SOURCE_DND3_FnP,
        page: 110,
      },
    },
    Ulutiu_Ubtao_Siamorphe_Tiamat_Valkur_DND3_FnP_data,
  ],
}
