const {
  SOURCE_FTD,
} = require('./../../../../sourceList')
const {
  GOD_BAHAMUT,
} = require('./../../../../godIdList')
const {
  Bahamut_Young_Monk_FTD_data,
  Bahamut_as_Fizban_Helps_FTD_data,
  Greatwyrm_Blue_FTD_data,
} = require('./../../../commonImageCollection')

const Bahamut_Humanoid_FTD_img = require('./../../../../../images/gods/bahamut-humanoid.png')
const Bahamut_Teach_Students_FTD_img = require('./../../../../../images/gods/bahamut-teach-students.jpg')

module.exports = {
  [GOD_BAHAMUT]: [
    {
      src: Bahamut_Humanoid_FTD_img,
      text: 'Бахамута, странствующего по Материальному плану, часто сопровождают семь древних золотых драконов',
      source: {
        id: SOURCE_FTD,
      },
    },
    Greatwyrm_Blue_FTD_data,
    Bahamut_Young_Monk_FTD_data,
    Bahamut_as_Fizban_Helps_FTD_data,
    {
      src: Bahamut_Teach_Students_FTD_img,
      text: 'Будучи в любой форме, Бахамут наслаждается моментами озарения у своих учеников',
      source: {
        id: SOURCE_FTD,
      },
    },
  ],
}
