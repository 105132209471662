const {
  SOURCE_MTG_CLB,
} = require('./../../../../sourceList')
const {
  GOD_DENDAR,
} = require('./../../../../godIdList')

const Dendar_eldritch_pact_MTG_CLB_img = require('./../../../../../images/gods/dendar-eldritch-pact.webp')

module.exports = {
  [GOD_DENDAR]: {
    src: Dendar_eldritch_pact_MTG_CLB_img,
    text: 'Ночная змея заключает договор с колдуньей',
    source: {
      id: SOURCE_MTG_CLB
    },
  },
}
