const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_HOAR,
} = require('./../../../../godIdList')
const {
  Istishia_Jergal_Gwaeron_Hoar_Milil_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Hoar_Symbol_SCAG_img = require('./../../../../../images/gods/hoar-symbol-SCAG.png')
const Hoar_Symbol_DND3_FnP_img = require('./../../../../../images/gods/hoar-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_HOAR]: [
    {
      src: Hoar_Symbol_SCAG_img,
      text: 'Символ Хоара',
      source: {
        id: SOURCE_SCAG,
        page: 30,
      },
    },
    {
      src: Hoar_Symbol_DND3_FnP_img,
      text: 'Символ Хоара',
      source: {
        id: SOURCE_DND3_FnP,
        page: 98,
      },
    },
    Istishia_Jergal_Gwaeron_Hoar_Milil_DND3_FnP_data,
  ],
}
