const {GENDER_MALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {ALIGNMENT_LN} = require('./../../aligmentList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  DOMAIN_LIFE,
  DOMAIN_LIGHT,
} = require('./../../domainList')
const {
  GOD_AMAUNATOR,
  GOD_SILVANUS,
} = require('./../../godIdList')

module.exports = {
  id: GOD_AMAUNATOR,
  nameEn: 'Amaunator',
  nameShort: {
    nominative: 'Амонатор',
    genitive: 'Амонатора',
    dative: 'Амонатору',
    accusative: 'Амонатора',
    instrumental: 'Амонатором',
    prepositional: 'Амонаторе',
  },
  godOfWhat: [
    `солнца`,
  ],
  nameAlt: [
    `Хранитель Вечного Солнца`,
    `Свет Закона`,
    `Жёлтый Бог`,
  ],
  alignmentId: ALIGNMENT_LN,
  description: [
    {
      header: `Амонатор`,
      text: `Превосходство закона и слава солнца находятся в сфере Амонатора. Его священнослужители помогают установить в городах бюрократию и закон. Они часто заверяют контракты и соглашения, оставляя на документе оттиск в виде символа солнца Амонатора, подтверждая тем их юридическую силу.

Священники говорят, что он умирает и вновь, и вновь возрождается. Как и солнце, он может пройти сквозь царство тьмы, но его сияющий взгляд неизбежно вновь падёт на мир. Амонатор безжалостное и неумолимое божество, но в отличие от [Сильвануса](GOD:${GOD_SILVANUS}), его заботит не баланс жизни, а течение дел в соответствии с божественным порядком: исполнение обязательств и сохранение верховенства закона.

Фермеры и путешественники молятся ему, когда просят о дожде или солнце, также, как и остальные желающие благоприятных изменений погоды. Но самой распространенной формой почитания Амонатора является принесение клятвы, подписание контракта или оглашение закона в свете солнца. Связь между клятвой и солнцем настолько укоренилась в умах, что, завершая сделку или отдавая указы, люди часто ждут, когда солнце покажется из-за облаков и только тогда завершают сделку или оглашают приказ.`,
      source: {
        id: SOURCE_SCAG,
        page: 25,
      },
    },
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_Над законом корпит Амонатор —_

_От него не уйдёт виноватый._

_Его имя навек_

_Затвердил человек,_

_Подписавшись служить бюрократам._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  symbolList: 'Золотое солнце',
  domainIdList: [DOMAIN_LIGHT, DOMAIN_LIFE],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 25,
    },
  ],
}
