const jergalLegendList = require('./../constants/jergalLegendList')
const {ALIGNMENT_LN} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  DOMAIN_DEATH,
  DOMAIN_KNOWLEDGE,
} = require('./../../domainList')
const {
  GOD_BANE,
  GOD_BHAAL,
  GOD_DENEIR,
  GOD_JERGAL,
  GOD_KELEMVOR,
  GOD_MYRKUL,
} = require('./../../godIdList')

module.exports = {
  id: GOD_JERGAL,
  nameEn: 'Jergal',
  nameShort: {
    nominative: 'Джергал',
    genitive: 'Джергала',
    dative: 'Джергалу',
    accusative: 'Джергала',
    instrumental: 'Джергалом',
    prepositional: 'Джергале',
  },
  jobName: {
    nominative: 'писарь мёртвых',
    genitive: 'писаря мёртвых',
    dative: 'писарю мёртвых',
    accusative: 'писаря мёртвых',
    instrumental: 'писарем мёртвых',
    prepositional: 'писаре мёртвых',
  },
  nameAlt: [
    `Писарь Конца`,
    `Безразличный`,
    `Бледный Сенешаль`,
  ],
  description: [
    {
      header: `Джергал`,
      text: `Легенда гласит, что Джергал очень древнее божество. История его восходит ко временам Нетерила, когда его почитали, как богу смерти, убийств и раздора. Но с течением времени ему наскучило его положение. Тогда три смертных, каждый из которых был могущественным искателем приключений, прибыли к Джергалу в землях мёртвых, вознамерившись убить его и отобрать его могущество. Но вместо этого, Джергал отрёкся от своего трона костей и позволил каждому из троих смертных взять часть его божественности. Так, [Бэйн](GOD:${GOD_BANE}) получил сферу влияния, относящуюся к раздору, [Миркул](GOD:${GOD_MYRKUL}) власть над мёртвыми, а [Баал](GOD:${GOD_BHAAL}) — над убийствами. Джергал утратил своё прошлое положение и стал писарем мёртвых.

Джергал теперь рассматривается как безразличный хранитель мёртвых. Считается, что он записывает переход в смерть живых, чтобы помочь [Келемвору](GOD:${GOD_KELEMVOR}) правильно определить их посмертие. Его редко поминают напрямую, за исключением упоминания на похоронах или среди практикующих обычай писать имя усопшего на листке пергамента и вкладывать его в рот мертвеца. Этот обычай распространён там, где не принято отмечать именем похороненного его могилу или гробницу.

Немногие избирают Джергала своим божеством, и большинство из них так или иначе принимают участие в ритуальных услугах. Жрецы Джергала служат гробовщиками и кладбищенскими смотрителями во многих селениях. У Джергала нет храмов, посвящённых ему, кроме тех, что остались со времён его древнего и более тёмного облика, но его жрецам всегда рады в храмах [Келемвора](GOD:${GOD_KELEMVOR}), [Денеира](GOD:${GOD_DENEIR}) и [Миркула](GOD:${GOD_MYRKUL}). Его последователи периодически отправляют свои записи о смертях в святые места, где их положено хранить.`,
      source: {
        id: SOURCE_SCAG,
        page: 32,
      },
    },
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_Говорят, что когда-то Джергал_

_Свой престол [Келемвору](GOD:${GOD_KELEMVOR}) отдал_

_И теперь он послушно ищет мёртвые души,_

_Если те вдруг уходят в астрал._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    ...jergalLegendList,
  ],
  alignmentId: ALIGNMENT_LN,
  symbolList: 'Череп с зажатым в зубах свитком',
  domainIdList: [DOMAIN_KNOWLEDGE, DOMAIN_DEATH],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 32,
    },
  ],
}
