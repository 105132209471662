const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_HELM,
} = require('./../../../../godIdList')

const Helm_Symbol_SCAG_img = require('./../../../../../images/gods/helm-symbol-SCAG.webp')
const Helm_Symbol_DND3_FnP_img = require('./../../../../../images/gods/helm-symbol-DND3_FnP.webp')
const Helm_DND3_FnP_img = require('./../../../../../images/gods/helm-DND3_FnP.webp')

module.exports = {
  [GOD_HELM]: [
    {
      src: Helm_Symbol_SCAG_img,
      text: 'Символ Хельма',
      source: {
        id: SOURCE_SCAG,
        page: 30,
      },
    },
    {
      src: Helm_Symbol_DND3_FnP_img,
      text: 'Символ Хельма',
      source: {
        id: SOURCE_DND3_FnP,
        page: 29,
      },
    },
    {
      src: Helm_DND3_FnP_img,
      text: 'Хельм бьётся с демоном марилит',
      source: {
        id: SOURCE_DND3_FnP,
        page: 29,
      },
    },
  ],
}
