const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_OGHMA,
} = require('./../../../../godIdList')
const {
  Human_Cleric_Oghma_female_PHB_data,
} = require('./../../../commonImageCollection')

const Oghma_Symbol_SCAG_img = require('./../../../../../images/gods/oghma-symbol-SCAG.png')
const Oghma_Symbol_DND3_FnP_img = require('./../../../../../images/gods/oghma-symbol-DND3_FnP.webp')
const Oghma_DND3_FnP_img = require('./../../../../../images/gods/oghma-DND3_FnP.jpg')

module.exports = {
  [GOD_OGHMA]: [
    {
      src: Oghma_Symbol_SCAG_img,
      text: 'Символ Огмы',
      source: {
        id: SOURCE_SCAG,
        page: 36,
      },
    },
    {
      src: Oghma_Symbol_DND3_FnP_img,
      text: 'Символ Огмы',
      source: {
        id: SOURCE_DND3_FnP,
        page: 54,
      },
    },
    {
      src: Oghma_DND3_FnP_img,
      text: 'Огма, Лорд Знаний',
      source: {
        id: SOURCE_DND3_FnP,
        page: 54,
      },
    },
    Human_Cleric_Oghma_female_PHB_data,
  ],
}
