const {SOURCE_GAME_BG_3} = require('./../../sourceList')
const {
  GOD_ILMATER,
  GOD_TORM,
  GOD_TYR,
} = require('./../../godIdList')

module.exports =  {
  header: `Триада для детей`,
  text: `На первый день [Тир](GOD:${GOD_TYR}) увидел несправедливость мира, хотя его глаза были слепы.

На второй день [Торм](GOD:${GOD_TORM}) был убит и вернулся, чтобы служить истине.

На третий день [Ильматер](GOD:${GOD_ILMATER}) плакал обо всё, что было раньше — и обо всём, что должно случиться.`,
  source: {
    id: SOURCE_GAME_BG_3,
  },
}
