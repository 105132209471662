const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_GRUUMSH,
} = require('./../../../../godIdList')
const {
  Bahgtru_Ilneval_Gruumsh_Luthic_Shargaas_Yurtrus_DND3_FnP_data,
  Corellon_Takes_the_Eye_of_Gruumsh_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Gruumsh_Symbol_DND3_FnP_img = require('./../../../../../images/gods/gruumsh-symbol-DND3_FnP.jpg')

module.exports = {
  [GOD_GRUUMSH]: [
    {
      src: Gruumsh_Symbol_DND3_FnP_img,
      text: 'Символ Груумша',
      source: {
        id: SOURCE_DND3_FnP,
        page: 150,
      },
    },
    Corellon_Takes_the_Eye_of_Gruumsh_DND3_FnP_data,
    Bahgtru_Ilneval_Gruumsh_Luthic_Shargaas_Yurtrus_DND3_FnP_data,
  ],
}
