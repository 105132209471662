const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_BESHABA,
} = require('./../../../../godIdList')

const Beshaba_Symbol_SCAG_img = require('./../../../../../images/gods/beshaba-symbol-SCAG.webp')
const Beshaba_Symbol_DND3_FnP_img = require('./../../../../../images/gods/beshaba-symbol-DND3_FnP.webp')
const Beshaba_DND3_FnP_img = require('./../../../../../images/gods/beshaba-DND3_FnP.jpg')

module.exports = {
  [GOD_BESHABA]: [
    {
      src: Beshaba_Symbol_SCAG_img,
      text: 'Символ Бешабы',
      source: {
        id: SOURCE_SCAG,
        page: 27,
      },
    },
    {
      src: Beshaba_Symbol_DND3_FnP_img,
      text: 'Символ Бешабы',
      source: {
        id: SOURCE_DND3_FnP,
        page: 93,
      },
    },
    {
      src: Beshaba_DND3_FnP_img,
      text: 'Бешаба, Дева Неудачи',
      source: {
        id: SOURCE_DND3_FnP,
        page: 93,
      },
    },
  ],
}
