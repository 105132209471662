const godsOfBattle = require('./../constants/godsOfBattle')
const {ALIGNMENT_CG} = require('./../../aligmentList')
const {DOMAIN_WAR} = require('./../../domainList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_HAELA} = require('./../../godIdList')
const {PANTHEON_MORNDINSAMMAN} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_HAELA,
  nameEn: 'Haela Brightaxe',
  nameShort: {
    nominative: `Хаэла Светлый Топор`,
    genitive: `Хаэлы Светлого Топора`,
    dative: `Хаэле Светлому Топору`,
    accusative: `Хаэлу Светлый Топор`,
    instrumental: `Хаэлой Светлым Топором`,
    prepositional: `Хаэле Светлом Топоре`,
  },
  godOfWhat: [
    `удачи в бою`,
  ],
  description: godsOfBattle,
  alignmentId: ALIGNMENT_CG,
  symbolList: `Меч, обращённый вверх, по лезвию которого спиралью струится пламя`,
  domainIdList: DOMAIN_WAR,
  pantheonId: PANTHEON_MORNDINSAMMAN,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_SCAG,
    page: 23,
  },
}
