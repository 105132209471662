const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_UMBERLEE,
} = require('./../../../../godIdList')

const Umberlee_Symbol_SCAG_img = require('./../../../../../images/gods/umberlee-symbol-SCAG.png')
const Umberlee_Symbol_DND3_FnP_img = require('./../../../../../images/gods/umberlee-symbol-DND3_FnP.webp')
const Umberlee_DND3_FnP_img = require('./../../../../../images/gods/umberlee-DND3_FnP.jpg')

module.exports = {
  [GOD_UMBERLEE]: [
    {
      src: Umberlee_Symbol_SCAG_img,
      text: 'Символ Амберли',
      source: {
        id: SOURCE_SCAG,
        page: 42,
      },
    },
    {
      src: Umberlee_Symbol_DND3_FnP_img,
      text: 'Символ Амберли',
      source: {
        id: SOURCE_DND3_FnP,
        page: 83,
      },
    },
    {
      src: Umberlee_DND3_FnP_img,
      text: 'Амберли, Мать Волн',
      source: {
        id: SOURCE_DND3_FnP,
        page: 84,
      },
    },
  ],
}
