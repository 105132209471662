const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_SEHANINE_MOONBOW,
} = require('./../../../../godIdList')
const {
  Hanali_Labelas_Rillifane_Sehanine_Shevarash_Solonor_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Sehanine_Moonbow_Symbol_DND3_FnP_img = require('./../../../../../images/gods/sehanine_moonbow-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_SEHANINE_MOONBOW]: [
    {
      src: Sehanine_Moonbow_Symbol_DND3_FnP_img,
      text: 'Символ Сеанин Лунной Радуги',
      source: {
        id: SOURCE_DND3_FnP,
        page: 130,
      },
    },
    Hanali_Labelas_Rillifane_Sehanine_Shevarash_Solonor_DND3_FnP_data,
  ],
}
