const {ALIGNMENT_LG} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_ILMATER} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const tyrTormIlmater = require('./../constants/tyrTormIlmater')
const {
  DOMAIN_LIFE,
  DOMAIN_PROTECTION,
  DOMAIN_TWILIGHT,
} = require('./../../domainList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_ILMATER,
  nameEn: 'Ilmater',
  nameShort: {
    nominative: 'Ильматер',
    genitive: 'Ильматера',
    dative: 'Ильматеру',
    accusative: 'Ильматера',
    instrumental: 'Ильматером',
    prepositional: 'Ильматере',
  },
  godOfWhat: [
    `стойкости`,
    `страданий`,
    `мученичества`,
    `воздержания`,
    `обиженных`,
    `обделённых`,
  ],
  nameAlt: [
    `Плачущий Бог`,
    `Распятый Бог`,
    `Искалеченный Бог`,
    `Господь на Дыбе`,
    `Терпящий`,
    `Претерпевающий`,
    `Сострадательный`,
    `Милосердный`,
  ],
  description: [
    {
      header: `Ильматер`,
      text: `Ильматер — бог страданий, мученичества и воздержания, известный своим состраданием и терпением.

Он предлагает поддержку и слова утешения тем, кто чувствует боль, находится в страданиях и нужде. Он страдает по собственной воле, занимая место кого-то другого и взваливая на себя его ношу и боль. Он бог всех притеснённых и тех, с кем обошлись несправедливо. Говорят, если бы он мог, то Плачущий Бог взвалил бы на себя страдания всего мира, чтобы избавить от этой ноши других. Мученики, погибшие, чтобы другие смогли жить, всегда благословенны Ильматером и обретают вечный покой на лоне его, если на то будет их воля.

Жрецы Ильматера заботятся о больных, голодающих и раненных, а храмы отдают большую часть своих доходов на борьбу со страданиями мира. Его последователи предоставляют поддержку, тогда, когда могут, и способны применить силу, чтобы положить конец страданиям других. Жрецы Ильматера отправляются в места с самыми ужасными условиями, оказывая помощь притеснённым, бедным и усопшим. Они ставят других выше себя, делясь всем, что имеют, подчёркивая верховенство духовной природы жизни над материальной.

Жрецов Ильматера, которые приняли миссию помощи другим, можно узнать по власяницам, одеждам из грубых шкур, надетым наголо. Строго воспрещается вредить таким жрецам, когда они исполняют свой долг, такой как уход за раненными на поле боя. Запрет настолько прочен среди людей, что и другие расы также его почитают. Даже орки и гоблиноиды избегают прямо атаковать мирных жрецов Ильматера, пока жрец ухаживает и за их павшими товарищами.

Большинство народа глубоко уважают работу и жертвенность веры Ильматера и стараются помочь ей там, где это возможно. Когда храм Ильматера посылает своих верных на помощь беженцам от войны или жертвам чумы, их самопожертвование подвигает обычных людей, от стыда или вдохновения, поддерживать их.`,
      source: {
        id: SOURCE_SCAG,
        page: 31,
      },
    },
    {
      header: `Ильматер`,
      text: `Ильматер Плачущий защищает обиженных и обделённых. Его служители поклялись облегчать чужие страдания, даже если придётся принять их на себя.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Кредо Распятого Бога`,
      text: `_Распятый Бог, плачь о нас, ибо мы слабы, а ты терпишь. Да упадут твои тяжёлые слёзы, и д а терзает их соль глотки наши._
  
_Прими нашу боль, молим тебя, как мы избавляем от боли ближних наших. Плачь в муках, и мы понесём твои слова тем, кто в них нуждается._

— Наставление по учению Ильматера и тому, как следовать его слову`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Молитвы покаяния`,
      text: `Господь Ильматер, молю тебя, взгляни на меня и узри мой грех. Пусть предстанет он перед твоим бесконечным терпением и умалится, ибо когда я слаб, ты могуч. Даруй мне прощение перед твоим плачущим величием, дабы вольно мне было нести чужое бремя во имя твоё.
      
Дрожат мои колени, крошатся кости, ломается воля — но вера будет крепко стоять на твоих плечах, господи, если ты позволишь ей.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Медитации`,
      text: `Не путайте братья, чудеса, которые творит собравшееся общество и приказы тех, кто стоит на его вершине. Долг верного измученного, _ваш_ долг — знать, когда эти приказы порождают несправедливые страдания. Долг это нелёгкий, и он оставит вам шрамы на руках, когда вы понесёте его — как и должно быть.

Мы всегда должны держать в голове главное: страдание — это ещё не святость. Страдание — это следствие выполнения священного долга. Ильматер не _наслаждается_ болью, друзья, он терпит её, потому что это справедливо. Наша собственная боль — это приемлемая цена, но она не является благом сама по себе.

Некоторые могут спросить: _«Если ваш бог так любит вас, то почему позволяет вам страдать? Почему он хоть кому-то позволяет страдать?»_ Вопрос это риторический, но на него есть ответ. Нельзя исцелиться, не пострадав. Нельзя по-настоящему познать радость, не познав её отсутствия. Но и жить жизнью, наполненной только отсутствием и страданиями, — значит знать только одно. Мы же стремимся к равновесию во имя Распятого Бога, ибо это правильно и _справедливо_.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_Каждый мученик, каждый изгнанник —_

_Суть Ильматера радПостный данник._

_Жизнь за други своя положи, не скуля — _

_Будешь богу почти что племянник._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    tyrTormIlmater,
  ],
  alignmentId: ALIGNMENT_LG,
  symbolList: 'Руки, связанные на запястьях красным шнуром',
  domainIdList: [DOMAIN_LIFE, DOMAIN_PROTECTION, DOMAIN_TWILIGHT],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 31,
    },
  ],
}
