const {ALIGNMENT_NE} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_MEMNOR} = require('./../../godIdList')
const {PANTHEON_GIANT} = require('./../../pantheonList')
const {
  giantGodsCommonDescriptionList,
  godMemnorOnlyDescriptionList,
} = require('./../../textCommonParts')

module.exports = {
  id: GOD_MEMNOR,
  nameEn: 'Memnor',
  nameAlt: [
    'Обманщик',
  ],
  nameShort: {
    nominative: `Мемнор`,
    genitive: `Мемнора`,
    dative: `Мемнору`,
    accusative: `Мемнора`,
    instrumental: `Мемнором`,
    prepositional: `Мемноре`,
  },
  worshipperRace: `облачных великанов`,
  godOfWhat: [
    `обмана`,
  ],
  description: [
    ...godMemnorOnlyDescriptionList,
    ...giantGodsCommonDescriptionList,
  ],
  alignmentId: ALIGNMENT_NE,
  pantheonId: PANTHEON_GIANT,
  genderId: GENDER_MALE,
}
