const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_GORM_GULTHYN,
} = require('./../../../../godIdList')
const {
  Abbathor_Berronar_Clangeddin_Duerra_Dumathoin_Dugmaren_Gorm_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Gorm_Symbol_DND3_FnP_img = require('./../../../../../images/gods/gorm-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_GORM_GULTHYN]: [
    {
      src: Gorm_Symbol_DND3_FnP_img,
      text: 'Символ Горма Галтина',
      source: {
        id: SOURCE_DND3_FnP,
        page: 119,
      },
    },
    Abbathor_Berronar_Clangeddin_Duerra_Dumathoin_Dugmaren_Gorm_DND3_FnP_data,
  ],
}
