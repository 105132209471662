const {ALIGNMENT_LN} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  DOMAIN_ARCANA,
  DOMAIN_KNOWLEDGE,
} = require('./../../domainList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GOD_AZUTH,
  GOD_BANE,
  GOD_BESHABA,
  GOD_DENEIR,
  GOD_MYSTRA,
  GOD_OGHMA,
} = require('./../../godIdList')

module.exports = {
  id: GOD_AZUTH,
  nameEn: 'Azuth',
  nameShort: {
    nominative: 'Азут',
    genitive: 'Азута',
    dative: 'Азуту',
    accusative: 'Азута',
    instrumental: 'Азутом',
    prepositional: 'Азуте',
  },
  godOfWhat: [
    `волшебников`,
  ],
  nameAlt: [
    `Высший`,
    `Владыка Заклинаний`,
    `Первый Магистр`,
  ],
  description: [
    {
      header: `Азут`,
      text: `Поклонение Азуту неотделимо от волшебников. Для них Высший — абсолютное воплощение всего, что им дорого.

[Мистра](GOD:${GOD_MYSTRA}) — богиня магии, [Огма](GOD:${GOD_OGHMA}) — бог знаний, [Денеир](GOD:${GOD_DENEIR}) — бог письма и языка. Азут воплощает аспекты этих общих понятий в их приложении к практике волшебства. Например, в то время как [Мистра](GOD:${GOD_MYSTRA}) — божество олицетворяющее душу, искусство и чудо магии, Азут — воплощение долгих магических исследований, строгости движений и речи плетения заклинаний, саднящих и перепачканных чернилами пальцев.

Волшебники взывают к Азуту, когда пишут свитки, чертят магические круги, пытаются запомнить или произнести заклинание. Чаще всего это проявляется в виде молчаливого сотворения символа Азута — направленного в небо указательного пальца левой руки. Для многих волшебников, этот жест стал настолько за всю их жизнь, что он перерастает в несознательную привычку.

Храмов, посвященных Азуту не много, а жрецы встречаются крайне редко. Даже в насыщенном магией Халруаа, всего несколько святынь этого бога. Иногда статую или часовню Азута можно найти в углу храма [Мистры](GOD:${GOD_MYSTRA}) или другого бога. Чаще маги обустраивают дома небольшое святилище. Обычно в подобных святынях Азут представлен в виде бородатой и одетой в плащ с капюшоном фигуры, с поднятой левой рукой и указывающим вверх пальцем. Иногда изображают только руку. В любом случае на палец часто ставят свечу, или накладывают заклинание света.`,
      source: {
        id: SOURCE_SCAG,
        page: 26,
      },
    },
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_Колдовства покровитель Азут_

_Награждает ученье и труд._

_Вашу тягу к познаньям_

_Превратит в заклинанье,_

_Пока [Мистру](GOD:${GOD_MYSTRA}) сомненья грызут._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_LN,
  symbolList: 'Левая рука окруженная огнём, указующая вверх',
  domainIdList: [DOMAIN_ARCANA, DOMAIN_KNOWLEDGE],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 26,
    },
  ],
}
