const {GENDER_MALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {ALIGNMENT_LE} = require('./../../aligmentList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  DOMAIN_KNOWLEDGE,
  DOMAIN_ORDER,
  DOMAIN_TRICKERY,
} = require('./../../domainList')
const {
  GOD_ASMODEUS,
  GOD_KELEMVOR,
  GOD_LATHANDER,
  GOD_MORADIN,
} = require('./../../godIdList')

module.exports = {
  id: GOD_ASMODEUS,
  nameEn: 'Asmodeus',
  nameShort: {
    nominative: 'Асмодей',
    genitive: 'Асмодея',
    dative: 'Асмодею',
    accusative: 'Асмодея',
    instrumental: 'Асмодеем',
    prepositional: 'Асмодее',
  },
  godOfWhat: [
    `отпущения греха`,
  ],
  nameAlt: [
    `Владыка Девяти`,
    `Дьявол`,
    `Древний Рогатый`,
  ],
  description: [
    {
      header: 'Асмодей',
      text: `Асмодею начали поклоняться открыто около века назад, когда после Магической Чумы на поверхность вышли небольшие культы с харизматичными лидерами во главе. Катаклизм породил много вопросов у народа: почему боги разгневались или покинули нас? Асмодей дал просителям ответы на эти вопросы и стал богом готовым простить все прегрешения. С тех пор, в течение нескольких десятилетий, культ Асмодея боролся за признание своей религии.
  
  В поверьях народов Севера, которые включают сказания дварфов, эльфов и иных рас, Асмодей — Владыка Девяти, лидер всех дьяволов Девяти Кругов Ада. Все знают, что дьяволы — сладкоголосые искусители с железной волей, и дары их стоят очень дорого, иногда даже души просителя. Говорят, что пока душа ждёт на плане Фугу божество, которое заберёт их в загробные чертоги, может явиться дьявол и в обмен на душу предложить силу и вечные удовольствия. Всем душам, что возжелают этого, нужно всего лишь сделать шаг из толпы и пыли, и ступить на первую ступень адской лестницы, которая символизирует иерархию Девяти Кругов Ада.
  
  Служители Асмодея признают, что дьяволы предлагают своим последователям путь, подходящий не для каждого, так же, как и вечное плескание в свете [Латандера](GOD:${GOD_LATHANDER}) и бесконечная работа в кузне [Морадина](GOD:${GOD_MORADIN}) не всем придутся по вкусу. Служащие Асмодею при жизни, могут надеяться, что после смерти их призовут из стенающей толпы на плане Фугу. Они сами будут ковать свою судьбу, тратя вечность на достижение своих целей.
  
  Для не столь избранных, священники Асмодея могут рассчитывать на некоторые поблажки в загробной жизни. Все души на плане Фугу ждут милости богов, которые определят, где те проведут остаток вечности. Тех, кто при жизни вёл себя наиболее подобающе догмам бога, забираются в первую очередь. Те же кто совершили преступление в глазах своего бога или вообще не поклонялись им, могут томиться столетия до того, как [Келемвор](GOD:${GOD_KELEMVOR}) рассудит их судьбу. Люди, которых подобная судьба страшит, могут молиться Асмодею, и как говорят жрецы, в ответ дьявол обеспечит томящейся душе некоторый комфорт.
  
  Сегодня, святилища Асмодея всё ещё встречаются редко, а о храмах и вовсе никто не слышал, но многие обыватели взяли за привычку просить у Асмодея отпущения своих грехов. После нарушения заветов своего бога, они молятся, чтобы Асмодей облегчил их долгое томление. Как известно, Асмодей даёт людям то, что они хотят, поэтому ему молятся о получении благ, которых они желают более всего. Те, кто серьёзно нарушил догмы бога, молятся о том, чтобы Асмодей сокрыл их грех от богов, и жрецы говорят, что он сделает так, но за свою цену, которую придётся уплатить после смерти.`,
      source: {
        id: SOURCE_SCAG,
        page: 25,
      },
    },
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_Мастер сладких речей Асмодей —_

_Он любого обмана древней._

_По увёрткам и козням_

_В мире нет виртуозней._

_Если встретишь — спасайся скорей._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_LE,
  symbolList: 'Три обращенных вовнутрь треугольника, создающие четвёртый треугольник',
  domainIdList: [DOMAIN_KNOWLEDGE, DOMAIN_ORDER, DOMAIN_TRICKERY],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 25,
    },
  ],
}
