const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_GHAUNADAUR,
} = require('./../../../../godIdList')
const {
  Kiaransalee_Vhaeraun_Lolth_Selvetarm_Ghaunadaur_Eilistraee_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Ghaunadaur_Symbol_DND3_FnP_img = require('./../../../../../images/gods/ghaunadaur-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_GHAUNADAUR]: [
    {
      src: Ghaunadaur_Symbol_DND3_FnP_img,
      text: 'Символ Гонадора',
      source: {
        id: SOURCE_DND3_FnP,
        page: 112,
      },
    },
    Kiaransalee_Vhaeraun_Lolth_Selvetarm_Ghaunadaur_Eilistraee_DND3_FnP_data,
  ],
}
