const blackAndWhite = require('./../constants/blackAndWhite')
const {ALIGNMENT_NE} = require('./../../aligmentList')
const {DOMAIN_DEATH} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_YURTRUS} = require('./../../godIdList')
const {PANTHEON_ORC} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_YURTRUS,
  nameEn: 'Yurtrus',
  nameShort: {
    nominative: `Юртрус`,
    genitive: `Юртруса`,
    dative: `Юртрусу`,
    accusative: `Юртруса`,
    instrumental: `Юртрусом`,
    prepositional: `Юртрусе`,
  },
  godOfWhat: [
    `смерти`,
    `болезни`,
  ],
  nameAlt: 'Лорд Червей',
  description: blackAndWhite,
  symbolList: `белая раскрытая ладонь`,
  alignmentId: ALIGNMENT_NE,
  domainIdList: DOMAIN_DEATH,
  pantheonId: PANTHEON_ORC,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_SCAG,
      page: 25,
    },
    {
      id: SOURCE_SCAG,
      page: 119,
    },
  ],
}
