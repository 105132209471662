const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_RED_KNIGHT,
} = require('./../../../../godIdList')
const {
  Shiallia_Nobanion_Savras_Red_Knight_Sharess_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Red_Knight_Symbol_SCAG_img = require('./../../../../../images/gods/red_knight-symbol-SCAG.png')
const Red_Knight_Symbol_DND3_FnP_img = require('./../../../../../images/gods/the_red_knight-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_RED_KNIGHT]: [
    {
      src: Red_Knight_Symbol_SCAG_img,
      text: 'Символ Красного Рыцаря',
      source: {
        id: SOURCE_SCAG,
        page: 37,
      },
    },
    {
      src: Red_Knight_Symbol_DND3_FnP_img,
      text: 'Символ Красного Рыцаря',
      source: {
        id: SOURCE_DND3_FnP,
        page: 37,
      },
    },
    Shiallia_Nobanion_Savras_Red_Knight_Sharess_DND3_FnP_data,
  ],
}
