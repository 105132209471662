const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_KELEMVOR,
} = require('./../../../../godIdList')

const Kelemvor_Symbol_SCAG_img = require('./../../../../../images/gods/kelemvor-symbol-SCAG.png')
const Kelemvor_Symbol_DND3_FnP_img = require('./../../../../../images/gods/kelemvor-symbol-DND3_FnP.jpg')
const Kelemvor_DND3_FnP_img = require('./../../../../../images/gods/kelemvor-DND3_FnP.jpg')

module.exports = {
  [GOD_KELEMVOR]: [
    {
      src: Kelemvor_Symbol_SCAG_img,
      text: 'Символ Келемвора',
      source: {
        id: SOURCE_SCAG,
        page: 32,
      },
    },
    {
      src: Kelemvor_Symbol_DND3_FnP_img,
      text: 'Символ Келемвора',
      source: {
        id: SOURCE_DND3_FnP,
        page: 34,
      },
    },
    {
      src: Kelemvor_DND3_FnP_img,
      text: 'Келемвор, Владыка Мёртвых',
      source: {
        id: SOURCE_DND3_FnP,
        page: 35,
      },
    },
  ],
}
