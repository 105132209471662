const {SOURCE_SCAG} = require('./../../sourceList')
const {PC_RACE_DWARF} = require('./../../pcRaceIdList')
const {
  GOD_DUMATHOIN,
  GOD_SHARINDLAR,
} = require('./../../godIdList')

module.exports = {
  header: 'Боги ремесел',
  text: `[Думатойн](GOD:${GOD_DUMATHOIN}), Хранитель Секретов под Горой — покровитель щитовых [дварфов](PC_RACE:${PC_RACE_DWARF}), а также бог захороненного богатства, горной промышленности, исследований, драгоценных камней и страж мёртвых.

[Шариндлар](GOD:${GOD_SHARINDLAR}), леди Жизни и Милосердия — богиня исцеления, романтической любви и плодородия, часто ассоциируется с луной.`,
  source: {
    id: SOURCE_SCAG,
    page: 106,
  },
}
