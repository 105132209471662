const {SOURCE_SCAG} = require('./../../sourceList')
const {
  GOD_AZUTH,
  GOD_BESHABA,
  GOD_LATHANDER,
  GOD_SELUNE,
  GOD_TYMORA,
} = require('./../../godIdList')

module.exports = {
  header: 'Легенда о Тич и её дочерях-близнецах',
  text: `До Рассветного Катаклизма, была лишь одна богиня удачи — Тич. Всегда ходящая по лезвию между удачей и провалом, Леди Удача отдавала и отбирала свою благосклонность по собственной прихоти. Когда её любовник, [Латандер](GOD:${GOD_LATHANDER}), затеял сражение среди богов, Тич подарила поцелуй неудачи Утреннему Лорду и отправилась исследовать мир.

Во время своих путешествий, Леди Удача наткнулась на распускающуюся розу несравненной красы. Заворожённая таким удачным происшествием, Тич протянула руку, чтобы сорвать этот восхитительный образчик, который она приняла за увертюру [Латандера](GOD:${GOD_LATHANDER}), который пытался вернуть её расположение. К своему удивлению, Леди Удача не смогла сорвать бутон с куста, как бы сильно она не старалась. Разочарованная, она в сердцах прокляла розу, и в этот момент стебель цветка в её руке надломился. Тич пристроила сорванную розу в волосы и продолжила свой путь, не зная, что роза была создана рукой Моандера, бога распада и порчи. Отделённый от куста, стебель розы пустил свои корни в ухе Тич и начал разлагать её изнутри. Вернувшись домой, Тич встретила там свою дорогую подругу, богиню [Селунэ](GOD:${GOD_SELUNE}), которая хотела поговорить с ней.

Там же ждал её и [Латандер](GOD:${GOD_LATHANDER}), который хотел вновь получить её расположение, и [Азут](GOD:${GOD_AZUTH}), который пришёл, чтобы быть посредником в диспуте этих двоих. [Селунэ](GOD:${GOD_SELUNE}) пролила великие слёзы, когда узрела порчу, глодавшую её подругу изнутри, и прежде чем Тич смогла распознать её намерения, [Селунэ](GOD:${GOD_SELUNE}) вспыхнула лучом очищающего света. Подгнившая сердцевина Тич раскололась надвое, и меньшая, но более яркая версия богини удачи вышла оттуда, позволив богине луны спасти то доброе и чистое, что было в её подруге. Но вслед за первой богиней из расколотой сердцевины вышла и вторая, ошеломительной внешности, но и полная тёмной злобы и дурных капризов. Обе богини, увидев друг друга, не медля ни мгновения затеяли драку, полную взаимной ненависти, и разнять её получилось лишь совместными усилиями всех трёх гостей.

Говорят, что [Тимора](GOD:${GOD_TYMORA}), Светловолосая Дочь Тич, воплощает всю доброту и благодать своей матери, тогда как [Бешаба](GOD:${GOD_BESHABA}), Нежеланная Дочь Тич, получила лишь её внешность. С их рождения, обе наследницы-близняшки Тич — [Тимора, Леди Удача](GOD:${GOD_TYMORA}) и [Бешаба, Дева Несчастий](GOD:${GOD_BESHABA}) — сражались друг с другом, оспаривая всё, от вещей столь великих, как судьбы народов, до дел столь малых, как бросок монеты.`,
  source: {
    id: SOURCE_SCAG,
    page: 41,
  },
}
