const {ALIGNMENT_CE} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_GRUUMSH} = require('./../../godIdList')
const {PANTHEON_ORC} = require('./../../pantheonList')
const {
  DOMAIN_TEMPEST,
  DOMAIN_WAR,
} = require('./../../domainList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  gruumshDescriptionList,
  firstFamilyDescription,
  orcEyeOfGruumshDescription,
} = require('./../../textCommonParts')

module.exports = {
  id: GOD_GRUUMSH,
  nameEn: 'Gruumsh',
  nameShort: {
    nominative: `Груумш`,
    genitive: `Груумша`,
    dative: `Груумшу`,
    accusative: `Груумша`,
    instrumental: `Груумшем`,
    prepositional: `Груумше`,
  },
  godOfWhat: [
    `бурь`,
    `войны`,
    `завоеваний`,
    `силы`,
    `выживания`,
  ],
  nameAlt: [
    'Тот, Кто Зрит',
    'Одноглазый',
  ],
  alignmentId: ALIGNMENT_CE,
  symbolList: `немигающий глаз`,
  domainIdList: [DOMAIN_TEMPEST, DOMAIN_WAR],
  description: [
    ...gruumshDescriptionList,
    firstFamilyDescription,
    orcEyeOfGruumshDescription,
  ],
  pantheonId: PANTHEON_ORC,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 297,
    },
    {
      id: SOURCE_SCAG,
      page: 25,
    },
  ],
}
