const {ALIGNMENT_LN} = require('./../../aligmentList')
const {DOMAIN_WAR} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {
  GOD_HOAR,
  GOD_TYR,
} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_HOAR,
  nameEn: 'Hoar',
  nameShort: {
    nominative: 'Хоар',
    genitive: 'Хоара',
    dative: 'Хоару',
    accusative: 'Хоара',
    instrumental: 'Хоаром',
    prepositional: 'Хоаре',
  },
  godOfWhat: [
    `мести`,
    `возмездия`,
  ],
  nameAlt: [
    `Ассуран`,
    `Роковой Вестник`,
    `Поэт Правосудия`,
  ],
  description: {
    header: 'Хоар',
    text: `Хоар, известный в землях у Внутреннего Моря, как Ассуран — бог отмщения и возмездия. Ему не поклоняются обычными методами, но его имя поминают, когда ищут мести. Когда виновный становится жертвой судьбы — как, например, беглый убийца умирает в результате несчастного случая — в этом видят длань Хоара. Считается, что троекратный раскат грома подряд — это знак, посланным Хоаром, что некое возмездие было совершено. Многие человеческие культуры поддерживают обычай бить в колокол или гонг трижды, когда вершится наказание за преступление или казнь.

Народ произносит имя Хоара, когда они ищут мести, но не способны отомстить за себя сами. Это может быть реакция, как на незначительную, так и на существенную несправедливость, а мольба может быть высказана как просто в слух, так и записана где-то. Считается, что чем более постоянную форму будет носить мольба, тем больше вероятность, что она будет услышана. Потому, многие чеканят свои мольбы на свинцовых дощечках и после закапывают их, или скрывают свои мольбы на страницах своих дневников. Кроме охотников за головами и тех, кто отправился в крестовый поход мщения, немногие истинно почитают Хоара, и ещё меньше среди них тех, кто может именовать себя его жрецами. Храмы и святилища Хоара почти не встречаются, за исключением древних руин в Чессенте и Унтере.

Хоар стал частью фаэрунского пантеона, когда ему стали поклоняться вне земель, изначально верных ему. Большинство считают, что [Тир](GOD:${GOD_TYR}) стал судьёй и арбитром, устанавливающим законы, а Хоар — богом, карающим за их нарушения. Судьи отдают предпочтение [Тиру](GOD:${GOD_TYR}), в то время как тюремщик скорее всего будет возносить молитвы Хоару.`,
    source: {
      id: SOURCE_SCAG,
      page: 30,
    },
  },
  alignmentId: ALIGNMENT_LN,
  symbolList: 'Монета с двуликой головой',
  domainIdList: DOMAIN_WAR,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
  ],
}
