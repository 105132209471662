const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_VHAERAUN,
} = require('./../../../../godIdList')
const {
  Kiaransalee_Vhaeraun_Lolth_Selvetarm_Ghaunadaur_Eilistraee_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Vhaeraun_DND3_FnP_img = require('./../../../../../images/gods/vhaeraun-DND3_FnP.webp')
const Vhaeraun_Symbol_DND3_FnP_img = require('./../../../../../images/gods/vhaeraun-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_VHAERAUN]: [
    {
      src: Vhaeraun_Symbol_DND3_FnP_img,
      text: 'Символ Ваэрона',
      source: {
        id: SOURCE_DND3_FnP,
        page: 115,
      },
    },
    {
      src: Vhaeraun_DND3_FnP_img,
      text: 'Ваэрон',
      source: {
        id: SOURCE_DND3_FnP,
        page: 114,
      },
    },
    Kiaransalee_Vhaeraun_Lolth_Selvetarm_Ghaunadaur_Eilistraee_DND3_FnP_data,
  ],
}
