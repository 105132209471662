const {
  SOURCE_GAME_BG_3,
  SOURCE_MTG_CLB,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_BHAAL,
} = require('./../../../../godIdList')

const Bhaal_Symbol_SCAG_img = require('./../../../../../images/gods/bhaal-symbol-SCAG.png')
const Bhaal_GAME_BG_3_img = require('./../../../../../images/gods/bhaal_game_bg3.webp')
const Bhaal_MTG_CLB_img = require('./../../../../../images/gods/bhaal-mtg_clb.jpg')
const Bhaal_2_MTG_CLB_img = require('./../../../../../images/gods/bhaal_2-mtg_clb.jpg')

module.exports = {
  [GOD_BHAAL]: [
    {
      src: Bhaal_Symbol_SCAG_img,
      text: 'Символ Баала',
      source: {
        id: SOURCE_SCAG,
        page: 28,
      },
    },
    {
      src: Bhaal_GAME_BG_3_img,
      text: 'Баал',
      source: SOURCE_GAME_BG_3,
    },
    {
      src: Bhaal_2_MTG_CLB_img,
      text: 'Баал, владыка убийств',
      source: SOURCE_MTG_CLB,
    },
    {
      src: Bhaal_MTG_CLB_img,
      text: 'Баал, владыка убийств',
      source: SOURCE_MTG_CLB,
    },
  ],
}
