import React from 'react'

import SymbolComponent from './SymbolComponent'

export default ({ list }) => {
  if (list && list.length) {
    const isSingle = list.length === 1
    const preText = isSingle
      ? 'Символ'
      : 'Символы'

    return (
      <SymbolComponent
        list={list}
        isSingle={isSingle}
        preText={preText}
      />
    )
  }

  return null
}
