module.exports = {
  ...require('./list/a'),
  ...require('./list/b'),
  ...require('./list/c'),
  ...require('./list/d'),
  ...require('./list/e'),
  ...require('./list/f'),
  ...require('./list/g'),
  ...require('./list/h'),
  ...require('./list/i'),
  ...require('./list/j'),
  ...require('./list/k'),
  ...require('./list/l'),
  ...require('./list/m'),
  ...require('./list/o'),
  ...require('./list/r'),
  ...require('./list/s'),
  ...require('./list/t'),
  ...require('./list/u'),
  ...require('./list/v'),
  ...require('./list/w'),
  ...require('./list/y'),
}
