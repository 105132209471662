const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_CALLARDURAN_SMOOTHHANDS,
} = require('./../../../../godIdList')
const {
  Baervan_Baravar_Callarduran_Flandal_Gaerdal_Garl_Segojan_Urdlen_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Callarduran_Smoothhands_Symbol_DND3_FnP_img = require('./../../../../../images/gods/callarduran_smoothhands-DND3_FnP.webp')

module.exports = {
  [GOD_CALLARDURAN_SMOOTHHANDS]: [
    {
      src: Callarduran_Smoothhands_Symbol_DND3_FnP_img,
      text: 'Символ Каллардюрана',
      source: {
        id: SOURCE_DND3_FnP,
        page: 134,
      },
    },
    Baervan_Baravar_Callarduran_Flandal_Gaerdal_Garl_Segojan_Urdlen_DND3_FnP_data,
  ],
}
