const {GENDER_MALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {ALIGNMENT_LN} = require('./../../aligmentList')
const {
  DOMAIN_DEATH,
  DOMAIN_GRAVE,
} = require('./../../domainList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GOD_DENEIR,
  GOD_KELEMVOR,
  GOD_MYRKUL,
  GOD_OGHMA,
} = require('./../../godIdList')
const homePray = require('./../constants/homePray')

module.exports = {
  id: GOD_KELEMVOR,
  nameEn: 'Kelemvor',
  nameShort: {
    nominative: 'Келемвор',
    genitive: 'Келемвора',
    dative: 'Келемвору',
    accusative: 'Келемвора',
    instrumental: 'Келемвором',
    prepositional: 'Келемворе',
  },
  godOfWhat: [
    `смерти`,
  ],
  nameAlt: [
    `Владыка Мёртвых`,
    `Судья Проклятых`,
  ],
  description: [
    {
      header: `Келемвор`,
      text:  `Келемвор рассматривается как справедливый, честный и утешающий бог смерти. Смерть приходит за всеми, и когда такое случается, Келемвор за руку ведёт каждую душу к её посмертию. Жречество Келемвора учит, что те, кто поклонялся богам согласно заветам их религии, поступали верно и им будет предложено соответствующее посмертие.

Последователи Келемвора предоставляют людям спокойный переход в лоно Владыки Мёртвых. Они помогают умирающим привести их дела в порядок и помогают с похоронами тем, кто не может себе позволить, к примеру, пышности похорон собственной веры.

Принципы последователей Келемвора призывают их препятствовать и предотвращать безвременным смертям, когда это возможно. Разные группы и поклонники дают разные определения «безвременности». Одна группа может сконцентрироваться на том, чтобы остановить распространение болезней. Другая, на предотвращении убийств. Иная — на истреблении нежити. Характерно, что все верующие Келемвора презирают нежить и так или иначе работают над её искоренением, так как любая нежить расценивается как искажение естественного порядка вещей. Эта деталь не только очевидным образом противопоставляет последователей Келемвора некромантам, жрецам [Миркула](GOD:${GOD_MYRKUL}) и всем прочим, кто приветствует создание нежити, но и порождает, казалось бы, неожиданные конфликты.

Например, жрецы Келемвора методично истребляют все записи о создании нежити, которые они находят — такие действия оскорбляют тех, кто ценит знания ради самих знаний, таких как последователи [Огмы](GOD:${GOD_OGHMA}) и [Денеира](GOD:${GOD_DENEIR}). Также существует нежить, которая не является злом, такая как баэлнорны, которых эльфы считают святыми. Последователи Келемвора пытаются уничтожить и их, не обращая внимания на этот факт.`,
      source: {
        id: SOURCE_SCAG,
        page: 32,
      },
    },
    {
      header: `Судья проклятых`,
      text: `Было много Владык Смерти, которые выносили приговор душам потерянным и обретённым. Среди них был Келемвор — суровый человек с ещё более суровыми убеждениями.

Будучи смертным мужем, он служил наёмником, и его стойкие убеждения паладина скрывались под маской хмурого ворчания. Став же Владыкой Смерти, он был вынужден смягчить эти обе свои стороны, поскольку импульсы человека — совсем не то, что у бога.

Смертным позволено быть капризными, но бессмертие быстро стачивает этот камень, и Келемвор предпочёл холодное благоразумие холодной злобе. 

— Отрывок из книги, в которой Ифиген Могрос обсуждает странную богословскую историю о  смертных, ставших богами.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Келемвор`,
      text: `Келемвор — справедливый, но безучастный бог. Он указывает умершим путь к их обители в загробной жизни. Его жрецы по всему Фаэруну проводят погребальные обряды и уничтожают нежить, бежавшую от суда Келемвора.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_Келемвор, что дворецким у смерти,_

_Примет вас из земной круговерти._

_Он протянет ладонь — и тебя в новый дом_

_Отведёт. И путь новый начертит._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    homePray,
  ],
  alignmentId: ALIGNMENT_LN,
  symbolList: 'Рука скелета, держащая уравновешенные весы',
  domainIdList: [DOMAIN_DEATH, DOMAIN_GRAVE],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 32,
    },
  ],
}
