const {ALIGNMENT_NG} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {PC_CLASS_RANGER} = require('./../../pcClassIdList')
const {PC_RACE_ORC} = require('./../../pcRaceIdList')
const {CREATURE_TROLL} = require('./../../creatureIdList')
const {
  DOMAIN_KNOWLEDGE,
  DOMAIN_NATURE,
} = require('./../../domainList')
const {
  GOD_GWAERON_WINDSTROM,
  GOD_MIELIKKI,
} = require('./../../godIdList')

module.exports = {
  id: GOD_GWAERON_WINDSTROM,
  nameEn: 'Gwaeron Windstrom',
  nameShort: {
    nominative: 'Гвейрон Буреветер',
    genitive: 'Гвейрона Буреветра',
    dative: 'Гвейрону Буреветру',
    accusative: 'Гвейрона Буреветра',
    instrumental: 'Гвейроном Буреветром',
    prepositional: 'Гвейроне Буреветре',
  },
  godOfWhat: [
    `выслеживания`,
  ],
  nameAlt: [
    `Глас Майликки`,
    `Мастер Выслеживания`,
    `Не Теряющий След`,
  ],
  description: {
    header: 'Гвейрон Буреветер',
    text: `Некоторые [следопыты](PC_CLASS:${PC_CLASS_RANGER}) Севера возносят молитвы Гвейрону Буреветру. Говорят, что это смертный, вознесённый до бога [Майликки](GOD:${GOD_MIELIKKI}), который теперь служит [следопытам](PC_CLASS:${PC_CLASS_RANGER}) как посредник между ними и Майликки. Он — мастер-следопыт, идеально выслеживающий дичь, непревзойденный укротитель зверей и непримиримый враг жестоких существ подобных [оркам](PC_RACE:${PC_RACE_ORC}) и [троллям](CREATURE:${CREATURE_TROLL}). Говорят, он выглядит как крепкий и сильный пожилой человек с длинной белой бородой, и многие верят, что он отдыхает и спит в роще недалеко от Трибора.

Следопыты поклоняются Гвейрону, так как он олицетворяет все их занятия и потому что он может говорить с [Майликки](GOD:${GOD_MIELIKKI}) от их имени. На Севере большинство [следопытов](PC_CLASS:${PC_CLASS_RANGER}) считают [Майликки](GOD:${GOD_MIELIKKI}) слишком таинственной, священной и дикой, чтобы обращаться напрямую к ней со своими молитвами, а Гвейрон Буреветер почти один из них, и поэтому понимает их нужды.

У Гвейрона нет храмов, но его святыни можно найти во множестве мест, они служат путникам указующими метками в диких землях. Каждый из них — вырезанный символ Гвейрона, отпечаток лапы со звездой на нём, который наносится на приметное дерево или камень.`,
    source: {
      id: SOURCE_SCAG,
      page: 30,
    },
  },
  alignmentId: ALIGNMENT_NG,
  symbolList: 'Отпечаток лапы с пятиконечной звездой в центре',
  domainIdList: [DOMAIN_KNOWLEDGE, DOMAIN_NATURE],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 30,
    },
  ],
}
