const shadowGods = require('./../constants/shadowGods')
const {ALIGNMENT_N} = require('./../../aligmentList')
const {DOMAIN_TRICKERY} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_BRANDOBARIS} = require('./../../godIdList')
const {PANTHEON_HIN} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_BRANDOBARIS,
  nameEn: 'Brandobaris',
  nameShort: {
    nominative: `Брандобарис`,
    genitive: `Брандобариса`,
    dative: `Брандобарису`,
    accusative: `Брандобариса`,
    instrumental: `Брандобарисом`,
    prepositional: `Брандобарисе`,
  },
  godOfWhat: [
    `вороства`,
    `приключений`,
    `обманщиков`,
    `воров`,
    `всего скрытного`,
  ],
  description: shadowGods,
  alignmentId: ALIGNMENT_N,
  symbolList: `след полурослика`,
  domainIdList: DOMAIN_TRICKERY,
  pantheonId: PANTHEON_HIN,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_SCAG,
      page: 24,
    },
    {
      id: SOURCE_SCAG,
      page: 111,
    },
  ],
}
