const {SOURCE_GAME_BG_3} = require('./../../sourceList')

module.exports = {
  text: `Луну и звёздный свет вручила нам Селунэ.

Шар скрыла тьмой ту боль, что сходит в мир подлунный.`,
  author: 'Стихи на табличке',
  source: {
    id: SOURCE_GAME_BG_3,
  },
}
