const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_KIARANSALEE,
} = require('./../../../../godIdList')
const {
  Kiaransalee_Vhaeraun_Lolth_Selvetarm_Ghaunadaur_Eilistraee_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Kiaransalee_Symbol_DND3_FnP_img = require('./../../../../../images/gods/kiaransalee-symbol-DND3_FnP.jpg')

module.exports = {
  [GOD_KIARANSALEE]: [
    {
      src: Kiaransalee_Symbol_DND3_FnP_img,
      text: 'Символ Киарансали',
      source: {
        id: SOURCE_DND3_FnP,
        page: 113,
      },
    },
    Kiaransalee_Vhaeraun_Lolth_Selvetarm_Ghaunadaur_Eilistraee_DND3_FnP_data,
  ],
}
