const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_SILVANUS,
} = require('./../../../../godIdList')

const Silvanus_Symbol_SCAG_img = require('./../../../../../images/gods/silvanus-symbol-SCAG.png')
const Silvanus_Symbol_DND3_FnP_img = require('./../../../../../images/gods/silvanus-symbol-DND3_FnP.jpg')
const Silvanus_DND3_FnP_img = require('./../../../../../images/gods/silvanus-DND3_FnP.webp')

module.exports = {
  [GOD_SILVANUS]: [
    {
      src: Silvanus_Symbol_SCAG_img,
      text: 'Символ Сильвануса',
      source: {
        id: SOURCE_SCAG,
        page: 38,
      },
    },
    {
      src: Silvanus_Symbol_DND3_FnP_img,
      text: 'Символ Сильвануса',
      source: {
        id: SOURCE_DND3_FnP,
        page: 64,
      },
    },
    {
      src: Silvanus_DND3_FnP_img,
      text: 'Сильванус, Отец Дубов',
      source: {
        id: SOURCE_DND3_FnP,
        page: 64,
      },
    },
  ],
}
