const {GENDER_MALE} = require('./../../genderList')
const {GOD_MERRSHAULK} = require('./../../godIdList')
const {godMerrshaulkDescriptionList} = require('./../../textCommonParts')
const {PANTHEON_SNAKE} = require('./../../pantheonList')

module.exports = {
  id: GOD_MERRSHAULK,
  nameEn: 'Merrshaulk',
  nameShort: {
    nominative: 'Мершолк',
    genitive: 'Мершолка',
    dative: 'Мершолку',
    accusative: 'Мершолка',
    instrumental: 'Мершолком',
    prepositional: 'Мершолке',
  },
  worshipperRace: `юань-ти`,
  nameAlt: [
    `Владыка Ямы`,
  ],
  description: godMerrshaulkDescriptionList,
  pantheonId: PANTHEON_SNAKE,
  genderId: GENDER_MALE,
}
