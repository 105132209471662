const {ALIGNMENT_N} = require('./../../aligmentList')
const {DOMAIN_KNOWLEDGE} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_SKORAEUS_STONEBONES} = require('./../../godIdList')
const {PANTHEON_GIANT} = require('./../../pantheonList')
const {SOURCE_PHB} = require('./../../sourceList')
const {
  giantGodsCommonDescriptionList,
  godSkoraeusOnlyDescriptionList,
} = require('./../../textCommonParts')

module.exports = {
  id: GOD_SKORAEUS_STONEBONES,
  nameEn: 'Skoraeus Stonebones',
  nameAlt: 'Великий создатель',
  nameShort: {
    nominative: `Скораус Камнекост`,
    genitive: `Скорауса Камнекоста`,
    dative: `Скораусу Камнекосту`,
    accusative: `Скорауса Камнекоста`,
    instrumental: `Скораусом Камнекостом`,
    prepositional: `Скораусе Камнекосте`,
  },
  worshipperRace: `каменных великанов`,
  godOfWhat: [
    `искусства`,
  ],
  alignmentId: ALIGNMENT_N,
  description: [
    ...godSkoraeusOnlyDescriptionList,
    ...giantGodsCommonDescriptionList,
  ],
  symbolList: `Сталактит`,
  domainIdList: DOMAIN_KNOWLEDGE,
  pantheonId: PANTHEON_GIANT,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PHB,
    page: 297,
  },
}
