const natureGods = require('./../constants/natureGods')
const {ALIGNMENT_CG} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_LABELAS_ENORETH} = require('./../../godIdList')
const {PANTHEON_SELDARINE} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  DOMAIN_ARCANA,
  DOMAIN_FATE,
  DOMAIN_KNOWLEDGE,
} = require('./../../domainList')

module.exports = {
  id: GOD_LABELAS_ENORETH,
  nameEn: 'Labelas Enoreth',
  nameShort: {
    nominative: `Лабелас Энорет`,
    genitive: `Лабеласа Энорета`,
    dative: `Лабеласу Энорету`,
    accusative: `Лабеласа Энорета`,
    instrumental: `Лабеласом Эноретом`,
    prepositional: `Лабеласе Энорете`,
  },
  godOfWhat: [
    `времени`,
    `истории`,
    `философии`,
  ],
  alignmentId: ALIGNMENT_CG,
  symbolList: `Закатное солнце`,
  description: natureGods,
  domainIdList: [DOMAIN_KNOWLEDGE, DOMAIN_FATE, DOMAIN_ARCANA],
  pantheonId: PANTHEON_SELDARINE,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 24,
  },
}
