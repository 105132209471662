const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_LLIIRA,
} = require('./../../../../godIdList')

const Lliira_Symbol_SCAG_img = require('./../../../../../images/gods/lliira-symbol-SCAG.png')
const Lliira_Symbol_DND3_FnP_img = require('./../../../../../images/gods/lliira-symbol-DND3_FnP.webp')
const Lliira_DND3_FnP_img = require('./../../../../../images/gods/lliira-DND3_FnP.webp')

module.exports = {
  [GOD_LLIIRA]: [
    {
      src: Lliira_Symbol_SCAG_img,
      text: 'Символ Ллииры',
      source: {
        id: SOURCE_SCAG,
        page: 34,
      },
    },
    {
      src: Lliira_Symbol_DND3_FnP_img,
      text: 'Символ Ллииры',
      source: {
        id: SOURCE_DND3_FnP,
        page: 100,
      },
    },
    {
      src: Lliira_DND3_FnP_img,
      text: 'Ллиира, Несущая Радость',
      source: {
        id: SOURCE_DND3_FnP,
        page: 100,
      },
    },
  ],
}
