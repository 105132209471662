const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_ANGHARRADH,
} = require('./../../../../godIdList')
const {
  Angharradh_Aerdrie_Corellon_Fenmarel_Sashelas_Erevan_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Angharradh_Symbol_DND3_FnP_img = require('./../../../../../images/gods/angharradh-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_ANGHARRADH]: [
    {
      src: Angharradh_Symbol_DND3_FnP_img,
      text: 'Символ Ангаррад',
      source: {
        id: SOURCE_DND3_FnP,
        page: 126,
      },
    },
    Angharradh_Aerdrie_Corellon_Fenmarel_Sashelas_Erevan_DND3_FnP_data,
  ],
}
