const {
  SOURCE_DND3_FnP,
  SOURCE_ROT,
} = require('./../../../../sourceList')
const {
  GOD_TIAMAT,
} = require('./../../../../godIdList')
const {
  Tiamat_ROT_data,
  Tiamat_Emergence_from_Avernus_data,
  Ulutiu_Ubtao_Siamorphe_Tiamat_Valkur_DND3_FnP_data,
  Tiamat_MTG_AFR_data,
  Tiamat_alt_MTG_AFR_data,
} = require('./../../../commonImageCollection')

const Tiamat_Symbol_DND3_FnP_img = require('./../../../../../images/gods/tiamat-symbol-DND3_FnP.jpg')
const Tiamat_Symbol_ROT_img = require('./../../../../../images/gods/tiamat-symbol-ROT.png')

module.exports = {
  [GOD_TIAMAT]: [
    {
      src: Tiamat_Symbol_ROT_img,
      text: 'Символ Тиамат',
      source: {
        id: SOURCE_ROT,
      },
    },
    {
      src: Tiamat_Symbol_DND3_FnP_img,
      text: 'Символ Тиамат',
      source: {
        id: SOURCE_DND3_FnP,
      },
    },
    Tiamat_ROT_data,
    Tiamat_Emergence_from_Avernus_data,
    Ulutiu_Ubtao_Siamorphe_Tiamat_Valkur_DND3_FnP_data,
    Tiamat_MTG_AFR_data,
    Tiamat_alt_MTG_AFR_data,
  ],
}
