const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_KOSSUTH,
} = require('./../../../../godIdList')

const Kossuth_Symbol_DND3_FnP_img = require('./../../../../../images/gods/kossuth-symbol-DND3_FnP.webp')
const Kossuth_DND3_FnP_img = require('./../../../../../images/gods/kossuth-DND3_FnP.webp')

module.exports = {
  [GOD_KOSSUTH]: [
    {
      src: Kossuth_Symbol_DND3_FnP_img,
      text: 'Символ Коссута',
      source: {
        id: SOURCE_DND3_FnP,
        page: 36,
      },
    },
    {
      src: Kossuth_DND3_FnP_img,
      text: 'Коссут',
      source: {
        id: SOURCE_DND3_FnP,
        page: 37,
      },
    },
  ],
}
