const ladiesOfTheGoldenHills = require('./../constants/ladiesOfTheGoldenHills')
const {ALIGNMENT_CE} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_URDLEN} = require('./../../godIdList')
const {PANTHEON_GNOME} = require('./../../pantheonList')
const {PC_RACE_GNOME} = require('./../../pcRaceIdList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  DOMAIN_DEATH,
  DOMAIN_WAR,
} = require('./../../domainList')

module.exports = {
  id: GOD_URDLEN,
  nameEn: 'Urdlen',
  nameShort: {
    nominative: `Урдлен`,
    genitive: `Урдлена`,
    dative: `Урдлену`,
    accusative: `Урдлена`,
    instrumental: `Урдленом`,
    prepositional: `Урдлене`,
  },
  godOfWhat: [
    `жадности`,
    `убийств`,
    `зла`,
    `жажды крови`,
    `неконтролируемых импульсивных желаний`,
  ],
  nameAlt: [
    'Ползающий Внизу',
  ],
  alignmentId: ALIGNMENT_CE,
  description: [
    {
      header: 'Ползающий внизу',
      text: `Последний член пантеона [гномов](PC_RACE:${PC_RACE_GNOME}) — Урдлен, что предстаёт не в облике [гнома](PC_RACE:${PC_RACE_GNOME}), а в виде огромного мертвенно-белого слепого крота.

Урдлен — когтистый бог зла и жажды крови, жадности и неконтролируемых импульсивных желаний. Молодых гномов предупреждают, чтобы они _«никогда не давали Урдлену прорыть нору в их сердце»_, как предостережение против того, чтобы поддаться злым побуждениям.`,
      source: {
        id: SOURCE_SCAG,
        page: 116,
      },
    },
    ladiesOfTheGoldenHills,
  ],
  symbolList: `белолапый крот, вылезающий из-под земли`,
  domainIdList: [DOMAIN_DEATH, DOMAIN_WAR],
  pantheonId: PANTHEON_GNOME,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 25,
  },
}
