const goddessOfWisdom = require('./../constants/goddessOfWisdom')
const {ALIGNMENT_CG} = require('./../../aligmentList')
const {DOMAIN_LIFE} = require('./../../domainList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_HANALI_CELANIL} = require('./../../godIdList')
const {PANTHEON_SELDARINE} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_HANALI_CELANIL,
  nameEn: 'Hanali Celanil',
  nameShort: {
    nominative: `Ханали Селанил`,
    genitive: `Ханали Селанил`,
    dative: `Ханали Селанил`,
    accusative: `Ханали Селанил`,
    instrumental: `Ханали Селанил`,
    prepositional: `Ханали Селанил`,
  },
  godOfWhat: [
    `любви`,
    `красоты`,
    `искусства`,
    `очарования`,
  ],
  nameAlt: 'Прелестная Роза',
  alignmentId: ALIGNMENT_CG,
  description: goddessOfWisdom,
  symbolList: `Золотое сердце`,
  domainIdList: DOMAIN_LIFE,
  pantheonId: PANTHEON_SELDARINE,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_SCAG,
    page: 24,
  },
}
