const {ALIGNMENT_NG} = require('./../../aligmentList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_ELDATH} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  DOMAIN_LIFE,
  DOMAIN_NATURE,
  DOMAIN_PEACE,
  DOMAIN_UNITY,
} = require('./../../domainList')

module.exports = {
  id: GOD_ELDATH,
  nameEn: 'Eldath',
  nameShort: {
    nominative: 'Эльдат',
    genitive: 'Эльдат',
    dative: 'Эльдат',
    accusative: 'Эльдат',
    instrumental: 'Эльдат',
    prepositional: 'Эльдат',
  },
  godOfWhat: [
    `мира`,
  ],
  nameAlt: [
    `Тихая`,
    `Страж Рощ`,
    `Мать Вод`,
  ],
  description: `Эльдат — богиня водопадов, родников, заводей, тишины, мира и уединенных полян. Говорят, она часто бывает в подобных местах, особенно в тех, что служат друидскими рощами. Эльдат — богиня комфорта, исцеления и спокойствия. Благословлённые ею воды исцеляют больных, лечат безумных и дарят спокойствие умирающим.

В большинстве сельских общин есть пруд или поляна, которую крестьяне связывают с Эльдат. По традиции это место спокойных дум, где человек со своими мыслями предоставлен сам себе. Водоёмы, такие как пруды или родники, часто служат в качестве хранилища подношений. Если священное место — поляна, хранилищем может стать ручей, протекающий по ней, хотя особенный куст или дерево тоже могут стать местом, где люди будут оставлять свои подношения. Обычно подношениями служат сломанное оружие или предметы, которые верующие оставляют, надеясь в будущем на мирное существование. Многие почитатели Эльдат — пацифисты или люди, взволнованные насилием, которое они наблюдали или которое коснулось их.

Священники Эльдат не организовывают крупных сект. На самом деле, многие из них странствуют, посещая различные священные места и святыни, чтобы убедиться, что о них заботятся и они по-прежнему остаются местом упоительного спокойствия. Верующие в Эльдат как правило близки к природе и друидам, которые включают Эльдат в Круг Первых. Ударить жреца Эльдат — табу, а его убийство, говорят, приносит огромные беды. Несмотря на дарованную их верой защиту, большинство служителей Эльдат стараются избежать конфликтов, а не решить их. Служители Эльдат будут рады участвовать в мирных переговорах и засвидетельствовать их, но они не станут силой вести других к гармонии.`,
  alignmentId: ALIGNMENT_NG,
  symbolList: 'Водопад, низвергающийся в спокойный пруд',
  domainIdList: [DOMAIN_LIFE, DOMAIN_NATURE, DOMAIN_UNITY, DOMAIN_PEACE],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 29,
    },
  ],
}
