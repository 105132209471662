const arrify = require('arrify')

const listToCollectionById = require('./../../utils/listToCollectionById')
const prepareForSearch = require('./../../utils/prepareForSearch')
const joinLastItem = require('./../../utils/joinLastItem')

const SEARCH_PROP_NAME = require('./../SEARCH_PROP_NAME')
const {GENDER_MIDDLE} = require('./../genderList')
const nameFullByGender = require('./constants/nameFullByGender')
const godRawList = require('./godRawList')

const godList = godRawList
  .map(
    god => {
      const genderId = god.genderId || GENDER_MIDDLE

      const godOfWhatText = god.godOfWhat
        ? ` ${joinLastItem(arrify(god.godOfWhat))}`
        : ''
      const worshipperRaceText = god.worshipperRace
        ? ` у ${joinLastItem(arrify(god.worshipperRace))}`
        : ''

      const nameFull = {}
      const job = {}

      Object
        .entries(nameFullByGender[genderId])
        .forEach(
          ([caseName, godTypeName]) => {

            const jobText = godOfWhatText
              ? `${godTypeName}${godOfWhatText}${worshipperRaceText}`
              : god.jobName
                ? god.jobName[caseName]
                : godTypeName

            nameFull[caseName] = `${god.nameShort[caseName]}, ${jobText}`
            job[caseName] = jobText

          }
        )

      return {
        ...god,
        symbolList: arrify(god.symbolList),
        domainIdList: arrify(god.domainIdList),
        pantheonId: god.pantheonId || null,
        nameFull,
        genderId,
        job,
        name: god.nameShort.nominative,
        [SEARCH_PROP_NAME]: prepareForSearch(
          [
            nameFull.nominative,
            god.nameEn,
            arrify(god.nameAlt).join(' ') || '',
            arrify(god.nameEnAlt).join(' ') || '',
          ]
            .filter(e => e)
            .join('\n'),
        ),
      }
    },
  )
  .sort(
    (
      { name: A },
      { name: B },
    ) => A > B
      ? 1
      : -1,
  )

module.exports = godList

module.exports.godCollection = listToCollectionById(godList)
