const godsOfNature = require('./../constants/godsOfNature')
const ladiesOfTheGoldenHills = require('./../constants/ladiesOfTheGoldenHills')
const {ALIGNMENT_NG} = require('./../../aligmentList')
const {DOMAIN_NATURE} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_BAERVAN_WILDWANDERER} = require('./../../godIdList')
const {PANTHEON_GNOME} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_BAERVAN_WILDWANDERER,
  nameEn: 'Baervan Wildwanderer',
  nameShort: {
    nominative: `Баэрван Дикий Странник`,
    genitive: `Бэрвана Дикого Странника`,
    dative: `Бэрвану Дикому Страннику`,
    accusative: `Бэрвана Дикого Странника`,
    instrumental: `Бэрваном Диким Странником`,
    prepositional: `Бэрване Диком Страннике`,
  },
  godOfWhat: [
    `лесов`,
    `покрытых лесом земель`,
  ],
  alignmentId: ALIGNMENT_NG,
  description: [
    godsOfNature,
    ladiesOfTheGoldenHills,
  ],
  symbolList: `морда енота`,
  domainIdList: DOMAIN_NATURE,
  pantheonId: PANTHEON_GNOME,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 25,
  },
}
