const godsOfCrafts = require('./../constants/godsOfCrafts')
const {ALIGNMENT_CG} = require('./../../aligmentList')
const {DOMAIN_LIFE} = require('./../../domainList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_SHARINDLAR} = require('./../../godIdList')
const {PANTHEON_MORNDINSAMMAN} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_SHARINDLAR,
  nameEn: 'Sharindlar',
  nameShort: {
    nominative: `Шариндлар`,
    genitive: `Шариндлар`,
    dative: `Шариндлар`,
    accusative: `Шариндлар`,
    instrumental: `Шариндлар`,
    prepositional: `Шариндлар`,
  },
  godOfWhat: [
    `лечения`,
    `исцеления`,
    `романтической любви`,
    `плодородия`,
  ],
  nameAlt: 'леди Жизни и Милосердия',
  description: godsOfCrafts,
  alignmentId: ALIGNMENT_CG,
  symbolList: 'Пылающая игла',
  domainIdList: DOMAIN_LIFE,
  pantheonId: PANTHEON_MORNDINSAMMAN,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_SCAG,
    page: 23,
  },
}
