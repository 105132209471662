const {ALIGNMENT_CE} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_VAPRAK} = require('./../../godIdList')
const {PANTHEON_GIANT} = require('./../../pantheonList')
const {SOURCE_VGTM} = require('./../../sourceList')
const {
  CREATURE_FROST_GIANT_EVERLASTING_ONE,
  CREATURE_OGRE,
  CREATURE_TROLL,
} = require('./../../creatureIdList')
const {
  giantGodsCommonDescriptionList,
  frostGiantEverlastingOneList,
  vaprakDescription,
} = require('./../../textCommonParts')

module.exports = {
  id: GOD_VAPRAK,
  nameEn: 'Vaprak',
  nameAlt: [
    'Разрушитель',
    'Уничтожитель',
  ],
  nameShort: {
    nominative: 'Вапрак',
    genitive: 'Вапрака',
    dative: 'Вапраку',
    accusative: 'Вапрака',
    instrumental: 'Вапраком',
    prepositional: 'Вапраке',
  },
  worshipperRace: 'огров и троллей',
  godOfWhat: [
    'силы',
    'голода',
  ],
  alignmentId: ALIGNMENT_CE,
  description: [
    vaprakDescription,
    {
      text: `Ледяной великан, который с рождения слабее своих соплеменников, имеет низкий ранг в Уложении, и практически не имеет шансов возвыситься. Время от времени, когда великан сильно разочаровывается этой ситуацией, он обращается к подпольному поклонению Вапраку, божеству [троллей](CREATURE:${CREATURE_TROLL}) и [огров](CREATURE:${CREATURE_OGRE}). Великан, осенённый милостью Вапрака, трансформируется в [извечного](CREATURE:${CREATURE_FROST_GIANT_EVERLASTING_ONE}) — великана с достаточной силой, чтобы конкурировать с лидерами клана, хотя ему и суждено быть изгнанным или уничтоженным, если станет известно, кому он поклоняется.`,
      source: {
        id: SOURCE_VGTM,
        page: 29,
      },
    },
    ...frostGiantEverlastingOneList,
    ...giantGodsCommonDescriptionList,
  ],
  pantheonId: PANTHEON_GIANT,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_VGTM,
    page: 134,
  },
}
