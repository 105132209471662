const jergalLegendList = require('./../constants/jergalLegendList')
const {bhaalFollowerList} = require('./../../textCommonParts')
const {ALIGNMENT_NE} = require('./../../aligmentList')
const {DOMAIN_DEATH} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_BHAAL} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_BHAAL,
  nameEn: 'Bhaal',
  nameShort: {
    nominative: 'Баал',
    genitive: 'Баала',
    dative: 'Баалу',
    accusative: 'Баала',
    instrumental: 'Баалом',
    prepositional: 'Баале',
  },
  godOfWhat: [
    `убийства`,
  ],
  nameAlt: `Лорд Убийства`,
  description: [
    {
      header: 'Баал',
      text: `Народ Фаэруна обычно не молится Баалу и не призывает его. Они помнят его как злого и разрушительного бога, жадного до смерти — насильственной смерти любого живого существа.
    
Некоторые люди молятся Баалу, когда хотят совершить убийство. У этого человека вполне может быть веский повод для убийства, например, когда он не может исправить несправедливость законными методами. Но чаще Баалу молятся те, кто желает убить из ревности, жадности или гнева. Редко кто-то кроме профессиональных убийц или маньяков выбирает Баала в покровители, а жрецы служащие Баалу часто соединяют обе этих ипостаси.

Раньше возникали культы убийств Баала, каждый и которых вёл за собой самостоятельный, харизматичный священник Баала, но организованного поклонения Лорду Убийства практически нет. Редкостью являются и храмы, и часовни. Те, кто возводят святыню Баала, обычно делают это в благодарность за успешное убийство. Обычно в таких святынях ставят череп или отрубленную голову, окруженную каплями крови (часто голова до этого принадлежала жертве).`,
      source: {
        id: SOURCE_SCAG,
        page: 28,
      },
    },
    {
      header: 'Мемуары о моей смерти',
      text: `Братья часто спрашивают меня: каково это — умереть? И ждут, что я опишу им бесконечное сражение: унижение от поражения против славного самопожертвования. Но когда ты мёртв, невозможно думать о себе, о жизни, ибо ты в объятиях Баала.

Владения Баала — это красная пустынная земля под беззвёздным небом, замершая во времени перед разрушением последнего солнца. Там я скитался, вкушая конец всего.

Меня вернули с края Бездны, вновь связав с живой оболочкой. Сначала я возненавидел это. Я забыл как дышать, как питаться, все животные функции тех, кто цепляется за жизнь.

Но когда я был жестоко воскрешён, моя вера укрепилась через смерть больше, чем через жизнь. Я исполню волю Баала и приведу мир к идеальному финалу. Красное солнце будет плавиться над озёрами крови. Это случится. Сей мир будет вырезан по его образу и подобию.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    ...bhaalFollowerList,
    ...jergalLegendList,
  ],
  alignmentId: ALIGNMENT_NE,
  symbolList: 'Череп, окружённый кольцом капель крови',
  domainIdList: DOMAIN_DEATH,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 28,
    },
  ],
}
