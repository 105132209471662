const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_IALLANIS} = require('./../../godIdList')
const {PANTHEON_GIANT} = require('./../../pantheonList')
const {SOURCE_MM} = require('./../../sourceList')
const {giantGodsCommonDescriptionList} = require('./../../textCommonParts')

module.exports = {
  id: GOD_IALLANIS,
  nameEn: 'Iallanis',
  nameAlt: 'Ялланис',
  nameShort: {
    nominative: 'Илланис',
    genitive: 'Илланис',
    dative: 'Илланис',
    accusative: 'Илланис',
    instrumental: 'Илланис',
    prepositional: 'Илланис',
  },
  godOfWhat: [
    `любви`,
    `мира`,
  ],
  description: [
    {
      text: `Илланис — богиня любви и мира.`,
      source: {
        id: SOURCE_MM,
        page: 37,
      },
    },
    ...giantGodsCommonDescriptionList,
  ],
  pantheonId: PANTHEON_GIANT,
  genderId: GENDER_FEMALE,
}
