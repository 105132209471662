const {ALIGNMENT_CE} = require('./../../aligmentList')
const {DOMAIN_TRICKERY} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  GOD_CYRIC,
  GOD_MYSTRA,
} = require('./../../godIdList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_CYRIC,
  nameEn: 'Cyric',
  nameShort: {
    nominative: 'Цирик',
    genitive: 'Цирика',
    dative: 'Цирику',
    accusative: 'Цирика',
    instrumental: 'Цириком',
    prepositional: 'Цирике',
  },
  godOfWhat: [
    `лжи`,
  ],
  nameAlt: [
    `Принц Лжи`,
    `Тёмное Солнце`,
  ],
  description: {
    header: 'Цирик',
    text: `Поклонение Цирику вытекает непосредственно из истории его восхождения на божественный престол. Цирик был смертным в Смутное Время, и он нашел свой путь в этом хаосе, став эгоистичным предателем и убийцей. Став богом, Цирик продолжил обманывать и убивать. Известнейший факт, ставший легендой — Цирик убил [Мистру](GOD:${GOD_MYSTRA}), что послужило началом Магической Чумы более века назад.

Те, кто не поклоняется Цирику, видят в нём бога безумия, распрей и обмана, хотя его жрецы считают подобные заявления ересью. Их Принц Лжи не искалеченный безумец, а бог тёмного величия, который показывает, что в конечном итоге, все отношения между людьми черны и грязны.

Церковь Цирика открыто служит в Амне, где горожане придерживаются принципов амбициозности, самоуверенности и девиза _«покупатель должен быть бдителен»_. Цирика выбирают своим покровителем как правило садисты, мошенники, одержимые жаждой власти карьеристы или кто похуже. Другие молятся Цирику, когда делают нечто аморальное, но не хотят, чтобы об этом узнали.

«Тёмное Солнце» — изначально один из титулов Цирика, уже стал метафорой раздора в Королевствах. Говоря: _«Тёмное Солнце взошло над этим двором»_ предупреждают о том, что в благородном семействе буйным цветом расцвели интриги и распри, а супружеские пары понимают, чего ожидать, если кто-то им говорит, что _«Тёмное Солнце светит сквозь окно»_ в их отношениях.`,
    source:{
      id: SOURCE_SCAG,
      page: 28,
    },
  },
  alignmentId: ALIGNMENT_CE,
  symbolList: 'Белый череп без нижней челюсти в ореоле чёрных или фиолетовых лучей',
  domainIdList: DOMAIN_TRICKERY,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
  ],
}
