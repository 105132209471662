const {ALIGNMENT_LE} = require('./../../aligmentList')
const {DOMAIN_WAR} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_KURTULMAK} = require('./../../godIdList')
const {kurtulmakDescription} = require('./../../textCommonParts')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_PHB} = require('./../../sourceList')

module.exports = {
  id: GOD_KURTULMAK,
  nameEn: 'Kurtulmak',
  nameShort: {
    nominative: `Куртулмак`,
    genitive: `Куртулмака`,
    dative: `Куртулмаку`,
    accusative: `Куртулмака`,
    instrumental: `Куртулмаком`,
    prepositional: `Куртулмаке`,
  },
  worshipperRace: `кобольдов`,
  description: kurtulmakDescription,
  godOfWhat: [
    `войны`,
    `горного дела`,
  ],
  alignmentId: ALIGNMENT_LE,
  symbolList: `Гномий череп`,
  domainIdList: DOMAIN_WAR,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PHB,
    page: 297,
  },
}
