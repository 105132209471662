const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_ELDATH,
} = require('./../../../../godIdList')
const {
  Human_Peace_domain_TCoE_data,
  Akadi_Gargauth_Eldath_Finder_Grumbar_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Eldath_Symbol_SCAG_img = require('./../../../../../images/gods/eldath-symbol-SCAG.png')
const Eldath_Symbol_DND3_FnP_img = require('./../../../../../images/gods/eldath-symbol-DND3_FnP.jpg')

module.exports = {
  [GOD_ELDATH]: [
    {
      src: Eldath_Symbol_SCAG_img,
      text: 'Символ Эльдат',
      source: {
        id: SOURCE_SCAG,
        page: 29,
      },
    },
    {
      src: Eldath_Symbol_DND3_FnP_img,
      text: 'Символ Эльдат',
      source: {
        id: SOURCE_DND3_FnP,
        page: 94,
      },
    },
    Akadi_Gargauth_Eldath_Finder_Grumbar_DND3_FnP_data,
    Human_Peace_domain_TCoE_data,
  ],
}
