const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_GAERDAL_IRONHAND,
} = require('./../../../../godIdList')
const {
  Baervan_Baravar_Callarduran_Flandal_Gaerdal_Garl_Segojan_Urdlen_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Gaerdal_Ironhand_Symbol_DND3_FnP_img = require('./../../../../../images/gods/gaerdal_ironhand-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_GAERDAL_IRONHAND]: [
    {
      src: Gaerdal_Ironhand_Symbol_DND3_FnP_img,
      text: 'Символ Гаэрдала',
      source: {
        id: SOURCE_DND3_FnP,
        page: 136,
      },
    },
    Baervan_Baravar_Callarduran_Flandal_Gaerdal_Garl_Segojan_Urdlen_DND3_FnP_data,
  ],
}
