const {ALIGNMENT_CG} = require('./../../aligmentList')
const {GENDER_FEMALE} = require('./../../genderList')
const {
  GOD_EILISTRAEE,
  GOD_LOLTH,
} = require('./../../godIdList')
const {PANTHEON_DROW} = require('./../../pantheonList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  DOMAIN_LIGHT,
  DOMAIN_NATURE,
} = require('./../../domainList')

module.exports = {
  id: GOD_EILISTRAEE,
  nameEn: 'Eilistraee',
  nameShort: {
    nominative: `Эйлистри`,
    genitive: `Эйлистри`,
    dative: `Эйлистри`,
    accusative: `Эйлистри`,
    instrumental: `Эйлистри`,
    prepositional: `Эйлистри`,
  },
  worshipperRace: 'дроу',
  nameAlt: [
    `Элистреи`,
    `Тёмная Дева`,
  ],
  godOfWhat: [
    `песни`,
    `красоты`,
    `мастерства меча`,
    `охоты`,
    `лунного света`,
  ],
  description: [
    {
      header: 'Тёмная Дева',
      text: `Некоторые изгнанники дроу слышали песнь Эйлистри, зовущую их на поверхность созерцать восход Луны. Богиня песни, красоты, мастерства меча, охоты и лунного света, она покровительствует дроу, которые отвергли злодеяния их общества, предлагая им свет надежды.`,
      source: {
        id: SOURCE_SCAG,
        page: 109,
      },
    },
    {
      header: `Элистреи`,
      text: `Элистреи — богиня добрых дроу, красоты, песен и свободы. Тёмная Дева стремится к равновесию между всеми расами и противостоит подлым намерениям своей матери [Ллос](GOD:${GOD_LOLTH}).`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_CG,
  symbolList: `Силуэт танцующей с клинком женщины дроу на фоне полной луны`,
  domainIdList: [DOMAIN_NATURE, DOMAIN_LIGHT],
  pantheonId: PANTHEON_DROW,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_SCAG,
    page: 24,
  },
}
