module.exports = {
  ...require('./malar'),
  ...require('./marthammor'),
  ...require('./mask'),
  ...require('./mielikki'),
  ...require('./milil'),
  ...require('./moradin'),
  ...require('./myrkul'),
  ...require('./mystra'),
}
