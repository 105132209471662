const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_RILLIFANE_RALLATHIL,
} = require('./../../../../godIdList')
const {
  Hanali_Labelas_Rillifane_Sehanine_Shevarash_Solonor_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Rillifane_Rallathil_Symbol_DND3_FnP_img = require('./../../../../../images/gods/rillifane_rallathil-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_RILLIFANE_RALLATHIL]: [
    {
      src: Rillifane_Rallathil_Symbol_DND3_FnP_img,
      text: 'Символ Риллифэйна Раллатиля',
      source: {
        id: SOURCE_DND3_FnP,
        page: 130,
      },
    },
    Hanali_Labelas_Rillifane_Sehanine_Shevarash_Solonor_DND3_FnP_data,
  ],
}
