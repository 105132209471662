const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_VALKUR,
} = require('./../../../../godIdList')
const {
  Ulutiu_Ubtao_Siamorphe_Tiamat_Valkur_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Valkur_Symbol_DND3_FnP_img = require('./../../../../../images/gods/valkur-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_VALKUR]: [
    {
      src: Valkur_Symbol_DND3_FnP_img,
      text: 'Символ Валькура',
      source: {
        id: SOURCE_DND3_FnP,
      },
    },
    Ulutiu_Ubtao_Siamorphe_Tiamat_Valkur_DND3_FnP_data,
  ],
}
