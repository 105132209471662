const {ALIGNMENT_LE} = require('./../../aligmentList')
const {DOMAIN_DEATH} = require('./../../domainList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_LOVIATAR} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_LOVIATAR,
  nameEn: 'Loviatar',
  nameShort: {
    nominative: 'Ловиатар',
    genitive: 'Ловиатар',
    dative: 'Ловиатар',
    accusative: 'Ловиатар',
    instrumental: 'Ловиатар',
    prepositional: 'Ловиатар',
  },
  godOfWhat: [
    `боли`,
  ],
  nameAlt: [
    `Дева Боли`,
    `Госпожа Мучений`,
    `Старательная Плеть`,
  ],
  description: [
    {
      header: `Ловиатар`,
      text: `В представлении верных Ловиатар — у боли нет конца, она и есть — завершение. Для них, нет ничего превосходней мук — боль безгрешна. От грубейшего варварства, до извращенной пытки и душевных мук убитых горем и предательства.

Чувство боли в этой вере — это внимание Ловиатар. Поэтому среди них славится флагелланство. Боль — путь к силе, умение причинять её и выносить на себе. Жестокость, бесчувственность — считаются идеалом, прообразом Госпожи. По той же причине ее поданные ценят красоту, изящность, и манипулирование людьми.

Храмов Ловиатар немного, но верующих в нее больше, чем можно предположить. Она — божество палачей, мучителей и всех, кто желает сломать волю других. Дева Боли славится среди садистов и мазохистов, отдельные из них создают тайные культы единомышленников. Во главе таких групп — наслаждающиеся болью и властью над другими, с кучкой покорных подхалимов.

Последователи Ловиатар редко собираются в группы, разве только в больших городах. Жители в таких местах замечают их реже или не всегда поднимают панику. Но при этом далеко не все попадают в этот культ по своей воле — нередко они в рабстве, а это привлекает внимание властей. Открыто Ловиатар практически никто не поклоняется, за исключением стран, где рабство в норме.`,
      source: {
        id: SOURCE_SCAG,
        page: 34,
      },
    },
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_Ловиатар любые мучения_

_Обожает аж до исступления._

_Не носи ей дары — _

_Стань адептом игры._

_Изощрённо отдайся служению._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_LE,
  symbolList: 'Девятихвостая плеть с шипами',
  domainIdList: DOMAIN_DEATH,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 34,
    },
  ],
}
