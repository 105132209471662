const {ALIGNMENT_NE} = require('./../../aligmentList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_AURIL} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  DOMAIN_NATURE,
  DOMAIN_TEMPEST,
} = require('./../../domainList')

module.exports = {
  id: GOD_AURIL,
  nameEn: 'Auril',
  nameShort: {
    nominative: 'Ориль',
    genitive: 'Ориль',
    dative: 'Ориль',
    accusative: 'Ориль',
    instrumental: 'Ориль',
    prepositional: 'Ориль',
  },
  godOfWhat: [
    `зимы`,
  ],
  nameAlt: [
    `Аурил`,
    `Морозная Дева`,
    `Леди Морозного Поцелуя`,
    `Ледяной Рассвет`,
  ],
  description: [
    {
      header: `Ориль`,
      text: `Ориль, беспощадная богиня холода и зимы, которой в основном поклоняются в регионах, страдающих от суровых зим. Люди задабривают Ориль дарами и возносят молитвы о пощаде.

Немногие истинно почитают Ориль, и обычно это те, чьё выживание зависит от причуд зимы или те, кто по-настоящему любит этот сезон. Таковыми обычно являются её редкие жрецы, но из-за своего статуса, они обычно изгои общества. Они практикуют целибат и стараются остаться отстранёнными вне духовного служения.

В Лускане есть храм, посвященный Ориль, — Зимний дворец. Здание без крыши, состоящее из колонн и арок, вырезанных из белого камня. Ритуалы поклонения Ориль часто кажутся жестоким для непосвященных. В Лускане, гости собираются в храме, чтобы посмотреть традиционный ритуал, известный как _«мокрый ход»_, в котором верующие надевают обледеневшую одежду. Затем они обходят шесть белых колонн, известных как Поцелуи Ориль, которые находятся в разных частях города.

Верующие переходят от колонны к колонне, скандируя молитвы богине. При достижении колонны, верующий должен вскарабкаться на нее, а затем _«поцеловать даму»_, — прикоснуться губами к ржавой железной пластине в верхней части. Зимой, эти события напоминают безумные соревнования, с дополнительным риском обморожения и травмы, вызванным падением со скользких столбов. Процессии бегунов приветствуются завсегдатаями близлежащих таверн, которые делают ставки на выносливость участников. Те, кто закончили гонку, как полагают, помогли сделать зиму легче, и им редко приходится платить за еду или эль в течении зимы.`,
      source: {
        id: SOURCE_SCAG,
        page: 26,
      },
    },
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_Ледяное дыханье Ориль_

_Без тепла оставляет, без сил._

_Ей пожертвуй обед,_

_Да полгорсти монет,_

_И надейся, что ты проскочил._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_NE,
  symbolList: 'Шестилучевая снежинка',
  domainIdList: [DOMAIN_TEMPEST, DOMAIN_NATURE],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 26,
    },
  ],
}
