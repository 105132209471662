const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_MYSTRA,
} = require('./../../../../godIdList')

const Mystra_Symbol_SCAG_img = require('./../../../../../images/gods/mystra-symbol-SCAG.png')
const Mystra_Symbol_DND3_FnP_img = require('./../../../../../images/gods/mystra-symbol-DND3_FnP.jpg')
const Mystra_DND3_FnP_img = require('./../../../../../images/gods/mystra-DND3_FnP.jpg')

module.exports = {
  [GOD_MYSTRA]: [
    {
      src: Mystra_Symbol_SCAG_img,
      text: 'Символ Мистры',
      source: {
        id: SOURCE_SCAG,
        page: 36,
      },
    },
    {
      src: Mystra_Symbol_DND3_FnP_img,
      text: 'Символ Мистры',
      source: {
        id: SOURCE_DND3_FnP,
        page: 51,
      },
    },
    {
      src: Mystra_DND3_FnP_img,
      text: 'Мистра, Леди Тайн',
      source: {
        id: SOURCE_DND3_FnP,
        page: 52,
      },
    },
  ],
}
