const {SOURCE_SCAG} = require('./../../sourceList')
const {PC_RACE_DWARF} = require('./../../pcRaceIdList')
const {
  GOD_MORADIN,
  GOD_BERRONAR,
} = require('./../../godIdList')

module.exports = {
  header: `Отец Кузни и Уважаемая Мать`,
  text: `[Морадин](GOD:${GOD_MORADIN}), Кователь Душ — лидер дварфийских богов. Известный как Отец Дварфов и Всеотец, он бог народа [дварфов](PC_RACE:${PC_RACE_DWARF}) в целом, а также бог созидания, защиты и дварфийских ремесел (таких как кузнечное дело или работа с камнем).

Его жена — Уважаемая Мать, [Берронар](GOD:${GOD_BERRONAR}) Истинно Серебряная — богиня домашнего очага, клятвы, верности, преданности, честности и чести.`,
  source: {
    id: SOURCE_SCAG,
    page: 105,
  },
}
