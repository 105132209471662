const {ALIGNMENT_LN} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GOD_AZUTH,
  GOD_SAVRAS,
} = require('./../../godIdList')
const {
  DOMAIN_FATE,
  DOMAIN_KNOWLEDGE,
} = require('./../../domainList')

module.exports = {
  id: GOD_SAVRAS,
  nameEn: 'Savras',
  nameShort: {
    nominative: 'Саврас',
    genitive: 'Савраса',
    dative: 'Саврасу',
    accusative: 'Савраса',
    instrumental: 'Саврасом',
    prepositional: 'Саврасе',
  },
  godOfWhat: [
    `предсказаний`,
    `судьбы`,
  ],
  nameAlt: [
    'Всевидящий',
    'Третий Глаз',
    'Лорд Прорицаний',
  ],
  description: {
    header: 'Саврас',
    text: `Саврас — бог прорицаний и предсказаний судьбы. Ему поклоняются немногие, но многие молятся ему во время небольших предсказательных ритуалов. Например, молодые мужчины и женщины иногда пытаются узнать имя своих будущих супругов, читая стихотворный заговор, взывающий к Саврасу, и глядя в зеркало при этом.

Саврас не имеет активных храмов на Фаэруне на данный момент, а его святилища немногочисленны и отдалены друг от друга, находясь в укромных уголках библиотек и рукописных. Не смотря на неширокую известность, представители определённых профессий, таких как следователи, прорицатели, судьи и прочие люди, связанные с поиском правды, воздают почести Саврасу ежедневно. Их часто можно узнать по искусным посохам, которые они носят в его честь. Согласно легенде, Саврас веками был заключён в посохе [Азута](GOD:${GOD_AZUTH}). Со временем, [Азут](GOD:${GOD_AZUTH}) освободил Савраса, когда тот поклялся ему в верности, и сегодня посох является символом для тех, кто поклоняется Саврасу. Набожные поклонники предпринимают множество усилий, украшая и декорируя свой посох, в надежде, что Саврас сочтёт его достойным местом, чтобы на время остаться рядом.`,
    source: {
      id: SOURCE_SCAG,
      page: 37,
    },
  },
  alignmentId: ALIGNMENT_LN,
  symbolList: 'Хрустальный шар, содержащий множество разных глаз',
  domainIdList: [
    DOMAIN_FATE,
    DOMAIN_KNOWLEDGE,
  ],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 37,
    },
  ],
}
