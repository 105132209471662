const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_EILISTRAEE,
} = require('./../../../../godIdList')
const {
  Kiaransalee_Vhaeraun_Lolth_Selvetarm_Ghaunadaur_Eilistraee_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Eilistraee_Symbol_DND3_FnP_img = require('./../../../../../images/gods/eilistraee-symbol-DND3_FnP.jpg')
const Eilistraee_DND3_FnP_img = require('./../../../../../images/gods/eilistraee-DND3_FnP.jpg')

module.exports = {
  [GOD_EILISTRAEE]: [
    {
      src: Eilistraee_Symbol_DND3_FnP_img,
      text: 'Символ Эйлистри',
      source: {
        id: SOURCE_DND3_FnP,
        page: 24,
      },
    },
    {
      src: Eilistraee_DND3_FnP_img,
      text: 'Эйлистри Тёмная Дева',
      source: {
        id: SOURCE_DND3_FnP,
        page: 24,
      },
    },
    Kiaransalee_Vhaeraun_Lolth_Selvetarm_Ghaunadaur_Eilistraee_DND3_FnP_data,
  ],
}
