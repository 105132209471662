const {
  SOURCE_DND3_FnP,
  SOURCE_DND4_FRPG,
} = require('./../../../../sourceList')
const {
  GOD_SHAR,
} = require('./../../../../godIdList')

const {
  Shadowheart_MTG_CLB_data,
} = require('./../../../commonImageCollection')

const Shar_Symbol_DND3_FnP_img = require('./../../../../../images/gods/shar-symbol-DND3_FnP.webp')
const Shar_DND3_FnP_img = require('./../../../../../images/gods/shar-DND3_FnP.jpg')
const Shar_Symbol_DND4_FRPG_img = require('./../../../../../images/gods/shar_symbol_DND4_FRPG.webp')

module.exports = {
  [GOD_SHAR]: [
    {
      src: Shar_Symbol_DND3_FnP_img,
      text: 'Символ Шар',
      source: {
        id: SOURCE_DND3_FnP,
        page: 59,
      },
    },
    {
      src: Shar_Symbol_DND4_FRPG_img,
      text: 'Символ Шар',
      source: {
        id: SOURCE_DND4_FRPG,
        page: 153,
      },
    },
    {
      src: Shar_DND3_FnP_img,
      text: 'Шар, Тёмная Леди',
      source: {
        id: SOURCE_DND3_FnP,
        page: 60,
      },
    },
    Shadowheart_MTG_CLB_data,
  ],
}
