const {ALIGNMENT_NG} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  DOMAIN_ARCANA,
  DOMAIN_KNOWLEDGE,
} = require('./../../domainList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GOD_DENEIR,
  GOD_OGHMA,
} = require('./../../godIdList')

module.exports = {
  id: GOD_DENEIR,
  nameEn: 'Deneir',
  nameShort: {
    nominative: 'Денеир',
    genitive: 'Денеира',
    dative: 'Денеиру',
    accusative: 'Денеира',
    instrumental: 'Денеиром',
    prepositional: 'Денеире',
  },
  godOfWhat: [
    `письменности`,
  ],
  nameAlt: [
    `Лорд Всех Глифов и Образов`,
    `Первый Писец`,
    `Писец Огмы`,
  ],
  description: `Денеир — бог литературы и грамотности, покровитель художников и писцов. Он отвечает за точность передачи и описания, чтение и письмо, за распространение информации. В легендах Денеир часто изображается как писарь в услужении Огмы, и иногда его считают правой рукой [Огмы](GOD:${GOD_OGHMA}).

Вознести молитву Денеиру при написании письма или записи информации, чтобы избежать ошибок — обычное дело. Так же и художники поминают Денеира, обычно проливая свет на рукопись перед началом и после завершения картины, гобеленов, связанных с историей и других проявлений искусства, в которых пытались запечатлеть истину.

Последователи Денеира верят, что если не записать и не сохранить информацию, то она будет утеряна. Грамота для них — важный дар богов, который нужно распространять и которому нужно обучать. Его последователи — это писцы и учёные посвятившие себя, как и их покровитель, сохранению написанного, а также их изучению, потому что они верят, что сам Денеир скрыт в линиях, формах и пассажах написанного слова. Священники Денеира дают клятву милосердия, которая обязывает их помогать другим писать письма и документировать информацию.

Последователи этого божества как правило индивидуалисты, объединенные только общей верой и не слишком заинтересованные в религиозной иерархии и протоколе. Такое поведение основано ещё и на том, что благословение Денеира божественной магией чаще даруется тому, кто уходит с головой в письмо, а не тем, кто воображает себя частью храма или религиозного ордена. Изучение святейшей книги — _Тома Всеобщей Гармонии_, один из самых верных способов стать достойным благословения Денеира.`,
  alignmentId: ALIGNMENT_NG,
  symbolList: 'Горящая свеча над открытым глазом',
  domainIdList: [DOMAIN_ARCANA, DOMAIN_KNOWLEDGE],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 29,
    },
  ],
}
