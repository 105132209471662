const {SOURCE_GAME_BG_3} = require('./../../sourceList')
const {
  GOD_YONDALLA,
  GOD_GARL_GLITTERGOLD,
} = require('./../../godIdList')

module.exports = {
  header: `Шаг, другой, мы встретимся с тобой`,
  text: `
[Гарл](GOD:${GOD_GARL_GLITTERGOLD}) и [Йондалла](GOD:${GOD_YONDALLA})

Под одеялом

Бывало, частенько

Жевали печеньки.

Вдруг зажужжало

Над ухом [Йондаллы](GOD:${GOD_YONDALLA})

Крошку схватило,

Сев на носу.

Стой! — закричала

[Гарлу](GOD:${GOD_GARL_GLITTERGOLD}) [Йондалла](GOD:${GOD_YONDALLA}),

Но [Гарл](GOD:${GOD_GARL_GLITTERGOLD}) со всей силы

Прихлопнул осу.

_— Популярная среди полуросликов детская снижка стихов и считалок, собранных и записанных Эуфадорой Великоперой_`,
  source: {
    id: SOURCE_GAME_BG_3,
  },
}
