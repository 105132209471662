const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_SHEELA_PERYROYL,
} = require('./../../../../godIdList')
const {
  Arvoreen_Brandobaris_Cyrrollalee_Sheela_Peryroyl_Urogalan_Yondalla_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Sheela_Peryroyl_Symbol_DND3_FnP_img = require('./../../../../../images/gods/sheela-peryroyl-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_SHEELA_PERYROYL]: [
    {
      src: Sheela_Peryroyl_Symbol_DND3_FnP_img,
      text: 'Символ Шилы Периройл',
      source: {
        id: SOURCE_DND3_FnP,
        page: 141,
      },
    },
    Arvoreen_Brandobaris_Cyrrollalee_Sheela_Peryroyl_Urogalan_Yondalla_DND3_FnP_data,
  ],
}
