const {ALIGNMENT_CE} = require('./../../aligmentList')
const {DOMAIN_WAR} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_THRYM} = require('./../../godIdList')
const {PANTHEON_GIANT} = require('./../../pantheonList')
const {SOURCE_PHB} = require('./../../sourceList')
const {
  giantGodsCommonDescriptionList,
  godThrymOnlyDescriptionList,
} = require('./../../textCommonParts')

module.exports = {
  id: GOD_THRYM,
  nameEn: 'Thrym',
  nameShort: {
    nominative: `Трим`,
    genitive: `Трима`,
    dative: `Триму`,
    accusative: `Трима`,
    instrumental: `Тримом`,
    prepositional: `Триме`,
  },
  worshipperRace: `ледяных великанов`,
  description: [
    ...godThrymOnlyDescriptionList,
    ...giantGodsCommonDescriptionList,
  ],
  godOfWhat: [
    `силы`,
  ],
  alignmentId: ALIGNMENT_CE,
  symbolList: `Белый меч с двумя клинками`,
  domainIdList: DOMAIN_WAR,
  pantheonId: PANTHEON_GIANT,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PHB,
    page: 297,
  },
}
