const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_ABBATHOR,
} = require('./../../../../godIdList')
const {
  Abbathor_Berronar_Clangeddin_Duerra_Dumathoin_Dugmaren_Gorm_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Abbathor_Symbol_DND3_FnP_img = require('./../../../../../images/gods/abbathor-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_ABBATHOR]: [
    {
      src: Abbathor_Symbol_DND3_FnP_img,
      text: 'Символ Аббатора',
      source: {
        id: SOURCE_DND3_FnP,
        page: 116,
      },
    },
    Abbathor_Berronar_Clangeddin_Duerra_Dumathoin_Dugmaren_Gorm_DND3_FnP_data,
  ],
}
