const {SOURCE_SCAG} = require('./../../sourceList')
const {PC_RACE_GNOME} = require('./../../pcRaceIdList')
const {
  GOD_BAERVAN_WILDWANDERER,
  GOD_SEGOJAN_EARTHCALLER,
} = require('./../../godIdList')

module.exports = {
  header: 'Боги природы',
  text: `[Бэрван Дикий Странник](GOD:${GOD_BAERVAN_WILDWANDERER}) — [гномий](PC_RACE:${PC_RACE_GNOME}) бог лесов и покрытых лесом земель, сопровождаемый его компаньонкой — огромной енотихой Чиктиккой Быстрые Лапы (говорят, что из них двоих именно она наиболее благоразумна).

[Сегожан Зовущий Землю](GOD:${GOD_SEGOJAN_EARTHCALLER}) — бог скорее подземных пространств, чем наземной поверхности, также является богом нор и живущих в земле растений и животных.`,
  source: {
    id: SOURCE_SCAG,
    page: 116,
  },
}
