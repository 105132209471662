const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_BARAVAR_CLOAKSHADOW,
} = require('./../../../../godIdList')
const {
  Baervan_Baravar_Callarduran_Flandal_Gaerdal_Garl_Segojan_Urdlen_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Baravar_Cloakshadow_Symbol_DND3_FnP_img = require('./../../../../../images/gods/baravar_cloakshadow-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_BARAVAR_CLOAKSHADOW]: [
    {
      src: Baravar_Cloakshadow_Symbol_DND3_FnP_img,
      text: 'Символ Баравара',
      source: {
        id: SOURCE_DND3_FnP,
        page: 134,
      },
    },
    Baervan_Baravar_Callarduran_Flandal_Gaerdal_Garl_Segojan_Urdlen_DND3_FnP_data,
  ],
}
