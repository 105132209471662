import React from 'react'

import God from '@/components/God'
import GodCatalog from '@/components/GodCatalog'

import {godCollection} from "@/constants/godList"
import godImageCollection from '@/constants/images/godImageCollection'

import CommonCatalogPageTemplate from '@/templates/CommonCatalogPageTemplate'

const defaultSeoData = {
  title: 'Боги',
  description: 'Каталог богов Забытых Королевств Dungeons & Dragons',
}

const GodPageTemplate = (props) => (
  <CommonCatalogPageTemplate
    defaultSeoData={defaultSeoData}
    itemCollection={godCollection}
    itemImageCollection={godImageCollection}
    ItemComponent={God}
    ListComponent={GodCatalog}
    {...props}
  />
)

export default GodPageTemplate
