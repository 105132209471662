const {ALIGNMENT_LN} = require('./../../aligmentList')
const {DOMAIN_WAR} = require('./../../domainList')
const {GENDER_FEMALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  GOD_RED_KNIGHT,
  GOD_TEMPUS,
} = require('./../../godIdList')

module.exports = {
  id: GOD_RED_KNIGHT,
  nameEn: 'The Red Knight',
  nameShort: {
    nominative: 'Красный Рыцарь',
    genitive: 'Красного Рыцаря',
    dative: 'Красному Рыцарю',
    accusative: 'Красным Рыцарем',
    instrumental: 'Красным Рыцарем',
    prepositional: 'Красном Рыцаре',
  },
  godOfWhat: [
    `планирования`,
    `стратегии`,
  ],
  nameAlt: [
    'Леди Стратегии',
    'Багряный Генерал',
    'Гроссмейстер Игры в Копья',
  ],
  description: `Красный Рыцарь — богиня планирования и стратегии. Те, кто почитают её называют себя Красным Содружеством. Они считают, что войны выигрываются лучшим планированием, стратегией и тактикой. Догма Красного Рыцаря сильно связана со стратегией, как-то: 

* _«Каждая война — это череда сражений. Поражение в одном из них ещё не означает поражение в войне»_.
* _«На войне готовься к миру. В мирное время готовься к войне»_.
* _«Ищи союзников среди врагов врага твоего»_.

Поклонение Красному Рыцарю выросло среди почитающих героизм монашеских орденов [Темпуса](GOD:${GOD_TEMPUS}) в Тетире вскоре после Смутных Времён. Красный Рыцарь с тех пор набрал популярность из-за того, что его последователи называют Великой Стратагемой: десятилетиями, её жрецы отправлялись в горячие точки, чтобы обучить генералов и королей мастерству стратегии и полевой тактики. Многие лидеры, к которым они прибывали, поначалу не принимали их, но вскоре понимали, что те, кто прислушивался к советам последователей Багряного Генерала, получали значительные выгоды. Благодарные победители строили храмы в честь Леди Стратегии, тем самым распространяя её веру.

Сегодня последователи Красного Рыцаря есть почти во всех землях, на которых в прошлом столетии шли войны. Последователи Красного Рыцаря редки среди всего населения, но часто их можно встретить среди главнокомандующих армиями, инструкторов военных колледжей, квартермейстеров и авторов книг по стратегии. В каждом храме Красного Рыцаря есть алтарь [Темпуса](GOD:${GOD_TEMPUS}), и потому там часто бывают наёмники и солдаты. Храм обычно окружает обширный павильон или двор, который может быть сдан отряду солдат или наёмников для практики и тренировок. Её жречество считает, что тренировка войск во дворе храма — это одна из предпочтительных форм подношений Красному Рыцарю.`,
  alignmentId: ALIGNMENT_LN,
  symbolList: 'Красный шахматный конь со звёздами вместо глаз',
  domainIdList: DOMAIN_WAR,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 37,
    },
  ],
}
