const {ALIGNMENT_LE} = require('./../../aligmentList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_LUTHIC} = require('./../../godIdList')
const {PANTHEON_ORC} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  firstFamilyDescription,
  luthicDescription,
} = require('./../../textCommonParts')
const {
  DOMAIN_LIFE,
  DOMAIN_NATURE,
} = require('./../../domainList')

module.exports = {
  id: GOD_LUTHIC,
  nameEn: 'Luthic',
  nameShort: {
    nominative: `Лутик`,
    genitive: `Лутик`,
    dative: `Лутик`,
    accusative: `Лутик`,
    instrumental: `Лутик`,
    prepositional: `Лутик`,
  },
  godOfWhat: [
    `плодовитости`,
    `пещер`,
    `ведовства`,
  ],
  alignmentId: ALIGNMENT_LE,
  description: [
    luthicDescription,
    firstFamilyDescription,
  ],
  symbolList: `Орочья руна, означающая «вход в пещеру»`,
  domainIdList: [DOMAIN_LIFE, DOMAIN_NATURE],
  pantheonId: PANTHEON_ORC,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_SCAG,
    page: 25,
  },
}
