const {SOURCE_SCAG} = require('./../../sourceList')
const {PC_RACE_HALFLING} = require('./../../pcRaceIdList')
const {
  GOD_BRANDOBARIS,
  GOD_UROGALAN,
} = require('./../../godIdList')

module.exports = {
  header: 'Теневые боги',
  text: `[Брандобарис](GOD:${GOD_BRANDOBARIS}), бог обманщиков, воров и всего скрытного, покровитель многих приключенцев из числа [полуросликов](GOD:${PC_RACE_HALFLING}).

[Урогалан](GOD:${GOD_UROGALAN}), тихий, меланхоличный бог земли и смерти, везде сопровождается огромным чёрным псом. Он опечален своим долгом, но неусыпно следит за тем, чтобы мёртвые были достойно похоронены и их вечный сон ничем не был нарушен.`,
  source: {
    id: SOURCE_SCAG,
    page: 111,
  },
}
