const {GENDER_MALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_VGTM} = require('./../../sourceList')
const {
  GOD_KHURGORBAEYAG,
  GOD_MAGLUBIYET,
} = require('./../../godIdList')

module.exports = {
  id: GOD_KHURGORBAEYAG,
  nameEn: 'Khurgorbaeyag',
  nameShort: {
    nominative: `Хургорбояг`,
    genitive: `Хургорбояга`,
    dative: `Хургорбоягу`,
    accusative: `Хургорбояга`,
    instrumental: `Хургорбоягом`,
    prepositional: `Хургорбояге`,
  },
  worshipperRace: 'гоблинов',
  nameAlt: 'Надзиратель',
  description: {
    header: `Хургорбояг: надзиратель над всеми`,
    text: `У гоблинов когда-то было много богов, но единственный, кто пережил господство [Маглубиета](GOD:${GOD_MAGLUBIYET}), это безжалостный Хургорбояг, известный как Надзиратель. Хургорбояг призывает своих почитателей быть хозяевами других. Только владея кнутом, они могут надеяться избежать его удара. Хургорбояг иногда выражает свое присутствие или желание следующими гневными посланиями и магическими благословениями: удар кнута без видимого источника, цепи или веревки, которые перемещаются сами собой, сияющая клетка, которая, как будто запирает его противников или тех, кто вызывает у него недовольство. Почитатели Хургорбояга иногда внезапно впадают в депрессию, которая является знаком того, что они так или иначе вызвали недовольство у своего бога. Когда они приходят в себя от этого отчаяния, они хватают кнут хозяина с возобновленным рвением и ищут ещё существ, которых они могут им отделать.

Священный символ Хургорбояга — сделанный из кожи жёлто-красный полосатый кнут. Этот символ власти используется его владельцем против гоблинов низшей касты так же, как и против рабов и врагов. Знания того, как сделать такой кнут, достаточно, чтобы поднять гоблина до касты господ — _кнутобоев_. Часто тайну охраняет единственная семья в племени, которая наслаждается престижем и влиянием, потому что контролирует поставки кнутов.`,
    source: {
      id: SOURCE_VGTM,
      page: 42,
    },
  },
  symbolList: 'кожаный жёлто–красный полосатый кнут',
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_VGTM,
    page: 42,
  },
}
