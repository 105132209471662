const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_CHAUNTEA,
} = require('./../../../../godIdList')

const Chauntea_Symbol_SCAG_img = require('./../../../../../images/gods/chauntea-symbol-SCAG.png')
const Chauntea_DND3_FnP_img = require('./../../../../../images/gods/chauntea-DND3_FnP.webp')
const Chauntea_Symbol_DND3_FnP_img = require('./../../../../../images/gods/chauntea-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_CHAUNTEA]: [
    {
      src: Chauntea_Symbol_SCAG_img,
      text: 'Символ Чонтеи',
      source: {
        id: SOURCE_SCAG,
        page: 28,
      },
    },
    {
      src: Chauntea_Symbol_DND3_FnP_img,
      text: 'Символ Чонтеи в 14 веке ЛД',
      source: {
        id: SOURCE_DND3_FnP,
        page: 19,
      },
    },
    {
      src: Chauntea_DND3_FnP_img,
      text: 'Чонтея, Богиня Зерна',
      source: {
        id: SOURCE_DND3_FnP,
        page: 20,
      },
    },
  ],
}
