const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_AZUTH,
} = require('./../../../../godIdList')

const Azuth_Symbol_DND3_FnP_img = require('./../../../../../images/gods/azuth-symbol-DND3_FnP.webp')
const Azuth_DND3_FnP_img = require('./../../../../../images/gods/azuth-DND3_FnP.webp')
const Azuth_Symbol_SCAG_img = require('./../../../../../images/gods/azuth-symbol-SCAG.webp')

module.exports = {
  [GOD_AZUTH]: [
    {
      src: Azuth_Symbol_SCAG_img,
      text: 'Символ Азута',
      source: {
        id: SOURCE_SCAG,
        page: 26,
      },
    },
    {
      src: Azuth_Symbol_DND3_FnP_img,
      text: 'Символ Азута',
      source: {
        id: SOURCE_DND3_FnP,
        page: 13,
      },
    },
    {
      src: Azuth_DND3_FnP_img,
      text: 'Азут, Первый Магистр',
      source: {
        id: SOURCE_DND3_FnP,
        page: 14,
      },
    },
  ],
}
