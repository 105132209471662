const {
  GOD_SELUNE,
  GOD_LATHANDER,
  GOD_KELEMVOR,
} = require('./../../godIdList')
const {SOURCE_GAME_BG_3} = require('./../../sourceList')

module.exports = {
  header: `Домашняя молитва`,
  text: `
[Латандер](GOD:${GOD_LATHANDER}) светит,

[Селунэ](GOD:${GOD_SELUNE}) направляет,

[Келемвор](GOD:${GOD_KELEMVOR}) судит

И проход отворяет.`,
  source: {
    id: SOURCE_GAME_BG_3,
  },
}
