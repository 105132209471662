const {SOURCE_SCAG} = require('./../../sourceList')
const {
  GOD_DUGMAREN_BRIGHTMANTLE,
  GOD_MARTHAMMOR,
} = require('./../../godIdList')

module.exports = {
  header: 'Боги дальних мест',
  text: `Бог изобретений и открытий [Дагмарен Светлая Мантия](GOD:${GOD_DUGMAREN_BRIGHTMANTLE}), также известный под именами Блуждающий Исследователь или Сияние в Глазах.

[Мартаммор Дьюин](GOD:${GOD_MARTHAMMOR}) — бог путешественников, покровитель экспатриантов и проводников, божество дорог и молнии.`,
  source: {
    id: SOURCE_SCAG,
    page: 106,
  },
}
