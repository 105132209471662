const {ALIGNMENT_LE} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_MAGLUBIYET} = require('./../../godIdList')
const {maglubiyetDescription} = require('./../../textCommonParts')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_PHB} = require('./../../sourceList')
const {
  DOMAIN_ORDER,
  DOMAIN_WAR,
} = require('./../../domainList')

module.exports = {
  id: GOD_MAGLUBIYET,
  nameEn: 'Maglubiyet',
  nameShort: {
    nominative: `Маглубиет`,
    genitive: `Маглубиета`,
    dative: `Маглубиету`,
    accusative: `Маглубиета`,
    instrumental: `Маглубиетом`,
    prepositional: `Маглубиете`,
  },
  nameAlt: 'Могучий',
  worshipperRace: `гоблиноидов`,
  godOfWhat: [
    `войны`,
    `глубин`,
    `тьмы`,
  ],
  description: maglubiyetDescription,
  alignmentId: ALIGNMENT_LE,
  symbolList: `Окровавленный топор`,
  domainIdList: [DOMAIN_ORDER, DOMAIN_WAR],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PHB,
    page: 297,
  },
}
