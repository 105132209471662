module.exports = {
  ...require('./baervan_wildwanderer'),
  ...require('./bahamut'),
  ...require('./bahgtru'),
  ...require('./bane'),
  ...require('./baravar_cloakshadow'),
  ...require('./berronar'),
  ...require('./beshaba'),
  ...require('./bhaal'),
  ...require('./brandobaris'),

}
