const {ALIGNMENT_N} = require('./../../aligmentList')
const {DOMAIN_NATURE} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_SKERRIT} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_PHB} = require('./../../sourceList')

module.exports = {
  id: GOD_SKERRIT,
  nameEn: 'Skerrit',
  nameShort: {
    nominative: `Скеррит`,
    genitive: `Скеррита`,
    dative: `Скерриту`,
    accusative: `Скеррита`,
    instrumental: `Скерритом`,
    prepositional: `Скеррите`,
  },
  worshipperRace: `кентавров и сатиров`,
  godOfWhat: [
    `природы`,
  ],
  alignmentId: ALIGNMENT_N,
  symbolList: `Дуб, растущий из жёлудя`,
  domainIdList: DOMAIN_NATURE,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PHB,
    page: 297,
  },
}
