const ladiesOfTheGoldenHills = require('./../constants/ladiesOfTheGoldenHills')
const wiseProtectors = require('./../constants/wiseProtectors')
const garl_and_yondalla = require('./../constants/garl_and_yondalla')
const {ALIGNMENT_LG} = require('./../../aligmentList')
const {DOMAIN_TRICKERY} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_GARL_GLITTERGOLD} = require('./../../godIdList')
const {PANTHEON_GNOME} = require('./../../pantheonList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_GARL_GLITTERGOLD,
  nameEn: 'Garl Glittergold',
  nameShort: {
    nominative: `Гарл Златоблеск`,
    genitive: `Гарла Златоблеска`,
    dative: `Гарлу Златоблеску`,
    accusative: `Гарла Златоблеска`,
    instrumental: `Гарлом Златоблеском`,
    prepositional: `Гарле Златоблеске`,
  },
  godOfWhat: [
    `драгоценных камней`,
    `защиты`,
    `обмана`,
    `уловок`,
    `хитрости`,
    `юмора`,
  ],
  nameAlt: 'Бдительный Защитник',
  alignmentId: ALIGNMENT_LG,
  description: [
    wiseProtectors,
    ladiesOfTheGoldenHills,
    {
      header: `Гарл Златоблеск`,
      text: `Гарл Златоблеск, Бдительный Защитник — верховный бог гномьего пантеона, покровитель юмора, защиты и хитрости.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    garl_and_yondalla,
  ],
  symbolList: `золотой самородок`,
  domainIdList: DOMAIN_TRICKERY,
  pantheonId: PANTHEON_GNOME,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 297,
    },
    {
      id: SOURCE_SCAG,
      page: 25,
    },
  ],
}
