const {SOURCE_SCAG} = require('./../../sourceList')
const {PC_RACE_GNOME} = require('./../../pcRaceIdList')
const {
  GOD_BARAVAR_CLOAKSHADOW,
  GOD_CALLARDURAN_SMOOTHHANDS,
} = require('./../../godIdList')

module.exports = {
  header: 'Тень и камень',
  text: `[Баравар Теневой Плащ](GOD:${GOD_BARAVAR_CLOAKSHADOW}) — бог иллюзий и обмана, носящий соответствующий титул Лукавого.

[Каллардюран Гладкорукий](GOD:${GOD_CALLARDURAN_SMOOTHHANDS}) — бог камня и Подземья, покровитель [свирфнеблинов](PC_RACE:${PC_RACE_GNOME}).`,
  source: {
    id: SOURCE_SCAG,
    page: 116,
  },
}
