const {
  SOURCE_DND4_FRPG,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_ASMODEUS,
} = require('./../../../../godIdList')

const Asmodeus_Symbol_SCAG_img = require('./../../../../../images/gods/asmodeus-symbol-SCAG.webp')
const Asmodeus_Symbol_DND4_FRPG_img = require('./../../../../../images/gods/asmodeus_symbol_DND4_FRPG.webp')

module.exports = {
  [GOD_ASMODEUS]: [
    {
      src: Asmodeus_Symbol_SCAG_img,
      text: 'Символ Асмодея',
      source: {
        id: SOURCE_SCAG,
        page: 25,
      },
    },
    {
      src: Asmodeus_Symbol_DND4_FRPG_img,
      text: 'Символ Асмодея',
      source: {
        id: SOURCE_DND4_FRPG,
        page: 151,
      },
    },
  ],
}
