const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_BRANDOBARIS,
} = require('./../../../../godIdList')
const {
  Arvoreen_Brandobaris_Cyrrollalee_Sheela_Peryroyl_Urogalan_Yondalla_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Brandobaris_Symbol_DND3_FnP_img = require('./../../../../../images/gods/brandobaris-symbol-DND3_FnP.jpg')

module.exports = {
  [GOD_BRANDOBARIS]: [
    {
      src: Brandobaris_Symbol_DND3_FnP_img,
      text: 'Символ Брандобариса',
      source: {
        id: SOURCE_DND3_FnP,
        page: 139,
      },
    },
    Arvoreen_Brandobaris_Cyrrollalee_Sheela_Peryroyl_Urogalan_Yondalla_DND3_FnP_data,
  ],
}
