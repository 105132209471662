const {
  SOURCE_BOOK_LOWDER_PoL,
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_CYRIC,
} = require('./../../../../godIdList')

const Cyric_Symbol_SCAG_img = require('./../../../../../images/gods/cyric-symbol-SCAG.png')
const Cyric_Symbol_DND3_FnP_img = require('./../../../../../images/gods/cyric-symbol-DND3_FnP.webp')
const Cyric_DND3_FnP_img = require('./../../../../../images/gods/cyric-DND3_FnP.webp')
const Cyric_BOOK_LOWDER_PoL_img = require('./../../../../../images/gods/cyric_book_lowder_pol.webp')

module.exports = {
  [GOD_CYRIC]: [
    {
      src: Cyric_BOOK_LOWDER_PoL_img,
      text: 'Цирик',
      source: {
        id: SOURCE_BOOK_LOWDER_PoL,
      },
    },
    {
      src: Cyric_Symbol_SCAG_img,
      text: 'Символ Цирика на кольце',
      source: {
        id: SOURCE_SCAG,
        page: 28,
      },
    },
    {
      src: Cyric_Symbol_DND3_FnP_img,
      text: 'Символ Цирика',
      source: {
        id: SOURCE_DND3_FnP,
        page: 21,
      },
    },
    {
      src: Cyric_DND3_FnP_img,
      text: 'Цирик, Принц Лжи',
      source: {
        id: SOURCE_DND3_FnP,
        page: 22,
      },
    },
  ],
}
