const {
  SOURCE_BOOK_LARIAN_AFBG3,
  SOURCE_BOOK_LOWDER_PoL,
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_JERGAL,
} = require('./../../../../godIdList')
const {
  Istishia_Jergal_Gwaeron_Hoar_Milil_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Jergal_Symbol_SCAG_img = require('./../../../../../images/gods/jergal-symbol-SCAG.png')
const Jergal_Symbol_DND3_FnP_img = require('./../../../../../images/gods/jergal-symbol-DND3_FnP.webp')
const Jergal_BOOK_LOWDER_PoL_img = require('./../../../../../images/gods/jergal_prince_of_lies.webp')
const Jergal_BOOK_LARIAN_AFBG3_img = require('./../../../../../images/gods/jergal_larian_afbg3.webp')

module.exports = {
  [GOD_JERGAL]: [
    {
      src: Jergal_BOOK_LOWDER_PoL_img,
      text: 'Джергал',
      source: {
        id: SOURCE_BOOK_LOWDER_PoL,
      },
    },
    {
      src: Jergal_BOOK_LARIAN_AFBG3_img,
      text: 'Джергал',
      source: {
        id: SOURCE_BOOK_LARIAN_AFBG3,
      },
    },
    {
      src: Jergal_Symbol_SCAG_img,
      text: 'Символ Джергала',
      source: {
        id: SOURCE_SCAG,
        page: 31,
      },
    },
    {
      src: Jergal_Symbol_DND3_FnP_img,
      text: 'Символ Джергала',
      source: {
        id: SOURCE_DND3_FnP,
        page: 99,
      },
    },
    Istishia_Jergal_Gwaeron_Hoar_Milil_DND3_FnP_data,
  ],
}
