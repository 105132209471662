import React from 'react'
import PropTypes from 'prop-types'

import ImageList from '@/components/ImageList'

import godImageCollection from '@/constants/images/godImageCollection'
import {godCollection} from '@/constants/godList'

const GodImageListContainer = ({ id }) => {
  if (id) {
    const god = godCollection[id]
    const imageList = godImageCollection[id]

    if (imageList) {
      return (
        <ImageList
          imageList={imageList}
          {...god}
        />
      )
    }

    return null
  }

  return null
}

GodImageListContainer.propTypes = {
  id: PropTypes.string.isRequired,
}

export default GodImageListContainer
