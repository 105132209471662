const goddessOfWisdom = require('./../constants/goddessOfWisdom')
const {ALIGNMENT_CG} = require('./../../aligmentList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_SEHANINE_MOONBOW} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  DOMAIN_KNOWLEDGE,
  DOMAIN_UNITY,
} = require('./../../domainList')

module.exports = {
  id: GOD_SEHANINE_MOONBOW,
  nameEn: 'Sehanine Moonbow',
  nameShort: {
    nominative: `Сеанин Лунная Радуга`,
    genitive: `Сеанин Лунной Радуги`,
    dative: `Сеанин Лунной Радуге`,
    accusative: `Сеанин Лунную Радугу`,
    instrumental: `Сеанин Лунной Радугой`,
    prepositional: `Сеанин Лунной Радуге`,
  },
  worshipperRace: `эльфов`,
  godOfWhat: [
    `луны`,
    `прорицания`,
    `мечтаний`,
    `путешествий`,
    `смерти`,
    `загадок жизни`,
    `мистицизма`,
    `пророчеств`,
    `сновидений`,
  ],
  nameAlt: `Тайна Лунного Света`,
  description: goddessOfWisdom,
  alignmentId: ALIGNMENT_CG,
  symbolList: [
    `полумесяц`,
    `полная луна под лунной радугой`,
  ],
  domainIdList: [DOMAIN_KNOWLEDGE, DOMAIN_UNITY],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 297,
    },
    {
      id: SOURCE_SCAG,
      page: 24,
    },
  ],
}
