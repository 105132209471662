const {ALIGNMENT_CN} = require('./../../aligmentList')
const {DOMAIN_TRICKERY} = require('./../../domainList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_LEIRA} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_LEIRA,
  nameEn: 'Leira',
  nameShort: {
    nominative: 'Лейра',
    genitive: 'Лейры',
    dative: 'Лейре',
    accusative: 'Лейру',
    instrumental: 'Лейрой',
    prepositional: 'Лейре',
  },
  godOfWhat: [
    `иллюзий`,
  ],
  nameAlt: [
    `Леди Туманов`,
    `Дымчатая Тень`,
    `Леди Обмана`,
  ],
  description: [
    {
      header: `Лейра`,
      text: `У Лейры было много масок, и не однажды она считалась мертвой и была принята за другое божество. Возможно, подобная слава только приличествует богине иллюзий и обмана. Как бы то ни было её служители сходятся в одном — Леди обожает этот хаос. Даже служители Цирика думали, что их божество покончило с Лейрой, но среди них, сейчас, ходит странная мысль, что она — его дочь.

Лейра не считается пагубным божеством или злым шутом, скорее, как — таинственной, скрытной и уединённой. К её заслугам причисляют изобретение Руатлека — языка иллюзионистов и устный язык Нимбрала.

Служителей у Лейры вроде бы немного, но сказать точно — трудно. Потому что ищущие ее благословения обычно не проявляют своих намерений. Лейра — покровительница лжецов и иллюзионистов. Боготворений в ее честь немного, и обычно это жаждущие силы заклинатели или плуты — для которых она своего рода защитник и герой. Большинство людей молятся ей, чтобы скрыть что-то, утаить, или милостивят её при важном выборе, боясь быть обманутыми. Некоторые крутят пальцем за спиной, прежде чем солгать, полагаясь на ее поддержку.

Жрецы носят одеяния белого и дымчато-серого цвета, а лица покрыты гладкими, бесформенными масками. Храмы, посвященные ей есть только в Нимбрале, а святыни, разбросанные по континенту, маскируются под что-то иное, и носят знаки, узнаваемые лишь верующими.`,
      source: {
        id: SOURCE_SCAG,
        page: 33,
      },
    },
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_Лейра, матушка лжи и тумана,_

_Может спрятать обман за обманом._

_Её имя — удел_

_Мастеров тайных дел_

_И от зоркого глаза охрана._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_CN,
  symbolList: 'Направленный вниз треугольник с вихрем тумана',
  domainIdList: DOMAIN_TRICKERY,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 33,
    },
  ],
}
