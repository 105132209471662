const {ALIGNMENT_CG} = require('./../../aligmentList')
const {GENDER_FEMALE} = require('./../../genderList')
const {
  GOD_SELUNE,
  GOD_SHAR,
} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const seluneAndSharList = require('./../constants/seluneAndSharList')
const seluneAndSharNote = require('./../constants/seluneAndSharNote')
const homePray = require('./../constants/homePray')
const {
  DOMAIN_KNOWLEDGE,
  DOMAIN_LIFE,
  DOMAIN_TWILIGHT,
} = require('./../../domainList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_SELUNE,
  nameEn: 'Selûne',
  nameShort: {
    nominative: 'Селунэ',
    genitive: 'Селунэ',
    dative: 'Селунэ',
    accusative: 'Селунэ',
    instrumental: 'Селунэ',
    prepositional: 'Селунэ',
  },
  godOfWhat: [
    `луны`,
    `звёзд`,
    `навигации`,
    `материнства`,
    `репродуктивных циклов`,
  ],
  nameAlt: [
    `Наша Леди Серебра`,
    `Лунная Дева`,
    `Ночная Белая Леди`,
    `Серебряная Владычица`,
  ],
  description: [
    {
      header: 'Селунэ',
      text: `Селунэ считается одним из древнейших божеств Фаэруна. Большинство людей Фаэруна воспринимают луну в небе буквально — как богиню, смотрящую с небес на мир, а следующие за ней огоньки — как её слезы. Она также богиня звёзд и навигации, материнства и репродуктивных циклов. Она рассматривается как спокойная сила, часто почитаемая не только людскими женщинами, но и прочим народом: штурманами и моряками, теми, кто честно работают по ночам, и теми, кто ищет защиты во тьме, заблудшими и ищущими.
  
  Существует множество легенд про Селунэ, главной из которых является сказание о сражении на заре времён между ней и её сестрой [Шар](GOD:${GOD_SHAR}). Слёзы Селунэ — скопление звёздных огней, следующих за луной по небу, которые, как считается, вызваны радостью богини, её горем или и тем, и другим вместе.
  
  Молоко, символ материнства, используется во многих ритуалах, исполняемых последователями Селунэ, также, как и медитация и транс. Те, кто избрал её своим божеством, обычно выставляют плошку молока за порог каждую ночь полнолуния.`,
      source: {
        id: SOURCE_SCAG,
        page: 37,
      },
    },
    {
      header: `Нити серебра`,
      text: `
О Селунэ, прекрасная дева!

Сплети наши сердца из серебряных нитей,

Веди нас за светом луны,

И утоли нашу жажду чистейшими слезами.



Тени дразнят нас. Услышь нашу молитву!

Тени преследуют нас. Услышь нашу молитву!

Тени ранят нас. Услышь нашу молитву!



О, Селунэ, стоящая за лучистым ткацким станком,

Залатай наши сердца серебряными нитями,

Исцели нас каплями утреней росы,

И успокой наши души мягчайшим сиянием звёзд.

— Селунитский молитвенник`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Селунэ`,
      text: `Серебряная Владычица властвует над луной, звёздами и мореплаванием. На небесах с нею постоянно соперничает её сестра [Шар](GOD:${GOD_SHAR}), стремящаяся погрузить мир в вечную тьму.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_Светлый путь нам дарует Селунэ_

_Звёзды капают с глаз её лунных._

_Хочет [злая сестра](GOD:${GOD_SHAR})_

_Погасить свет добра,_

_Но не дрогнут души нашей струны._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    homePray,
    ...seluneAndSharList,
  ],
  note: seluneAndSharNote,
  alignmentId: ALIGNMENT_CG,
  symbolList: 'Глаза, окружённые семью звёздами',
  domainIdList: [DOMAIN_LIFE, DOMAIN_KNOWLEDGE, DOMAIN_TWILIGHT],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 37,
    },
  ],
}
