const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_MALAR,
} = require('./../../../../godIdList')

const Malar_Symbol_SCAG_img = require('./../../../../../images/gods/malar-symbol-SCAG.png')
const Malar_Symbol_DND3_FnP_img = require('./../../../../../images/gods/malar-symbol-DND3_FnP.webp')
const Malar_DND3_FnP_img = require('./../../../../../images/gods/malar-DND3_FnP.webp')

module.exports = {
  [GOD_MALAR]: [
    {
      src: Malar_Symbol_SCAG_img,
      text: 'Символ Малара',
      source: {
        id: SOURCE_SCAG,
        page: 34,
      },
    },
    {
      src: Malar_Symbol_DND3_FnP_img,
      text: 'Символ Малара',
      source: {
        id: SOURCE_DND3_FnP,
        page: 44,
      },
    },
    {
      src: Malar_DND3_FnP_img,
      text: 'Малар, Лорд Зверей',
      source: {
        id: SOURCE_DND3_FnP,
        page: 45,
      },
    },
  ],
}
