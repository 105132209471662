const natureGods = require('./../constants/natureGods')
const {ALIGNMENT_CG} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_DEEP_SASHELAS} = require('./../../godIdList')
const {PANTHEON_SELDARINE} = require('./../../pantheonList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  DOMAIN_NATURE,
  DOMAIN_TEMPEST,
} = require('./../../domainList')

module.exports = {
  id: GOD_DEEP_SASHELAS,
  nameEn: 'Deep Sashelas',
  nameShort: {
    nominative: `Сашелас Глубинный`,
    genitive: `Сашеласа Глубинного`,
    dative: `Сашеласу Глубинному`,
    accusative: `Сашеласа Глубинного`,
    instrumental: `Сашеласом Глубинным`,
    prepositional: `Сашеласе Глубинном`,
  },
  godOfWhat: [
    `моря`,
    `морских эльфов`,
    `дельфинов`,
  ],
  alignmentId: ALIGNMENT_CG,
  symbolList: `Дельфин`,
  description: natureGods,
  domainIdList: [DOMAIN_TEMPEST, DOMAIN_NATURE],
  pantheonId: PANTHEON_SELDARINE,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 297,
    },
    {
      id: SOURCE_SCAG,
      page: 24,
    },
  ],
}
