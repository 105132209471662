const {ALIGNMENT_CN} = require('./../../aligmentList')
const {DOMAIN_TRICKERY} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_MASK} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_MASK,
  nameEn: 'Mask',
  nameShort: {
    nominative: 'Маск',
    genitive: 'Маска',
    dative: 'Маску',
    accusative: 'Маска',
    instrumental: 'Маском',
    prepositional: 'Маске',
  },
  godOfWhat: [
    `воров`,
  ],
  nameAlt: [
    `Лорд Теней`,
    `Мастер Воров`,
  ],
  description: `Маск — бог-мошенник. Покровитель повес, шпионов и воров. Все, что в тенях — во владениях Маска. Молитвы ему возносятся перед грабежом или началом какой-либо интриги. Придворные и дипломаты поминают божество в надеждах на удачные переговоры.

Чтящие Маска обычно склонны воровать, будь то грабёж, кража или уличное мошенничество. Простой люд молится ему, чтобы избежать воровства. Есть и «предусмотрительные» — они носят при себе «кошель Маска», небольшой, простой мешочек, носимый напоказ — такой легко срезать и украсть. В нём — небольшое подаяние в несколько монет. По обычаю, вор «снимает» такой мешочек, если встречает иного с ним, и принимает это как подарок (от божества). Потерявший мешочек благодарит Лорда за то, что тот принял воздаяние — небольшую часть имущества. И, конечно же, никто не зарпещает другому вору обворовать человека, уже лишившегося кошеля Маска. Но это считается вполне заслуженным — навлечь недовольство Маска, если уж позволяешь обокрасть себя несколько раз за одну прогулку.

Жрецы Маска обычно воры по профессии, и часто находятся в верхушке криминального мира, синдикатов. Их зовут демархами или демархеями, и они носят маски-вуали во время служения.`,
  alignmentId: ALIGNMENT_CN,
  symbolList: 'Чёрная маска',
  domainIdList: DOMAIN_TRICKERY,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 34,
    },
  ],
}
