const {ALIGNMENT_N} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_TOA} = require('./../../sourceList')
const {
  DOMAIN_NATURE,
  DOMAIN_PROTECTION,
} = require('./../../domainList')
const {
  GOD_GOND,
  GOD_SAVRAS,
  GOD_SUNE,
  GOD_TYMORA,
  GOD_UBTAO,
  GOD_WAUKEEN,
} = require('./../../godIdList')

module.exports = {
  id: GOD_UBTAO,
  nameEn: 'Ubtao',
  nameShort: {
    nominative: `Убтао`,
    genitive: `Убтао`,
    dative: `Убтао`,
    accusative: `Убтао`,
    instrumental: `Убтао`,
    prepositional: `Убтао`,
  },
  worshipperRace: 'чультов',
  godOfWhat: [
    `лабиринтов`,
    `динозавров`,
    `судьбы`,
  ],
  nameAlt: [
    'Создатель Чульта',
    'Основатель Мезро',
    'Отец динозавров',
  ],
  description: [
    `Центральную роль в культуре Чульта играет бог Убтао, долго защищавший эти земли и чультскую цивилизацию. Однако Убтао взращивал ненависть к тем, кто поклонялись ему, из-за из бесконечных войн и постоянных просьбах решать все их проблемы. Более 100 лет назад Убтао попросту бросил Чульт, а его присутствие больше не чувствовалось там. Когда он ушёл, чульты получили сильный духовный удар, но в конце концов воинствующие племена объединились в единый народ. По сей день динозавры, по мнению чультов, считаются потомками Убтао, даже несмотря на то, что сам Убтао больше не популярен.`,
    {
      header: 'Боги Чульта',
      text: `После того, как Убтао ушёл, чульты обратились к другим божествам, включая тех, которых принесли им миссионеры других цивилизаций. [Вокин](GOD:${GOD_WAUKEEN}), богине торговли, поклоняются многие торговцы Порта Нянзару. Другие храмы, которые можно найти в городе, посвящены [Гонду](GOD:${GOD_GOND}) (богу ремёсел), [Саврасу](GOD:${GOD_SAVRAS}) (богу предсказаний и судеб), [Сьюни](GOD:${GOD_SUNE}) (богине любви и красоты) и [Тиморе](GOD:${GOD_TYMORA}) (богине удачи).

Однако, помимо распространения своей веры, миссионеры из внешнего мира прибыли в Чульт за богатством, что привело ко многим конфликтам. Храмы и святыни их богов все ещё остались, но большая часть духовенства представлена жителями Чульта. Жрецы из внешнего мира, пропагандирующие свою религию чересчур активно, не приветствуются в Порту Нянзару.`,
      source: {
        id: SOURCE_TOA,
        page: 16,
      },
    },
  ],
  alignmentId: ALIGNMENT_N,
  symbolList: 'Лабиринт',
  domainIdList: [DOMAIN_NATURE, DOMAIN_PROTECTION],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_TOA,
    page: 16,
  },
}
