const ladiesOfTheGoldenHills = require('./../constants/ladiesOfTheGoldenHills')
const shadowAndStone = require('./../constants/shadowAndStone')
const {ALIGNMENT_N} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_CALLARDURAN_SMOOTHHANDS} = require('./../../godIdList')
const {PANTHEON_GNOME} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  DOMAIN_KNOWLEDGE,
  DOMAIN_NATURE,
} = require('./../../domainList')

module.exports = {
  id: GOD_CALLARDURAN_SMOOTHHANDS,
  nameEn: 'Callarduran Smoothhand',
  nameShort: {
    nominative: `Каллардюран`,
    genitive: `Каллардюрана`,
    dative: `Каллардюрану`,
    accusative: `Каллардюрана`,
    instrumental: `Каллардюраном`,
    prepositional: `Каллардюране`,
  },
  godOfWhat: [
    `шахтёров`,
    `каменотёсов`,
    `камня`,
    `Подземья`,
  ],
  nameAlt: 'Гладкорукий',
  description: [
    shadowAndStone,
    ladiesOfTheGoldenHills,
  ],
  alignmentId: ALIGNMENT_N,
  symbolList: `золотое кольцо-печатка с шестиконечной звездой`,
  domainIdList: [DOMAIN_KNOWLEDGE, DOMAIN_NATURE],
  pantheonId: PANTHEON_GNOME,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 25,
  },
}
