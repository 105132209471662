const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_CLANGEDDIN,
} = require('./../../../../godIdList')
const {
  Abbathor_Berronar_Clangeddin_Duerra_Dumathoin_Dugmaren_Gorm_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Clangeddin_Symbol_DND3_FnP_img = require('./../../../../../images/gods/clangeddin-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_CLANGEDDIN]: [
    {
      src: Clangeddin_Symbol_DND3_FnP_img,
      text: 'Символ Клангеддина Сребробородого',
      source: {
        id: SOURCE_DND3_FnP,
        page: 117,
      },
    },
    Abbathor_Berronar_Clangeddin_Duerra_Dumathoin_Dugmaren_Gorm_DND3_FnP_data,
  ],
}
