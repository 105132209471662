const tichLegend = require('./../constants/tichLegend')
const {ALIGNMENT_CG} = require('./../../aligmentList')
const {DOMAIN_TRICKERY} = require('./../../domainList')
const {GENDER_FEMALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {PC_RACE_HALFLING} = require('./../../pcRaceIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GOD_BESHABA,
  GOD_TYMORA,
} = require('./../../godIdList')

module.exports = {
  id: GOD_TYMORA,
  nameEn: 'Tymora',
  nameShort: {
    nominative: 'Тимора',
    genitive: 'Тиморы',
    dative: 'Тиморе',
    accusative: 'Тимору',
    instrumental: 'Тиморой',
    prepositional: 'Тиморе',
  },
  godOfWhat: [
    `удачи`,
  ],
  nameAlt: [
    `Леди Удачи`,
    `Наша Улыбающаяся Леди`,
  ],
  description: [
    `Тимора, светлоликая богиня удачи, которой возносят молитвы картёжники и игроки Фаэруна. Говорят, что Наша Улыбающаяся Леди никого не любит так, как тех, кто играет дерзко и с наивысшим мастерством. Тем не менее, считается, что она приглядывает за каждым, кто ставит свое будущее на кон.

Боевой клич последователей Тиморы — «Фортуна любит смелых». Хвала Тиморе уместна в любом случае, когда немного удачи помогли бы, но не тогда, когда страшатся неудач. (В таких случаях следует молиться [Бешабе](GOD:${GOD_BESHABA}), чтобы избавиться от невезения; молитвы же обоим, лишь только разгневают богинь.) Обычно, гадают на будущее, бросая монеты незнакомцам (как правило, нищим) и спрашивая, не выпал ли орёл. Если выпал орёл, монета остается незнакомцу в качестве оплаты за благосклонность Тиморы. Если выпала решка, незнакомец может выбрать, сохранить монету (и невезение с ней) или вернуть её.

Почитающие Тимору — в отличие от народа, который поминает её лишь бросая кубики — чаще всего дерзкие по своей природе. Искатели приключений и игроки составляют большинство среди них. Они полагают, что всё хорошее, что есть в их жизни происходит от удачи и смелости, с которой они готовы рисковать. Последователи Тиморы есть среди всех слоёв населения: от молодого лихого аристократа, до идущего на риск купца; от мечтательного трудяги, до коварного бездельника.

Жрецы Тиморы и храмы в честь Леди Удачи редки, так как её вера не склонна к созданию посредников: _«Пусть везунчик и Леди Удача разбираются сами»_, гласит старая пословица. Святилища Тиморы в игорных домах, впрочем, не столь необычны и, иногда, когда там появляется жрец, святилище, по сути, становится храмом.`,
    tichLegend,
    {
      header: 'Леди Удача',
      text: `Множество [полуросликов](PC_RACE:${PC_RACE_HALFLING}) постоянно поклоняются Тиморе, видя в ней руку помощи в их удачах и покровительницу везения, ассоциируемого с хин.`,
      source: {
        id: SOURCE_SCAG,
        page: 111,
      },
    },
    {
      header: `Тимора`,
      text: `Светлоликая богиня удачи, благоволящая самым завзятым игрокам и опытным приключенцам`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_CG,
  symbolList: 'Монета, решкой вверх',
  domainIdList: DOMAIN_TRICKERY,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 40,
    },
  ],
}
