const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_SHARGAAS,
} = require('./../../../../godIdList')
const {
  Bahgtru_Ilneval_Gruumsh_Luthic_Shargaas_Yurtrus_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Shargaas_DND3_FnP_img = require('./../../../../../images/gods/shargaas-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_SHARGAAS]: [
    {
      src: Shargaas_DND3_FnP_img,
      text: 'Символ Шаргааса',
      source: {
        id: SOURCE_DND3_FnP,
        page: 152,
      },
    },
    Bahgtru_Ilneval_Gruumsh_Luthic_Shargaas_Yurtrus_DND3_FnP_data,
  ],
}
