const {ALIGNMENT_LE} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_SURTUR} = require('./../../godIdList')
const {PANTHEON_GIANT} = require('./../../pantheonList')
const {SOURCE_PHB} = require('./../../sourceList')
const {
  DOMAIN_KNOWLEDGE,
  DOMAIN_WAR,
} = require('./../../domainList')
const {
  godSurturOnlyDescriptionList,
  giantGodsCommonDescriptionList,
} = require('./../../textCommonParts')

module.exports = {
  id: GOD_SURTUR,
  nameEn: 'Surtur',
  nameShort: {
    nominative: `Сурт`,
    genitive: `Сурта`,
    dative: `Сурту`,
    accusative: `Сурта`,
    instrumental: `Суртом`,
    prepositional: `Сурте`,
  },
  worshipperRace: `огненных великанов`,
  godOfWhat: [
    `ремёсел`,
  ],
  description: [
    ...godSurturOnlyDescriptionList,
    ...giantGodsCommonDescriptionList,
  ],
  alignmentId: ALIGNMENT_LE,
  symbolList: `Пламенеющий меч`,
  domainIdList: [DOMAIN_WAR, DOMAIN_KNOWLEDGE],
  pantheonId: PANTHEON_GIANT,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PHB,
    page: 297,
  },
}
