const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_LOVIATAR,
} = require('./../../../../godIdList')

const Loviatar_Symbol_SCAG_img = require('./../../../../../images/gods/loviatar-symbol-SCAG.png')
const Loviatar_Symbol_DND3_FnP_img = require('./../../../../../images/gods/loviatar-symbol-DND3_FnP.webp')
const Loviatar_DND3_FnP_img = require('./../../../../../images/gods/loviatar-DND3_FnP.webp')

module.exports = {
  [GOD_LOVIATAR]: [
    {
      src: Loviatar_Symbol_SCAG_img,
      text: 'Символ Ловиатар',
      source: {
        id: SOURCE_SCAG,
        page: 34,
      },
    },
    {
      src: Loviatar_Symbol_DND3_FnP_img,
      text: 'Символ Ловиатар',
      source: {
        id: SOURCE_DND3_FnP,
        page: 101,
      },
    },
    {
      src: Loviatar_DND3_FnP_img,
      text: 'Ловиатар, Госпожа Мучений',
      source: {
        id: SOURCE_DND3_FnP,
        page: 101,
      },
    },
  ],
}
