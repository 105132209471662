const blackAndWhite = require('./../constants/blackAndWhite')
const {ALIGNMENT_NE} = require('./../../aligmentList')
const {DOMAIN_TRICKERY} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_SHARGAAS} = require('./../../godIdList')
const {PANTHEON_ORC} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_SHARGAAS,
  nameEn: 'Shargaas',
  nameShort: {
    nominative: `Шаргаас`,
    genitive: `Шаргааса`,
    dative: `Шаргаасу`,
    accusative: `Шаргааса`,
    instrumental: `Шаргаасом`,
    prepositional: `Шаргаасе`,
  },
  godOfWhat: [
    `скрытности`,
    `темноты`,
    `тьмы`,
    `ночи`,
  ],
  nameAlt: 'Ночной Лорд',
  description: blackAndWhite,
  alignmentId: ALIGNMENT_NE,
  symbolList: `красный полумесяц с черепом между рогами`,
  domainIdList: DOMAIN_TRICKERY,
  pantheonId: PANTHEON_ORC,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 25,
  },
}
