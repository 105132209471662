const fatherOfForgeAndRespectedMother = require('./../constants/fatherOfForgeAndRespectedMother')
const {ALIGNMENT_LG} = require('./../../aligmentList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_BERRONAR} = require('./../../godIdList')
const {PANTHEON_MORNDINSAMMAN} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  DOMAIN_LIFE,
  DOMAIN_LIGHT,
  DOMAIN_PEACE,
  DOMAIN_UNITY,
} = require('./../../domainList')

module.exports = {
  id: GOD_BERRONAR,
  nameEn: 'Berronar Truesilver',
  nameShort: {
    nominative: `Берронар Истинно Серебряная`,
    genitive: `Берронар Истинно Серебряной`,
    dative: `Берронар Истинно Серебряной`,
    accusative: `Берронар Истинно Серебряную`,
    instrumental: `Берронар Истинно Серебряной`,
    prepositional: `Берронар Истинно Серебряной`,
  },
  godOfWhat: [
    `дома`,
    `очага`,
  ],
  nameAlt: 'Уважаемая Мать',
  description: fatherOfForgeAndRespectedMother,
  alignmentId: ALIGNMENT_LG,
  symbolList: 'Переплетённые серебряные кольца',
  domainIdList: [DOMAIN_LIGHT, DOMAIN_LIFE, DOMAIN_UNITY, DOMAIN_PEACE],
  pantheonId: PANTHEON_MORNDINSAMMAN,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_SCAG,
    page: 23,
  },
}
