const {SOURCE_SCAG} = require('./../../sourceList')
const {PC_RACE_HALFLING} = require('./../../pcRaceIdList')
const {
  GOD_SHEELA_PERYROYL,
  GOD_YONDALLA,
} = require('./../../godIdList')

module.exports = {
  header: 'Благословенные сёстры',
  text: `Богиней-матерью и главой пантеона [хин](PC_RACE:${PC_RACE_HALFLING}) является [Йондалла Благословенная](GOD:${GOD_YONDALLA}), богиня щедрости и плодородия, защитница тепла, дома и семьи.

[Шила Периройл](PC_RACE:${GOD_SHEELA_PERYROYL}), Зелёная Сестра Йондаллы — богиня природы, леди полей, равнин, ручьёв, долин и погоды в таких местах. Она также и богиня любви, песен и танцев`,
  source: {
    id: SOURCE_SCAG,
    page: 110,
  },
}
