const {
  SOURCE_DND3_FnP,
  SOURCE_MTG_CLB,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_BANE,
} = require('./../../../../godIdList')

const Bane_Symbol_SCAG_img = require('./../../../../../images/gods/bane-symbol-SCAG.webp')
const Bane_Symbol_DND3_FnP_img = require('./../../../../../images/gods/bane-symbol-DND3_FnP.webp')
const Bane_DND3_FnP_img = require('./../../../../../images/gods/bane-DND3_FnP.webp')
const Bane_lord_of_darkness_MTG_CLB_img = require('./../../../../../images/gods/bane_lord_of_darkness.jpg')
const Bane_lord_of_darkness_2_MTG_CLB_img = require('./../../../../../images/gods/bane_lord_of_darkness_2.webp')
const Bane_s_contingency_MTG_CLB_img = require('./../../../../../images/gods/bane-s-contingency.webp')

module.exports = {
  [GOD_BANE]: [
    {
      src: Bane_Symbol_SCAG_img,
      text: 'Символ Бэйна',
      source: {
        id: SOURCE_SCAG,
        page: 27,
      },
    },
    {
      src: Bane_Symbol_DND3_FnP_img,
      text: 'Символ Бэйна во время Смутного времени',
      source: {
        id: SOURCE_DND3_FnP,
        page: 16,
      },
    },
    {
      src: Bane_lord_of_darkness_MTG_CLB_img,
      text: 'Бэйн, Повелитель Тьмы',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
    {
      src: Bane_lord_of_darkness_2_MTG_CLB_img,
      text: 'Бэйн, Повелитель Тьмы',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
    {
      src: Bane_DND3_FnP_img,
      text: 'Бэйн, Повелитель Тьмы',
      source: {
        id: SOURCE_DND3_FnP,
        page: 17,
      },
    },
    {
      src: Bane_s_contingency_MTG_CLB_img,
      text: 'Бэйн',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
  ],
}
