module.exports = [
  require('./gods/abbathor'),
  require('./gods/aerdrie_faenya'),
  require('./gods/akadi'),
  require('./gods/amaunator'),
  require('./gods/amberli'),
  require('./gods/angharradh'),
  require('./gods/annam'),
  require('./gods/arvoreen'),
  require('./gods/asmodeus'),
  require('./gods/auril'),
  require('./gods/azuth'),
  require('./gods/baervan_wildwanderer'),
  require('./gods/bahamut'),
  require('./gods/bahgtru'),
  require('./gods/bane'),
  require('./gods/baravar_cloakshadow'),
  require('./gods/berronar'),
  require('./gods/beshaba'),
  require('./gods/bhaal'),
  require('./gods/blibdoolpoolp'),
  require('./gods/brandobaris'),
  require('./gods/callarduran_smoothhand'),
  require('./gods/chauntea'),
  require('./gods/clangeddin'),
  require('./gods/corellon_larethian'),
  require('./gods/cyric'),
  require('./gods/cyrrollalee'),
  require('./gods/deep_duerra'),
  require('./gods/deep_sashelas'),
  require('./gods/dendar'),
  require('./gods/deneir'),
  require('./gods/diancastra'),
  require('./gods/dugmaren_brightmantle'),
  require('./gods/dumathoin'),
  require('./gods/eadro'),
  require('./gods/eilistraee'),
  require('./gods/eldath'),
  require('./gods/erevan_ilesere'),
  require('./gods/fenmarel_mestarine'),
  require('./gods/flandal_steelskin'),
  require('./gods/gaerdal_ironhand'),
  require('./gods/garl_glittergold'),
  require('./gods/ghaunadaur'),
  require('./gods/gond'),
  require('./gods/gorm_gulthyn'),
  require('./gods/grolantor'),
  require('./gods/grumbar'),
  require('./gods/gruumsh'),
  require('./gods/gwaeron_windstrom'),
  require('./gods/haela'),
  require('./gods/hanali_celanil'),
  require('./gods/helm'),
  require('./gods/hiatea'),
  require('./gods/hoar'),
  require('./gods/hruggek'),
  require('./gods/iallanis'),
  require('./gods/ilmater'),
  require('./gods/ilneval'),
  require('./gods/imix'),
  require('./gods/istishia'),
  require('./gods/jergal'),
  require('./gods/kelemvor'),
  require('./gods/khurgorbaeyag'),
  require('./gods/kiaransalee'),
  require('./gods/kossuth'),
  require('./gods/kurtulmak'),
  require('./gods/labelas_enoreth'),
  require('./gods/laduguer'),
  require('./gods/laogzed'),
  require('./gods/lathander'),
  require('./gods/leira'),
  require('./gods/lliira'),
  require('./gods/lolth'),
  require('./gods/loviatar'),
  require('./gods/luthic'),
  require('./gods/maglubiyet'),
  require('./gods/malar'),
  require('./gods/marthammor'),
  require('./gods/mask'),
  require('./gods/memnor'),
  require('./gods/merrshaulk'),
  require('./gods/mielikki'),
  require('./gods/milil'),
  require('./gods/moradin'),
  require('./gods/myrkul'),
  require('./gods/mystra'),
  require('./gods/nebelun'),
  require('./gods/oghma'),
  require('./gods/raven_queen'),
  require('./gods/red_knight'),
  require('./gods/rillifane_rallathil'),
  require('./gods/savras'),
  require('./gods/segojan_earthcaller'),
  require('./gods/sehanine_moonbow'),
  require('./gods/sekolah'),
  require('./gods/selune'),
  require('./gods/selvetarm'),
  require('./gods/semuanya'),
  require('./gods/shar'),
  require('./gods/shargaas'),
  require('./gods/sharindlar'),
  require('./gods/sheela_peryroyl'),
  require('./gods/shevarash'),
  require('./gods/silvanus'),
  require('./gods/skerrit'),
  require('./gods/skoraeus_stonebones'),
  require('./gods/solonor_thelandira'),
  require('./gods/sseth'),
  require('./gods/stronmaus'),
  require('./gods/sune'),
  require('./gods/surtur'),
  require('./gods/talona'),
  require('./gods/talos'),
  require('./gods/tempus'),
  require('./gods/thrym'),
  require('./gods/tiamat'),
  require('./gods/torm'),
  require('./gods/tymora'),
  require('./gods/tyr'),
  require('./gods/ubtao'),
  require('./gods/urdlen'),
  require('./gods/urogalan'),
  require('./gods/valkur'),
  require('./gods/vaprak'),
  require('./gods/vergadain'),
  require('./gods/vhaeraun'),
  require('./gods/waukeen'),
  require('./gods/yondalla'),
  require('./gods/yurtrus'),
]
