const {GENDER_FEMALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {DOMAIN_NATURE} = require('./../../domainList')
const {ALIGNMENT_NG} = require('./../../aligmentList')
const {
  PC_CLASS_BARD,
  PC_CLASS_RANGER,
} = require('./../../pcClassIdList')
const {
  GOD_ELDATH,
  GOD_GWAERON_WINDSTROM,
  GOD_MIELIKKI,
  GOD_MILIL,
  GOD_SILVANUS,
} = require('./../../godIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_MIELIKKI,
  nameEn: 'Mielikki',
  nameShort: {
    nominative: 'Майликки',
    genitive: 'Майликки',
    dative: 'Майликки',
    accusative: 'Майликки',
    instrumental: 'Майликки',
    prepositional: 'Майликки',
  },
  godOfWhat: [
    `лесов`,
    `лесных созданий`,
  ],
  nameAlt: [
    `Миликки`,
    `Наша Леди Лесов`,
    `Королева Леса`,
  ],
  description: [
    {
      header: 'Майликки',
      text: `Люди редко поминают Майликки, разве что в тихих лесных чащобах. В лесах полных жизни и волшебства — она в полной силе. Но верят, что Майликки следит за добрыми людьми в любых чащах — неважно сколь тёмных и жестоких. Когда дети пропадают в чащобах, люди молятся Майликки об их защите, пока они не будут найдены.
  
  Майликки — божество лесов и всех живущих в них. Она отдалённый от сует всеобъемлющий дух — менее человечный, чем многие другие божества. Её внимание и расположение сложно привлечь, хоть она и не забывает о людях. Она — покровитель [следопытов](PC_CLASS:${PC_CLASS_RANGER}). Настолько же насколько [Милил](GOD:${GOD_MILIL}) — покровитель [бардов](PC_CLASS:${PC_CLASS_BARD}). Но даже [следопыты](PC_CLASS:${PC_CLASS_RANGER}) редко молятся ей — вместо этого они молятся [Гвейрону Буреветру](GOD:${GOD_GWAERON_WINDSTROM}), надеясь, что он донесет их слова Майликки, выискивая её, где бы она ни была.
  
  Майликки носит символ единорога, который причисляют также и к Лурью, Королеве Единорогов (и их божеству). Неудивительно, что некоторые путают их или объединяют в одно. Но Майликки чаще описывается как красивая девушка, которой Лурью позволяет седлать себя. Они считаются близкими друзьями. С другими божествами у Майликки более неоднозначные отношения. _«[Сильванус](GOD:${GOD_SILVANUS}) вроде бы как её отец»_, — думают некоторые, а [Эльдат](GOD:${GOD_ELDATH}) считается её сестрой. Но у Майликки свой путь, отдельный от других.
  
  Много святынь возведено в её честь, особенно в Диком Порубежье. Они выглядят обычно как усохшее дерево с вырезанным символом Майликки — головой единорога. Также этот символ может быть на древесине, прикрепленной к дереву. Местные знают, что за этими символами лес не стоит рубить (или охотиться). Часто символы оставляют лесорубы по окончанию работы как знак признательности божеству за сохранность и милость.`,
      source: {
        id: SOURCE_SCAG,
        page: 35,
      },
    },
    {
      header: `Миликки`,
      text: `Миликки — богиня лесов и лесных созданий. В глухих областях у неё множество последователей, но за пределами густых лесов она малоизвестна.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_NG,
  symbolList: 'Голова единорога',
  domainIdList: DOMAIN_NATURE,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 35,
    },
  ],
}
