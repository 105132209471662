import React from 'react'
import classNames from 'classnames'

import './SymbolStyles.less'

const SymbolComponent = (
  {
    isSingle,
    list,
    preText,
  },
) => (
  <section
    className={classNames(
      'Symbol',
      {
          'Symbol-single': isSingle,
      },
    )}
  >
    <header className='Symbol_header'>{preText}</header>
    <ul className='Symbol_list'>
      {
        list.map(
          (symbol, i) => (
            <li
              key={i}
              className='Symbol_item'
            >
              {symbol}
            </li>
          ),
        )
      }
    </ul>
  </section>
)

export default SymbolComponent
