const jergalLegendList = require('./../constants/jergalLegendList')
const { myrkulFollowerList } = require('./../../../constants/textCommonParts')
const {ALIGNMENT_NE} = require('./../../aligmentList')
const {DOMAIN_DEATH} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GOD_JERGAL,
  GOD_KELEMVOR,
  GOD_LATHANDER,
  GOD_MYRKUL,
} = require('./../../godIdList')

module.exports = {
  id: GOD_MYRKUL,
  nameEn: 'Myrkul',
  nameShort: {
    nominative: 'Миркул',
    genitive: 'Миркула',
    dative: 'Миркулу',
    accusative: 'Миркула',
    instrumental: 'Миркулом',
    prepositional: 'Миркуле',
  },
  godOfWhat: [
    `старости`,
    `смерти`,
    `трупов`,
    `душ`,
  ],
  nameAlt: [
    `Лорд Костей`,
    `Владыка Костей`,
    `Старый Лорд Черепов`,
    `Жнец`,
  ],
  description: [
    {
      header: `Миркул`,
      text: `Когда [Джергал](GOD:${GOD_JERGAL}) снял с себя полномочия и передал их трём смертным — Миркул был среди них. Он властвовал над мёртвыми, тысячелетиями правил городом мёртвых, и впоследствии был убит. Спустя время, он вернулся, ведь разве может умереть сама смерть? Жрецы Миркула представляют его Жнецом, что собирает души мёртвых и отдаёт их на суд Келемвора.

Миркул — это смерть, разложение, истощение, старость, осень и сумерки. Если [Латандер](GOD:${GOD_LATHANDER}) — сотворение и надежда, то Миркул — завершение и обречённость. Люд не столько молится ему, сколько страшится, винит его в болезнях, несчастьях. Он считается бесстрастным и бесчувственным к своим последователям. Верующие в него холодны, мрачны, отстраненны, одержимы смертью и нежитью. Как и многие последователи [Келемвора](GOD:${GOD_KELEMVOR}) и [Джергала](GOD:${GOD_JERGAL}), жрецы Миркула служат в погребениях и держат имя своего покровителя в секрете.

Святыни Миркула или гравюры его символа встречаются всюду, где погребают людей. Полноценные храмы — очень редки. Как правило это освящённая земля, куда стекаются сотни мёртвых для захоронений, даже если Миркул не был их божеством. Для живых остается мало места, может только небольшая церквушка. Но катакомбы под ней неизмеримы. В самом глубоком и далеко захороненном склепе стоит трон, и на этом троне восседает «страж рока» — сохранившееся тело самого почитаемого служителя в истории храма (чаще это — основатель). Посвящённых приводят в этот склеп преклониться перед ним, а также — провести ночь и день без света и еды, во внутреннем созерцании.`,
      source: {
        id: SOURCE_SCAG,
        page: 36,
      },
    },
    ...jergalLegendList,
    ...myrkulFollowerList,
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_Миркул — бог старости и смерти._

_Хотите верьте иль не верьте —_

_И нищих ждёт, и богачей,_

_И тех, кто всех сейчас живей_

_В конце всей этой круговерти._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_NE,
  symbolList: 'Белый человеческий череп',
  domainIdList: DOMAIN_DEATH,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 36,
    },
  ],
}
