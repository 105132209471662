const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_MIELIKKI,
} = require('./../../../../godIdList')

const Mielikki_Symbol_SCAG_img = require('./../../../../../images/gods/mielikki-symbol-SCAG.png')
const Mielikki_Symbol_DND3_FnP_img = require('./../../../../../images/gods/mielikki-symbol-DND3_FnP.webp')
const Mielikki_DND3_FnP_img = require('./../../../../../images/gods/mielikki-DND3_FnP.jpg')

module.exports = {
  [GOD_MIELIKKI]: [
    {
      src: Mielikki_Symbol_SCAG_img,
      text: 'Символ Майликки',
      source: {
        id: SOURCE_SCAG,
        page: 35,
      },
    },
    {
      src: Mielikki_Symbol_DND3_FnP_img,
      text: 'Символ Майликки',
      source: {
        id: SOURCE_DND3_FnP,
        page: 49,
      },
    },
    {
      src: Mielikki_DND3_FnP_img,
      text: 'Майликки, Королева Леса',
      source: {
        id: SOURCE_DND3_FnP,
        page: 50,
      },
    },
  ],
}
