const {ALIGNMENT_LN} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_HELM} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  DOMAIN_LIFE,
  DOMAIN_LIGHT,
  DOMAIN_PROTECTION,
  DOMAIN_TWILIGHT,
} = require('./../../domainList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_HELM,
  nameEn: 'Helm',
  nameShort: {
    nominative: 'Хельм',
    genitive: 'Хельма',
    dative: 'Хельму',
    accusative: 'Хельма',
    instrumental: 'Хельмом',
    prepositional: 'Хельме',
  },
  godOfWhat: [
    `бдительности`,
    `защиты`,
  ],
  nameAlt: [
    `Страж`,
    `Неспящий Взор`,
    `Бдительный`,
    `Наблюдатель`,
  ],
  description: [
    {
      header: `Хельм`,
      text: `Хельм, бог бдительности и защиты, считается олицетворением стражника, защитника и наблюдателя. Его почитают те, кому требуется оставаться чуткими к опасностям или действиям врагов. Хельм — излюбленное божество среди тех, кто зарабатывает на жизнь, защищая кого-либо или что-либо, например, среди телохранителей, городских стражников, охранников сокровищниц.

Хельм олицетворяет бдительность без принадлежности к добру или злу. В легендах он всегда благороден и держит данное слово, например, он охранял в Смутное Время небесную лестницу, не давая богам вознестись с земли и продолжить творившийся тогда хаос, пока Скрижали Судьбы не были найдены.

Хотя вера Хельма переживала и тёмные времена, поклонение ему никогда по-настоящему не исчезало. Большинство последователей считают, что Хельм никогда не исчезнет окончательно, и последние события — лишь подтверждение этому.

Священники Хельма говорят, что всегда нужно быть бдительным, всегда начеку, готовым к нападению врагов. Терпение, ясный ум и тщательное планирование, помогут одержать победу даже в безвыходной ситуации. Избравшие Хельма стараются быть всегда бдительными, с ясной головой и верными своему слову. Эти черты не обязательно делают их приятными людьми, однако, именно поэтому большинство считают верующих в Хельма негибкими и беспощадными.`,
      source: {
        id: SOURCE_SCAG,
        page: 30,
      },
    },
    {
      header: `Хельм`,
      text: `Хельм Наблюдатель — вечный часовой богов. Он олицетворяет стражей на всех планах мироздания. Более века его культ находился в упадке, но после Второго Раскола был восстановлен.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_LN,
  symbolList: 'Глаз на левой латной рукавице',
  domainIdList: [DOMAIN_LIFE, DOMAIN_LIGHT, DOMAIN_PROTECTION, DOMAIN_TWILIGHT],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 30,
    },
  ],
}
