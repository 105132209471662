const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_SOLONOR_THELANDIRA,
} = require('./../../../../godIdList')
const {
  Hanali_Labelas_Rillifane_Sehanine_Shevarash_Solonor_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Solonor_Symbol_DND3_FnP_img = require('./../../../../../images/gods/solonor-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_SOLONOR_THELANDIRA]: [
    {
      src: Solonor_Symbol_DND3_FnP_img,
      text: 'Символ Солонора Теландира',
      source: {
        id: SOURCE_DND3_FnP,
        page: 132,
      },
    },
    Hanali_Labelas_Rillifane_Sehanine_Shevarash_Solonor_DND3_FnP_data,
  ],
}
