const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_SELUNE,
} = require('./../../../../godIdList')

const Selune_Symbol_SCAG_img = require('./../../../../../images/gods/selune-symbol-SCAG.png')
const Selune_Symbol_DND3_FnP_img = require('./../../../../../images/gods/selune-symbol-DND3_FnP.jpg')
const Selune_DND3_FnP_img = require('./../../../../../images/gods/selune-DND3_FnP.webp')

module.exports = {
  [GOD_SELUNE]: [
    {
      src: Selune_Symbol_SCAG_img,
      text: 'Символ Селунэ',
      source: {
        id: SOURCE_SCAG,
        page: 37,
      },
    },
    {
      src: Selune_Symbol_DND3_FnP_img,
      text: 'Символ Селунэ',
      source: {
        id: SOURCE_DND3_FnP,
        page: 57,
      },
    },
    {
      src: Selune_DND3_FnP_img,
      text: 'Селунэ Лунная Дева, в трёх её автарах',
      source: {
        id: SOURCE_DND3_FnP,
        page: 58,
      },
    },
  ],
}
