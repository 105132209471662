const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_FLANDAL_STEELSKIN,
} = require('./../../../../godIdList')
const {
  Baervan_Baravar_Callarduran_Flandal_Gaerdal_Garl_Segojan_Urdlen_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Flandal_Steelskin_Symbol_DND3_FnP_img = require('./../../../../../images/gods/flandal_steelskin-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_FLANDAL_STEELSKIN]: [
    {
      src: Flandal_Steelskin_Symbol_DND3_FnP_img,
      text: 'Символ Фландала',
      source: {
        id: SOURCE_DND3_FnP,
        page: 135,
      },
    },
    Baervan_Baravar_Callarduran_Flandal_Gaerdal_Garl_Segojan_Urdlen_DND3_FnP_data,
  ],
}
