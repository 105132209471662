const {ALIGNMENT_N} = require('./../../aligmentList')
const {DOMAIN_WAR} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_TEMPUS} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_TEMPUS,
  nameEn: 'Tempus',
  nameShort: {
    nominative: 'Темпус',
    genitive: 'Темпуса',
    dative: 'Темпусу',
    accusative: 'Темпуса',
    instrumental: 'Темпусом',
    prepositional: 'Темпусе',
  },
  godOfWhat: [
    `войны`,
  ],
  nameAlt: [
    `Сокрушитель Врагов`,
    `Владыка Битв`,
  ],
  description: [
    {
      header: `Темпус`,
      text: `Темпус — воинственный бог, заботящийся о храбрости на войне, использующий рукоприкладство вместо слов в улаживании разногласий и поощряющий кровопролитие. Бог войны непостоянен в своих предпочтениях, считая, что так его хаотичная природа помогает всем сторонам в одинаковой мере. Владыка Темпус сегодня может быть благосклонным к одной армии, а завтра к другой — врагу первой. Он может появиться перед битвой в рядах одной из армий, и, если его видят на белой кобыле (Вейрос) — армия победит. Если же он будет на чёрном жеребце (Дейрос), то поражение неизбежно. Наиболее часто его видят правящим обоими конями, с одной ногой в стремени каждого из них, что отражает непредсказуемую природу битв. В таких видениях Темпус всегда крепко сложенный богатырь, вооружённый также, как и те, кто его видит.

Нрав Темпуса может быть и случаен, но с течением столетий его жречество приложило немало усилий, чтобы распространить и обеспечить соблюдение общего кодекса ведения войны — чтобы войну вели по правилам, профессионально и уважали репутацию друг друга. Этот кодекс, называемый Честью Темпуса, ставит целью сделать конфликты короткими, законченными и как можно более безопасными для тех, кто не участвует в них напрямую. Кодекс гласит: вооружай всех, кто в этом нуждается; уважай врага; храбро исполняй свой долг; учи всех сражаться; не вступай в распри. Те, кто отравляют колодцы, заражают поля, убивают мирное население или занимаются пытками во имя войны — являются грешниками.

Поклоняющихся Темпусу легион, и его имя часто слетает с губ солдат. Его духовенство чаще всего тактики, сведущие в искусстве войны. Многие его помазанников не служат в храмах, предпочитая быть боевыми капелланами при армиях и отрядах наёмников, вдохновляя товарищей и словом, и сталью. Жрецы Темпуса учат, что война должна вестись с честью, ставя в одинаковые условия обе стороны, так что в любой битве смертный может быть как убит, так и превознесен и стать великим лидером для его соратников. Смертные не должны бояться войны, но должны воспринимать её как ещё одну силу природы — шторм, который цивилизация несёт одним лишь своим существованием.`,
      source: {
        id: SOURCE_SCAG,
        page: 22,
      },
    },
    {
      header: `Темпус`,
      text: `Темпус — Владыка Битв, бог войны и покровитель солдат. Это божество олицетворяет честный бой и осуждает ненужное кровопролитие.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  note: 'Во имя Темпуса, Владыки Битв, ты умрёшь с честью в праведном огне',
  alignmentId: ALIGNMENT_N,
  symbolList: 'Вертикальный пламенеющий меч',
  domainIdList: DOMAIN_WAR,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 39,
    },
  ],
}
