const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_GWAERON_WINDSTROM,
} = require('./../../../../godIdList')
const {
  Istishia_Jergal_Gwaeron_Hoar_Milil_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Gwaeron_Windstrom_Symbol_DND3_FnP_img = require('./../../../../../images/gods/gwaeron_windstrom-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_GWAERON_WINDSTROM]: [
    {
      src: Gwaeron_Windstrom_Symbol_DND3_FnP_img,
      text: 'Символ Гвейрона Буреветра',
      source: {
        id: SOURCE_DND3_FnP,
        page: 97,
      },
    },
    Istishia_Jergal_Gwaeron_Hoar_Milil_DND3_FnP_data,
  ],
}
