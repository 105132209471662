const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_HANALI_CELANIL,
} = require('./../../../../godIdList')
const {
  Hanali_Labelas_Rillifane_Sehanine_Shevarash_Solonor_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Hanali_Celanil_Symbol_DND3_FnP_img = require('./../../../../../images/gods/hanali_celanil-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_HANALI_CELANIL]: [
    {
      src: Hanali_Celanil_Symbol_DND3_FnP_img,
      text: 'Символ Ханали Селанил',
      source: {
        id: SOURCE_DND3_FnP,
        page: 129,
      },
    },
    Hanali_Labelas_Rillifane_Sehanine_Shevarash_Solonor_DND3_FnP_data,
  ],
}
