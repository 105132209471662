const {SOURCE_SCAG} = require('./../../sourceList')
const {
  GOD_ARVOREEN,
  GOD_CYRROLLALEE,
} = require('./../../godIdList')

module.exports = {
  header: 'Хранители дома',
  text: `[Цирроллали](GOD:${GOD_CYRROLLALEE}) — богиня уюта и гостеприимства, доверия и ремёсел.

[Арворин](GOD:${GOD_ARVOREEN}) — бог-защитник, бдительный охранник, жертвующий личным комфортом в пользу безопасности остальных.`,
  source: {
    id: SOURCE_SCAG,
    page: 111,
  },
}
