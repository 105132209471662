const elvenDarkGods = require('./../constants/elvenDarkGods')
const {ALIGNMENT_CG} = require('./../../aligmentList')
const {DOMAIN_WAR} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_SHEVARASH} = require('./../../godIdList')
const {PANTHEON_SELDARINE} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_SHEVARASH,
  nameEn: 'Shevarash',
  nameShort: {
    nominative: `Шевараш`,
    genitive: `Шевараша`,
    dative: `Шеварашу`,
    accusative: `Шевараша`,
    instrumental: `Шеварашом`,
    prepositional: `Шевараше`,
  },
  godOfWhat: [
    `возмездия`,
    `обиженных`,
    `обозлённых`,
  ],
  alignmentId: ALIGNMENT_CG,
  description: elvenDarkGods,
  symbolList: `сломанная стрела над слезой`,
  domainIdList: DOMAIN_WAR,
  pantheonId: PANTHEON_SELDARINE,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 24,
  },
}
