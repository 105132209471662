const godsOfFarPlaces = require('./../constants/godsOfFarPlaces')
const {ALIGNMENT_NG} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_MARTHAMMOR} = require('./../../godIdList')
const {PANTHEON_MORNDINSAMMAN} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  DOMAIN_NATURE,
  DOMAIN_TRICKERY,
} = require('./../../domainList')

module.exports = {
  id: GOD_MARTHAMMOR,
  nameEn: 'Marthammor Duin',
  nameShort: {
    nominative: `Мартаммор Дьюин`,
    genitive: `Мартаммора Дьюина`,
    dative: `Мартаммору Дьюину`,
    accusative: `Мартаммора Дьюина`,
    instrumental: `Мартаммором Дьюином`,
    prepositional: `Мартамморе Дьюине`,
  },
  godOfWhat: [
    `странников`,
    `путешественников`,
    `экспатриантов`,
    `проводников`,
    `дорог`,
    `молнии`,
  ],
  alignmentId: ALIGNMENT_NG,
  symbolList: 'Булава, обращенная вверх, на фоне высокого сапога',
  description: godsOfFarPlaces,
  domainIdList: [DOMAIN_NATURE, DOMAIN_TRICKERY],
  pantheonId: PANTHEON_MORNDINSAMMAN,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_SCAG,
      page: 23,
    },
    {
      id: SOURCE_SCAG,
      page: 106,
    },
  ],
}
