const {ALIGNMENT_NE} = require('./../../aligmentList')
const {GENDER_FEMALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const seluneAndSharList = require('./../constants/seluneAndSharList')
const seluneAndSharNote = require('./../constants/seluneAndSharNote')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GOD_SELUNE,
  GOD_KELEMVOR,
  GOD_SHAR,
} = require('./../../godIdList')
const {
  DOMAIN_DEATH,
  DOMAIN_TRICKERY,
} = require('./../../domainList')

module.exports = {
  id: GOD_SHAR,
  nameEn: 'Shar',
  nameShort: {
    nominative: 'Шар',
    genitive: 'Шар',
    dative: 'Шар',
    accusative: 'Шар',
    instrumental: 'Шар',
    prepositional: 'Шар',
  },
  godOfWhat: [
    `тьмы`,
    `утрат`,
    `ночи`,
    `секретов`,
    `потерь`,
    `забывчивости`,
  ],
  nameAlt: [
    `Владычица Ночи`,
    `Госпожа Ночи`,
    `Госпожа Утрат`,
    `Тёмная Леди`,
    `Наша Леди Потерь`,
    `Ночная Певунья`,
  ],
  description: [
    {
      header: 'Шар',
      text: `Тёмная сестра-близнец [Селунэ](GOD:${GOD_SELUNE}) — Шар, богиня тьмы и в физической форме, и в душах и умах смертных. Люди поклоняются Шар как богине ночи, секретов, потерь и забывчивости. Она символизирует боль, которая спрятана, но не забыта, и месть, которая тщательно готовится в тенях. Говорят, у неё есть сила заставить человека забыть о боли или совладать с утратой, и многие отчаявшиеся люди просят Шар как раз о таком благословении.
  
  Шар почитаема теми, кто отправляется во тьму и потому просит о её защите, например, шахтёры, а также теми, кто впал в меланхолию и отчаяние, кто хочет забыть о чём-то, или кто потерял что-то и хочет найти. Жрецы, решившие служить Шар, часто сами пытаются исцелить какую-то свою рану, или хранят тёмные секреты, что, по их мнению, дает им право проповедовать тем, кто страдает от того же.
  
  В течение мировой истории последователи Шар творили много тёмных дел во имя неё, наиболее известный пример — шадовары Нетерила, целое сообщество, посвященное Шар. Трагедии и потери, вызванные фанатизмом её последователей, привели к запрету на поклонение ей во многих местах, так что многие из её священников совершают свои ритуалы тайком, но такие запреты только ожесточают верующих против местных властей и делают этих верующих наиболее вероятным очагом восстаний и мести против правящих сил.`,
      source: {
        id: SOURCE_SCAG,
        page: 37,
      },
    },
    {
      header: 'Неприкаянная',
      text: `При жизни служение её было безупречно. Каждый свой день она полностью посвящала Госпоже Утрат. С каждым днём освобождала она себя от гнёта памяти. Со временем она утратила всё — связи, собственные предпочтения; даже своё имя. На алтарь своей богини она возложила последнюю и самую великую жертву — свой опустошённый разум.

Когда же она умерла и очнулась уже в смерти, то огляделась и увидела вокруг блеклый и унылый Град Осуждённых. Тогда она стала ждать, когда Госпожа Утрат явится за нею. Мимо сплошной серой вереницей шли души; их были тысячи и миллионы, но никто не вложил свою руку в её; тихий голос не подсказал ей, куда идти; в сером пустом небе не было знамения. Время, само нематериальное время текло вокруг неё, подобно воздуху, а богиня так и не явилась за своей верной последовательницей.

[Келемвору](GOD:${GOD_KELEMVOR}) было жаль её — насколько [Владыка Смерти](GOD:${GOD_KELEMVOR}) вообще способен на жалость — но он был не властен вмешаться. Жрица Госпожи Утрат, как бы ни была она достойна своей богини, так и осталась неприкаянной. При жизни она предала всё забвению, но теперь ей предстояло усвоить новый урок — каково быть забытой самой.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_Ни кошмары, ни добрые сны_

_Сквозь покровы её не видны._

_Боль и горести снимет — _

_Но и радость отнимет._

_Ночи Шар непроглядно темны._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Учение об утрате: Ночная певунья`,
      text: `Что может заставить Песню Ночи затихнуть? Только сама ночная Певунья  — Шар. Владычица Ночи. Госпожа Утрат. Много у неё имён, но цель её есть сама простота: свет был ошибкой, жизнь — иллюзия, нестройный хор лжи, нарушающий покой забвения. Уверуйте в Шар. Позвольте ей заглушить лживую песнь в вашем сердце и вернуть вас в свои объятья. Всё, что вам нужно — это пустота. Вечная утроба, где вы будете во тьме и в безопасности, вместе с Матерью Шар.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Учение об утрате: забвение`,
      text: `Пустота есть священное состояние — ей следует восхищаться, к ней следует стремиться. Избавить себя ото всех чувств, всех привязанностей — значит прийти к чистоте объятий Владычицы Шар. Каждому из нас приходится идти по своему пути, дабы отвернуться от искушений жизни и света. Помните, что все эти ложные утешения в конце концов вас предадут. Останется лишь священная тьма.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Учение об утрате: любовь света`,
      text: `Любовь есть самая жестокая ложь, что используют враги Владычицы Шар. Любовь есть болезнь — она пленяет сердца на всю жизнь из-за мимолетной, скоротечной искры. Любовь есть пожар — разрушительное пламя, дающее мимолётный свет и утешение тому, кто его подпитывает, оскорбляя при этом холодную вечную тьму Владычицы Шар. Потушите огонь любви: он опалит вас своим жаром, удушит едким дымом и в конце оставит вас ни с чем, кроме остывших углей и голодного сердца.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Учение об утрате: тёмный юстициар`,
      text: `Нет у Владычицы Шар слуг более ревностных и преданных, нежели тёмные юстициары. Они её самые верные, самые безжалостные воины — обученные по её методам, пропитанные её священными доктринами. Каждый неофит должен забрать жизнь [селунита](GOD:${GOD_SELUNE}) — только после он или она сможет называть себя тёмным юстициаром. Таким образом мы можем быть уверены, что каждый тёмный юстициар окропил свой клинок кровью врагов Владычицы Шар.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Учение об утрате: собственный секрет`,
      text: `Владычица Шар знает, что вы храните, зарыв глубоко в душе своей. Она знает, что причиняет вам боль, что грызёт самую вашу сущность. Доверьте ей все свои недостатки. Позвольте ей возложить её исцеляющие ладони забвения поверх ваших ран. Дайте ей познать вас полностью, дабы смогла она освободить вас от ваших оков.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Учение тьмы`,
      text: `_Вступительный текст, объясняющий, насколько Шар привлекательней и лучше всех иных божеств._

Все остальные так называемые боги — лжецы и шарлатаны, питающие слабость к дешёвым трюкам и простым ответам. Они пытаются очаровать сердца смертных обещаниями, которых не могут исполнить. Владычица Шар не делает так. Её слова честны, её дары просты: её объятие, что ждёт всех — вечная тьма. Чистейшее благо. Конец всей боли. То, что рано или поздно ждёт всех нас. Ничто больше не будет исеть значения, и роль детей Шар — приблизить понимание этого среди невежественных.

Выполняя священную работу Владычицы Шар, мы должны неизбежно причинять боль и страдания, но они необходимы лишь для открытия истины. Ибо мы вершим _добро_.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    ...seluneAndSharList,
  ],
  note: [
    seluneAndSharNote,
    {
      text: `Быть невидимым — значит всюду быть желанным гостем`,
      author: 'Слова коварства Владычицы Шар',
    },
    {
      text: `Мягкий шаг лучше любой брони`,
      author: 'Слова мудрости Владычицы Шар',
    },
    {
      text: `Не ищи разрешения — бери всё, что тебе выгодно`,
      author: 'Слова дерзости Владычицы Шар',
    },
  ],
  alignmentId: ALIGNMENT_NE,
  symbolList: 'Чёрный диск с фиолетовым контуром',
  domainIdList: [DOMAIN_TRICKERY, DOMAIN_DEATH],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 37,
    },
  ],
}
