const {ALIGNMENT_LE} = require('./../../aligmentList')
const {DOMAIN_TRICKERY} = require('./../../domainList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_TIAMAT} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {tiamatDescriptionList} = require('./../../textCommonParts')
const {SOURCE_PHB} = require('./../../sourceList')

module.exports = {
  id: GOD_TIAMAT,
  nameEn: 'Tiamat',
  nameAlt: [
    'Королева драконов',
    'Многоглавая',
  ],
  nameShort: {
    nominative: `Тиамат`,
    genitive: `Тиамат`,
    dative: `Тиамат`,
    accusative: `Тиамат`,
    instrumental: `Тиамат`,
    prepositional: `Тиамат`,
  },
  worshipperRace: `драконов`,
  godOfWhat: [
    `жадности`,
    `зла`,
  ],
  description: tiamatDescriptionList,
  alignmentId: ALIGNMENT_LE,
  symbolList: `Драконья голова с пятью метками от когтей`,
  domainIdList: DOMAIN_TRICKERY,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_PHB,
    page: 297,
  },
}
