const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_TALOS,
} = require('./../../../../godIdList')

const Talos_Symbol_SCAG_img = require('./../../../../../images/gods/talos-symbol-SCAG.png')
const Talos_Symbol_DND3_FnP_img = require('./../../../../../images/gods/talos-symbol-DND3_FnP.jpg')
const Talos_DND3_FnP_img = require('./../../../../../images/gods/talos-DND3_FnP.webp')

module.exports = {
  [GOD_TALOS]: [
    {
      src: Talos_Symbol_SCAG_img,
      text: 'Символ Талоса',
      source: {
        id: SOURCE_SCAG,
        page: 39,
      },
    },
    {
      src: Talos_Symbol_DND3_FnP_img,
      text: 'Символ Талоса',
      source: {
        id: SOURCE_DND3_FnP,
        page: 69,
      },
    },
    {
      src: Talos_DND3_FnP_img,
      text: 'Талос Штормовой Лорд',
      source: {
        id: SOURCE_DND3_FnP,
        page: 70,
      },
    },
  ],
}
