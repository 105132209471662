const godsOfEvil = require('./../constants/godsOfEvil')
const {ALIGNMENT_LE} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_LADUGUER} = require('./../../godIdList')
const {PANTHEON_MORNDINSAMMAN} = require('./../../pantheonList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  DOMAIN_ARCANA,
  DOMAIN_DEATH,
} = require('./../../domainList')

module.exports = {
  id: GOD_LADUGUER,
  nameEn: 'Laduguer',
  nameShort: {
    nominative: `Ладугуэр`,
    genitive: `Ладугуэра`,
    dative: `Ладугуэру`,
    accusative: `Ладугуэра`,
    instrumental: `Ладугуэром`,
    prepositional: `Ладугуэре`,
  },
  godOfWhat: [
    `магии`,
    `ремёсел`,
    `рабства`,
  ],
  nameAlt: 'Изгнанник',
  alignmentId: ALIGNMENT_LE,
  symbolList: 'Сломанная стрела',
  description: [
    godsOfEvil,
    {
      header: `Ладугуэр`,
      text: `Ладугуэр Изгнанник — бог-тиран двергаров. Его жрецы обладают неограниченной властью и правят железной рукой, насаждая среди двергаров холодную, грубую жестокость.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `О праздности, чревоугодии и пороке`,
      text: `_«„ТЕРПИ! ТЕРПИ! ТЕРПИ!“_

_Так повелевает Ладугуэр-погонщик. „ТЕРПИ!“ — ори это во всю глотку, когда твоя кирка долбит породу. „ТЕРПИ!“ — вопи это, когда твоя секира ломает вражеские черепа. „ТЕРПИ!“ — выкрикивай, когда твоя трость бьёт по спинам непослушных рабов. Тяжкий труд — наш долг и наше благословение! Тяжкий труд — награда, передаваемая сыновьям и слугам!_

_И вместе с тем берегись великого врага трудолюбия, бича, что ранит нас и всех наших — кружки. Из неё щедро льётся не только эль, но и праздность, чревоугодие и леность мысли. Пьяница — жернов на шее клана. Он слишком тяжёл, чтобы влачить его с собой, и слишком неподвижен, чтобы двигаться самому. Выброси его в Тёмное озеро! Пусть опустится на самое дно! И затем снова воскликни: „ТЕРПИ! ТЕРПИ! ТЕРПИ!“»_

— Это пространное писание, принадлежащее перу некоего Финриса Флэтрока, восхваляет воздержание и трудолюбие, предостерегая против желаний плоти.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  domainIdList: [DOMAIN_ARCANA, DOMAIN_DEATH],
  pantheonId: PANTHEON_MORNDINSAMMAN,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 23,
  },
}
