const {SOURCE_SCAG} = require('./../../sourceList')
const {PC_RACE_GNOME} = require('./../../pcRaceIdList')

module.exports = {
  header: 'Дамы Золотых Холмов',
  text: `Интересная деталь [гномьей](PC_RACE:${PC_RACE_GNOME}) культуры — в их пантеоне нет ни одной женщины. Легенда по этому поводу гласит, что загадочные Дамы Золотых Холмов отправились вместе выполнять какую-то задачу в древние времена и не вернулись до сих пор.

Истории разнятся, так же, как и задача, стоящая перед Дамами, от поисков и сбора примеров всех чудес и богатств Торила, до тайного плана помешать всему злому в мире используя свою анонимность как защиту, которая привела к тому, что мир с течением времени забыл даже их имена и личности. Про гномов, которые путешествуют вдали от дома, говорят, что они _«отправились искать Дам»_.`,
  source: {
    id: SOURCE_SCAG,
    page: 115,
  },
}
