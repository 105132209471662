const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_PHB} = require('./../../sourceList')
const {GENDER_MALE} = require('./../../genderList')
const {ALIGNMENT_LE} = require('./../../aligmentList')
const {GOD_SEKOLAH} = require('./../../godIdList')
const {
  DOMAIN_NATURE,
  DOMAIN_TEMPEST,
} = require('./../../domainList')

module.exports = {
  id: GOD_SEKOLAH,
  nameEn: 'Sekolah',
  nameShort: {
    nominative: `Секола`,
    genitive: `Секолы`,
    dative: `Секоле`,
    accusative: `Секолу`,
    instrumental: `Секолой`,
    prepositional: `Секоле`,
  },
  worshipperRace: `сахуагинов`,
  godOfWhat: [
    `охоты`,
  ],
  alignmentId: ALIGNMENT_LE,
  symbolList: `Акула`,
  domainIdList: [DOMAIN_TEMPEST, DOMAIN_NATURE],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PHB,
    page: 297,
  },
}
