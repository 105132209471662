const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_URDLEN,
} = require('./../../../../godIdList')
const {
  Baervan_Baravar_Callarduran_Flandal_Gaerdal_Garl_Segojan_Urdlen_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Urdlen_Symbol_DND3_FnP_img = require('./../../../../../images/gods/urdlen-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_URDLEN]: [
    {
      src: Urdlen_Symbol_DND3_FnP_img,
      text: 'Символ Урдлена',
      source: {
        id: SOURCE_DND3_FnP,
        page: 138,
      },
    },
    Baervan_Baravar_Callarduran_Flandal_Gaerdal_Garl_Segojan_Urdlen_DND3_FnP_data,
  ],
}
