const {ALIGNMENT_CE} = require('./../../aligmentList')
const {DOMAIN_DEATH} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_LAOGZED} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_PHB} = require('./../../sourceList')
const {laogzedDescription} = require('./../../textCommonParts')

module.exports = {
  id: GOD_LAOGZED,
  nameEn: 'Laogzed',
  nameShort: {
    nominative: `Леягзед`,
    genitive: `Леягзеда`,
    dative: `Леягзеду`,
    accusative: `Леягзеда`,
    instrumental: `Леягзедом`,
    prepositional: `Леягзеде`,
  },
  worshipperRace: `троглодитов`,
  godOfWhat: [
    `голода`,
  ],
  description: laogzedDescription,
  alignmentId: ALIGNMENT_CE,
  symbolList: `Изображение бога ящерицы/жабы`,
  domainIdList: DOMAIN_DEATH,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PHB,
    page: 297,
  },
}
