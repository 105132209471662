const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_DENDAR} = require('./../../godIdList')
const {godDendarDescriptionList} = require('./../../textCommonParts')
const {PANTHEON_SNAKE} = require('./../../pantheonList')

module.exports = {
  id: GOD_DENDAR,
  nameEn: 'Dendar',
  nameShort: {
    nominative: 'Дендар',
    genitive: 'Дендар',
    dative: 'Дендар',
    accusative: 'Дендар',
    instrumental: 'Дендар',
    prepositional: 'Дендар',
  },
  worshipperRace: `юань-ти`,
  nameAlt: [
    `Ночная Змея`,
    `Пожирательница Миров`,
  ],
  description: godDendarDescriptionList,
  pantheonId: PANTHEON_SNAKE,
  genderId: GENDER_FEMALE,
}
