const {
  SOURCE_DND3_FnP,
  SOURCE_MTG_CLB,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_SAVRAS,
} = require('./../../../../godIdList')
const {
  Shiallia_Nobanion_Savras_Red_Knight_Sharess_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Savras_Symbol_SCAG_img = require('./../../../../../images/gods/savras-symbol-SCAG.png')
const Savras_Symbol_DND3_FnP_img = require('./../../../../../images/gods/savras-symbol-DND3_FnP.jpg')
const Alaundo_MTG_CLB_img = require('./../../../../../images/gods/alaundo-mtg_clb.jpg')

module.exports = {
  [GOD_SAVRAS]: [
    {
      src: Savras_Symbol_SCAG_img,
      text: 'Символ Савраса',
      source: {
        id: SOURCE_SCAG,
        page: 37,
      },
    },
    {
      src: Savras_Symbol_DND3_FnP_img,
      text: 'Символ Савраса',
      source: {
        id: SOURCE_DND3_FnP,
        page: 105,
      },
    },
    {
      src: Alaundo_MTG_CLB_img,
      text: 'Алондо Провидец, служитель Савраса',
      source: {
        id: SOURCE_MTG_CLB,
      },
    },
    Shiallia_Nobanion_Savras_Red_Knight_Sharess_DND3_FnP_data,
  ],
}
