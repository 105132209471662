const {SOURCE_SCAG} = require('./../../sourceList')
const {PC_RACE_ELF} = require('./../../pcRaceIdList')
const {
  GOD_AERDRIE_FAENYA,
  GOD_CORELLON_LARETHIAN,
  GOD_HANALI_CELANIL,
  GOD_SEHANINE_MOONBOW,
} = require('./../../godIdList')

module.exports = {
  header: `Богиня Мудрости`,
  text: `Ангаррад, триединая богиня мудрости и неистовая мать-защитница [эльфийского](PC_RACE:${PC_RACE_ELF}) народа, супруга [Кореллона](GOD:${GOD_CORELLON_LARETHIAN}).

Три её аспекта: [Аэрдри Фэйниа](GOD:${GOD_AERDRIE_FAENYA}) — дикая богиня ветра и погоды, а также покровительница авариэль; [Ханали Селанил](GOD:${GOD_HANALI_CELANIL}), Прелестная Роза — богиня любви, красоты, искусства и очарования; и Тайна Лунного Света, серебряная [Сеанин Лунная Радуга](GOD:${GOD_SEHANINE_MOONBOW}), богиня загадок жизни, среди которых мистицизм, пророчество, смерть и сновидения.

В легендах, эти богини часто отделены от сущности Ангаррад и часто описываются как дочери или супруги [Коррелона](GOD:${GOD_CORELLON_LARETHIAN}).`,
  source: {
    id: SOURCE_SCAG,
    page: 108,
  },
}
