const elvenDarkGods = require('./../constants/elvenDarkGods')
const {ALIGNMENT_CG} = require('./../../aligmentList')
const {DOMAIN_TRICKERY} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_EREVAN_ILESERE} = require('./../../godIdList')
const {PANTHEON_SELDARINE} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_EREVAN_ILESERE,
  nameEn: 'Erevan Ilesere',
  nameShort: {
    nominative: `Эреван Илисир`,
    genitive: `Эревана Илисира`,
    dative: `Эревану Илисиру`,
    accusative: `Эревана Илисира`,
    instrumental: `Эреваном Илисиром`,
    prepositional: `Эреване Илисире`,
  },
  godOfWhat: [
    `шуток`,
    `озорства`,
  ],
  alignmentId: ALIGNMENT_CG,
  description: elvenDarkGods,
  symbolList: `Ассиметричная восьмиконечная звезда`,
  domainIdList: DOMAIN_TRICKERY,
  pantheonId: PANTHEON_SELDARINE,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 24,
  },
}
