const natureGods = require('./../constants/natureGods')
const {ALIGNMENT_CG} = require('./../../aligmentList')
const {DOMAIN_NATURE} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_RILLIFANE_RALLATHIL} = require('./../../godIdList')
const {PANTHEON_SELDARINE} = require('./../../pantheonList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_RILLIFANE_RALLATHIL,
  nameEn: 'Rillifane Rallathil',
  nameShort: {
    nominative: `Риллифэйн Раллатил`,
    genitive: `Риллифэйна Раллатиля`,
    dative: `Риллифэйну Раллатилю`,
    accusative: `Риллифэйна Раллатиля`,
    instrumental: `Риллифэйном Раллатилем`,
    prepositional: `Риллифэйне Раллатиле`,
  },
  worshipperRace: `лесных эльфов`,
  godOfWhat: [
    `природы`,
    `лесов`,
    `диких мест`,
    `друидов`,
  ],
  alignmentId: ALIGNMENT_CG,
  symbolList: `Дуб`,
  description: natureGods,
  domainIdList: DOMAIN_NATURE,
  pantheonId: PANTHEON_SELDARINE,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 297,
    },
    {
      id: SOURCE_SCAG,
      page: 24,
    },
  ],
}
