import React from 'react'

import godList from '@/constants/godList'
import Catalog from '@/components/Catalog'

import generateGodPageUrlById from '@/utils/generateGodPageUrlById'

import filterList from './constants/filterList'

const GodCatalogContainer = () => (
  <Catalog
    pageUrlGenerator={generateGodPageUrlById}
    pageTitle='Каталог богов Забытых Королевств'
    itemNameList={godList}
    itemList={godList}
    filterListOriginal={filterList}
  />
)

export default GodCatalogContainer
