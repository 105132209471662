const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_HIATEA} = require('./../../godIdList')
const {PANTHEON_GIANT} = require('./../../pantheonList')
const {SOURCE_MM} = require('./../../sourceList')
const {giantGodsCommonDescriptionList} = require('./../../textCommonParts')

module.exports = {
  id: GOD_HIATEA,
  nameEn: 'Hiatea',
  nameAlt: 'Хиатеа',
  nameShort: {
    nominative: `Хиатея`,
    genitive: `Хиатеи`,
    dative: `Хиатее`,
    accusative: `Хиатею`,
    instrumental: `Хиатеей`,
    prepositional: `Хиатее`,
  },
  godOfWhat: [
    `охоты`,
    `домашнего хозяйства`,
  ],
  description: [
    {
      text: `Хиатея — охотница и домохозяйка.`,
      source: {
        id: SOURCE_MM,
        page: 37,
      },
    },
    ...giantGodsCommonDescriptionList,
  ],
  pantheonId: PANTHEON_GIANT,
  genderId: GENDER_FEMALE,
}
