import React from 'react'

import SourceInfo from '@/components/SourceInfo'
import DescriptionList from "@/components/DescriptionList"
import PageHeader from '@/components/PageHeader'
import Note from '@/components/Note'

import DomainList from './components/DomainList'
import GodImageList from './components/GodImageList'
import Symbol from './components/Symbol'

import './GodStyles.less'

const GodComponent = (
  {
    alignmentText,
    description,
    domainIdList,
    god,
    header,
    jobText,
    note,
    pantheonText,
    source,
    subHeader,
    symbolList,
    ...rest
  },
) => (
  <section className='God'>
    <section className='God_infoBlock'>
      <PageHeader
        className='God_header'
        {...rest}
      />

      {
        alignmentText
          ? <p className='God_alignment'>{alignmentText}</p>
          : null
      }

      <p className='God_job'>{jobText}</p>
      <p className='God_pantheon'>{pantheonText}</p>
      <DomainList domainId={domainIdList}/>
      <Symbol list={symbolList}/>
      <SourceInfo
        className='God_source'
        source={source}
        useFullName
      />
    </section>
    <Note
      note={note}
      className='God_note'
    />
    <DescriptionList
      {...god}
      description={description}
    />
    <GodImageList id={god.id}/>
  </section>
)

export default GodComponent
