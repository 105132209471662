const blessedSisters = require('./../constants/blessedSisters')
const {ALIGNMENT_N} = require('./../../aligmentList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_SHEELA_PERYROYL} = require('./../../godIdList')
const {PANTHEON_HIN} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  DOMAIN_NATURE,
  DOMAIN_TEMPEST,
} = require('./../../domainList')

module.exports = {
  id: GOD_SHEELA_PERYROYL,
  nameEn: 'Sheela Peryroyl',
  nameShort: {
    nominative: `Шила Периройл`,
    genitive: `Шилы Периройл`,
    dative: `Шиле Периройл`,
    accusative: `Шилу Периройл`,
    instrumental: `Шилой Периройл`,
    prepositional: `Шиле Периройл`,
  },
  godOfWhat: [
    `земледелия`,
    `погоды`,
    `природы`,
    `леди полей`,
    `равнин`,
    `ручьёв`,
    `долин`,
    `погоды`,
    `любви`,
    `песен`,
    `танцев`,
  ],
  alignmentId: ALIGNMENT_N,
  symbolList: `Цветок`,
  nameAlt: `Зелёная Сестра`,
  description: blessedSisters,
  domainIdList: [DOMAIN_TEMPEST, DOMAIN_NATURE],
  pantheonId: PANTHEON_HIN,
  genderId: GENDER_FEMALE,
  source: {
    id: SOURCE_SCAG,
    page: 24,
  },
}
