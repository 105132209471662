module.exports = {
  ...require('./labelas_enoreth'),
  ...require('./laduguer'),
  ...require('./lathander'),
  ...require('./leira'),
  ...require('./llira'),
  ...require('./lolth'),
  ...require('./loviatar'),
  ...require('./luthic'),
}
