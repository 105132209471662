const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_EREVAN_ILESERE,
} = require('./../../../../godIdList')
const {
  Angharradh_Aerdrie_Corellon_Fenmarel_Sashelas_Erevan_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Erevan_Ilesere_Symbol_DND3_FnP_img = require('./../../../../../images/gods/erevan_ilesere-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_EREVAN_ILESERE]: [
    {
      src: Erevan_Ilesere_Symbol_DND3_FnP_img,
      text: 'Символ Эревана Илисира',
      source: {
        id: SOURCE_DND3_FnP,
        page: 128,
      },
    },
    Angharradh_Aerdrie_Corellon_Fenmarel_Sashelas_Erevan_DND3_FnP_data,
  ],
}
