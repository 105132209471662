const blessedSisters = require('./../constants/blessedSisters')
const garl_and_yondalla = require('./../constants/garl_and_yondalla')
const {ALIGNMENT_LG} = require('./../../aligmentList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_YONDALLA} = require('./../../godIdList')
const {PANTHEON_HIN} = require('./../../pantheonList')
const {PC_RACE_HALFLING} = require('./../../pcRaceIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  DOMAIN_LIFE,
  DOMAIN_PROTECTION,
} = require('./../../domainList')

// TODO
module.exports = {
  id: GOD_YONDALLA,
  nameEn: 'Yondalla',
  nameShort: {
    nominative: `Йондалла`,
    genitive: `Йондаллы`,
    dative: `Йондалле`,
    accusative: `Йондаллу`,
    instrumental: `Йондаллой`,
    prepositional: `Йондалле`,
  },
  godOfWhat: [
    `плодородия`,
    `защиты`,
    `щедрости`,
    `защитница тепла`,
    `дома`,
    `семьи`,
  ],
  nameAlt: [
    'Богиня-мать',
    'Благословенная',
    'Мать-защитница',
    'Владетельная созидательница',
  ],
  description: [
    blessedSisters,
    {
      header: `Йондалла`,
      text: `Йондалла — богиня-мать всего пантеона [полуросликов](PC_RACE:${PC_RACE_HALFLING}). Она добра и прямодушна, а её последователи защищают родной дом, домашний очаг и всю природу.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Мать полуросликов`,
      text: `Была на свете община молодых и ужасно довольных собой [полуросликов](PC_RACE:${PC_RACE_HALFLING}), которые звали себя Лурмилаками. Все они были ещё юными и только недавно собрались в общину: каждый был силён и полон энергии, чтобы вносить свой вклад в постройку, уборку, штопку, починку (и так далее — у [полуросликов](PC_RACE:${PC_RACE_HALFLING}) множество подобных занятий).

Так что Лурмилаки не знали, что и делать, когда к ним в общину вдруг попросилась старая женщина. В конце концов они вежливо, но твёрдо отказали ей, сказав, что ей здесь не рады, и тогда женщина ушла в близлежащий лес. Конечно, [полурослики](PC_RACE:${PC_RACE_HALFLING}) не могли знать, что она собирается там поселиться, поэтому не думайте о них слишком плохо. Но вышло именно так: старуха отыскала укрытие и осталась в лесу. Скоро о ней узнали жившие в лесу оборотни и явились посмотреть, чем у неё можно поживиться. И тогда Йондалла ниспослала женщине волшебные спицы-скороходы!

Старушка мигом связала для оборотней уютные наряды, которые расширялись, чтобы соответствовать их ежемесячным изменениям. Тогда оборотни проводили женщину обратно в поселение Лурмилаков, и тем пришлось пришлось принять её — а также вытерпеть свою далю упрёков и тумаков от оборотней, которым совсем понравилось, как [полурослики](PC_RACE:${PC_RACE_HALFLING}) обошлись с такой умной старушкой!`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    garl_and_yondalla,
  ],
  alignmentId: ALIGNMENT_LG,
  symbolList: [
    `щит`,
    `рог изобилия на щите`,
  ],
  domainIdList: [DOMAIN_LIFE, DOMAIN_PROTECTION],
  pantheonId: PANTHEON_HIN,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 297,
    },
    {
      id: SOURCE_SCAG,
      page: 24,
    },
  ],
}
