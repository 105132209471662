const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_MASK,
} = require('./../../../../godIdList')

const Mask_Symbol_SCAG_img = require('./../../../../../images/gods/mask-symbol-SCAG.png')
const Mask_Symbol_DND3_FnP_img = require('./../../../../../images/gods/mask-symbol-DND3_FnP.webp')
const Mask_DND3_FnP_img = require('./../../../../../images/gods/mask-DND3_FnP.jpg')

module.exports = {
  [GOD_MASK]: [
    {
      src: Mask_Symbol_SCAG_img,
      text: 'Символ Маска',
      source: {
        id: SOURCE_SCAG,
        page: 35,
      },
    },
    {
      src: Mask_Symbol_DND3_FnP_img,
      text: 'Символ Маска',
      source: {
        id: SOURCE_DND3_FnP,
        page: 47,
      },
    },
    {
      src: Mask_DND3_FnP_img,
      text: 'Маск, Мастер Воров',
      source: {
        id: SOURCE_DND3_FnP,
        page: 48,
      },
    },
  ],
}
