const godsOfBattle = require('./../constants/godsOfBattle')
const {ALIGNMENT_LG} = require('./../../aligmentList')
const {DOMAIN_WAR} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_GORM_GULTHYN} = require('./../../godIdList')
const {PANTHEON_MORNDINSAMMAN} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_GORM_GULTHYN,
  nameShort: {
    nominative: `Горм Галтин`,
    genitive: `Горма Галтина`,
    dative: `Горму Галтину`,
    accusative: `Горма Галтина`,
    instrumental: `Гормом Галтином`,
    prepositional: `Горме Галтине`,
  },
  godOfWhat: [
    `защиты`,
    `бдительности`,
    `осторожности`,
  ],
  nameEn: 'Gorm Gulthyn',
  nameAlt: [
    'Лорд бронзовой маски',
    'Огненные глаза',
  ],
  description: godsOfBattle,
  alignmentId: ALIGNMENT_LG,
  symbolList: 'Бронзовая полумаска',
  domainIdList: DOMAIN_WAR,
  pantheonId: PANTHEON_MORNDINSAMMAN,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 23,
  },
}
