const {
  SOURCE_DND3_FnP,
  SOURCE_SCAG,
} = require('./../../../../sourceList')
const {
  GOD_AURIL,
} = require('./../../../../godIdList')

const Auril_Symbol_SCAG_img = require('./../../../../../images/gods/auril-symbol-SCAG.webp')
const Auril_DND3_FnP_img = require('./../../../../../images/gods/auril-DND3_FnP.webp')

module.exports = {
  [GOD_AURIL]: [
    {
      src: Auril_Symbol_SCAG_img,
      text: 'Символ Ориль',
      source: {
        id: SOURCE_SCAG,
        page: 26,
      },
    },
    {
      src: Auril_DND3_FnP_img,
      text: 'Ориль, Морозная Дева',
      source: {
        id: SOURCE_DND3_FnP,
        page: 92,
      },
    },
  ],
}
