const {ALIGNMENT_CE} = require('./../../aligmentList')
const {DOMAIN_WAR} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_GROLANTOR} = require('./../../godIdList')
const {PANTHEON_GIANT} = require('./../../pantheonList')
const {SOURCE_PHB} = require('./../../sourceList')
const {
  giantGodsCommonDescriptionList,
  godGrolantorOnlyDescriptionList,
} = require('./../../textCommonParts')

module.exports = {
  id: GOD_GROLANTOR,
  nameEn: 'Grolantor',
  nameShort: {
    nominative: `Гролантор`,
    genitive: `Гролантора`,
    dative: `Гролантору`,
    accusative: `Гролантора`,
    instrumental: `Гролантором`,
    prepositional: `Гроланторе`,
  },
  worshipperRace: `холмовых великанов`,
  godOfWhat: [
    `войны`,
  ],
  alignmentId: ALIGNMENT_CE,
  description: [
    ...godGrolantorOnlyDescriptionList,
    ...giantGodsCommonDescriptionList,
  ],
  symbolList: `Деревянная дубинка`,
  domainIdList: DOMAIN_WAR,
  pantheonId: PANTHEON_GIANT,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PHB,
    page: 297,
  },
}
