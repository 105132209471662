const godsOfBattle = require('./../constants/godsOfBattle')
const {ALIGNMENT_LG} = require('./../../aligmentList')
const {DOMAIN_WAR} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_CLANGEDDIN} = require('./../../godIdList')
const {PANTHEON_MORNDINSAMMAN} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_CLANGEDDIN,
  nameEn: 'Clangeddin Silverbeard',
  nameShort: {
    nominative: `Клангеддин Сребробородый`,
    genitive: `Клангеддина Сребробородого`,
    dative: `Клангеддину Сребробородому`,
    accusative: `Клангеддина Сребробородого`,
    instrumental: `Клангеддином Сребробородым`,
    prepositional: `Клангеддине Сребробородом`,
  },
  godOfWhat: [
    `войны`,
    `доблести`,
  ],
  alignmentId: ALIGNMENT_LG,
  symbolList: 'Перекрещенные серебряные топоры',
  description: godsOfBattle,
  domainIdList: DOMAIN_WAR,
  pantheonId: PANTHEON_MORNDINSAMMAN,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 23,
  },
}
