const ladiesOfTheGoldenHills = require('./../constants/ladiesOfTheGoldenHills')
const wiseProtectors = require('./../constants/wiseProtectors')
const {ALIGNMENT_LG} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_GAERDAL_IRONHAND} = require('./../../godIdList')
const {PANTHEON_GNOME} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  DOMAIN_PEACE,
  DOMAIN_UNITY,
  DOMAIN_WAR,
} = require('./../../domainList')

module.exports = {
  id: GOD_GAERDAL_IRONHAND,
  nameEn: 'Gaerdal Ironhand',
  nameShort: {
    nominative: `Гаэрдал`,
    genitive: `Гаэрдала`,
    dative: `Гаэрдалу`,
    accusative: `Гаэрдала`,
    instrumental: `Гаэрдалом`,
    prepositional: `Гаэрдале`,
  },
  godOfWhat: [
    `работ по металлу`,
    `бдительности`,
    `военной защиты`,
  ],
  nameAlt: 'Железнорукий',
  description: [
    wiseProtectors,
    ladiesOfTheGoldenHills,
  ],
  alignmentId: ALIGNMENT_LG,
  symbolList: `железный браслет`,
  domainIdList: [DOMAIN_WAR, DOMAIN_UNITY, DOMAIN_PEACE],
  pantheonId: PANTHEON_GNOME,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 25,
  },
}
