const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_DIANCASTRA} = require('./../../godIdList')
const {PANTHEON_GIANT} = require('./../../pantheonList')
const {SOURCE_MM} = require('./../../sourceList')
const {giantGodsCommonDescriptionList} = require('./../../textCommonParts')

module.exports = {
  id: GOD_DIANCASTRA,
  nameEn: 'Diancastra',
  nameAlt: 'Даянкастра',
  nameShort: {
    nominative: 'Дианкастра',
    genitive: 'Дианкастры',
    dative: 'Дианкастре',
    accusative: 'Дианкастру',
    instrumental: 'Дианкастрой',
    prepositional: 'Дианкастре',
  },
  godOfWhat: [
    'обмана',
  ],
  description: [
    {
      text: `Дианкастра — импульсивная и высокомерная обманщица.`,
      source: {
        id: SOURCE_MM,
        page: 37,
      },
    },
    ...giantGodsCommonDescriptionList,
  ],
  pantheonId: PANTHEON_GIANT,
  genderId: GENDER_FEMALE,
}
