const {ALIGNMENT_NG} = require('./../../aligmentList')
const {DOMAIN_LIGHT} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  PC_RACE_ELF,
  PC_RACE_HALF_ELF,
  PC_RACE_HUMAN,
} = require('./../../pcRaceIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GOD_DENEIR,
  GOD_MILIL,
  GOD_OGHMA,
} = require('./../../godIdList')

module.exports = {
  id: GOD_MILIL,
  nameEn: 'Milil',
  nameShort: {
    nominative: 'Милил',
    genitive: 'Милила',
    dative: 'Милилу',
    accusative: 'Милила',
    instrumental: 'Милилом',
    prepositional: 'Милиле',
  },
  godOfWhat: [
    `поэзии`,
    `песен`,
  ],
  nameAlt: [
    `Лорд Песен`,
    `Истинная Рука Всемудрого Огмы`,
  ],
  description: [
    {
      header: `Милил`,
      text: `Милил — божество поэзии, красноречия и песен. Он покровитель творчества и вдохновения, песен, которые больше чем просто слова и музыка. Он олицетворяет мысль, законченную и полную — от начала до завершения, от замысла до исполнения. Милил почитается бардами и певцами. Всеми, кто произносит речь перед слушателями. Ищущие вдохновения на пути творчества также молятся ему.

Его изображают как статного [человека](PC_RACE:${PC_RACE_HUMAN}), иногда [эльфа](PC_RACE:${PC_RACE_ELF}), или даже [полуэльфа](PC_RACE:${PC_RACE_HALF_ELF}) (особенно в местах, где [полуэльфов](PC_RACE:${PC_RACE_HALF_ELF}) много, пр. — Агларонд). Его могут изображать старым или младым. Но узнать его всегда просто — благодаря пятиструнной арфе, из серебряных листьев, что он носит постоянно с собой. Милил — идеал для актёров. Он хорошо себя держит, уверен, обаятелен, фонтан вдохновения для всех, кто его слушает. У него отличный слух и память на все что, касается музыки, и он превосходен в импровизации.

Святыни, посвященные Милилу, чаще можно увидеть на площадках для представлений и в школах музыки. Будь то громадные концертные залы или небольшие помещения для песнопений — все они должны иметь прекрасную акустику. Служители Милила — покровители искусств, хорошие актеры, и нередко обучают этим наукам в его храмах и святынях.

Подобно [Денеиру](GOD:${GOD_DENEIR}), Милил временами считается помощником [Огмы](GOD:${GOD_OGHMA}). В этом роде он считается его левой рукой, известной как Истинная Рука [Огмы](GOD:${GOD_OGHMA}). Ни в коем случае это не умаляет [Денеира](GOD:${GOD_DENEIR}) — его правую руку. Вместо этого считается, что левая рука чаще ассоциируется с творчеством и верой, а величайшее искусство идет из понимания правды.`,
      source: {
        id: SOURCE_SCAG,
        page: 35,
      },
    },
    {
      header: `«А» — значит Азут, и другие боги`,
      text: `
_Лорд Милил по велению слова_

_Над стихиями властвует снова._

_Если очень тупой,_

_Ты тихонько подпой —_

_Завибрирует мира основа._

— Сборник детских стихов о божествах.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  alignmentId: ALIGNMENT_NG,
  symbolList: 'Пятиструнная арфа из листьев',
  domainIdList: DOMAIN_LIGHT,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 35,
    },
  ],
}
