const {ALIGNMENT_CG} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')
const {
  GOD_AURIL,
  GOD_UMBERLEE,
  GOD_TALOS,
  GOD_TEMPUS,
  GOD_VALKUR,
} = require('./../../godIdList')
const {
  DOMAIN_TEMPEST,
  DOMAIN_WAR,
} = require('./../../domainList')

module.exports = {
  id: GOD_VALKUR,
  nameEn: 'Valkur',
  nameShort: {
    nominative: 'Валькур',
    genitive: 'Валькура',
    dative: 'Валькуру',
    accusative: 'Валькура',
    instrumental: 'Валькуром',
    prepositional: 'Валькуре',
  },
  alignmentId: ALIGNMENT_CG,
  symbolList: 'Туча с тремя разрядами молнии',
  description: {
    header: 'Валькур, герой-бог северян',
    text: `Северяне поклоняются разным богам: [Ориль](GOD:${GOD_AURIL}), [Амберли](GOD:${GOD_UMBERLEE}), [Талосу](GOD:${GOD_TALOS}) и [Темпусу](GOD:${GOD_TEMPUS}), но могучего Валькура они считают самым важным из них. Этот бог-герой уникален для северян и воплощает качества, которые северяне уважают больше всего: ярость, хитрость, смелость, силу и навыки мореплавателя.`,
    source: {
      id: SOURCE_SCAG,
      page: 69,
    },
  },
  domainIdList: [DOMAIN_TEMPEST, DOMAIN_WAR],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 22,
  },
}
