const {SOURCE_SCAG} = require('./../../sourceList')
const {
  GOD_EREVAN_ILESERE,
  GOD_FENMAREL_MESTARINE,
  GOD_SHEVARASH,
} = require('./../../godIdList')

module.exports =  {
  header: 'Боги тени',
  text: `К более негативно окрашенным можно причислить [Эревана Илисира](GOD:${GOD_EREVAN_ILESERE}), бога-бедокура озорства, и [Фенмарела Местарина](GOD:${GOD_FENMAREL_MESTARINE}), капризного и угрюмого бога изгоев и отверженных, который почти не ведёт дел с остальной частью Селдарин (кроме [Эревана](GOD:${GOD_EREVAN_ILESERE}), который часто использует [Фенмарела](GOD:${GOD_FENMAREL_MESTARINE}), как козла отпущения в своих кознях и розыгрышах).

Также к ним можно отнести и [Шевараша](GOD:${GOD_SHEVARASH}), бога покровителя обиженных и обозлённых, к которому эльфы обращаются в поисках отмщения.`,
  source: {
    id: SOURCE_SCAG,
    page: 109,
  },
}
