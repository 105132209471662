const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_SELVETARM,
} = require('./../../../../godIdList')
const {
  Kiaransalee_Vhaeraun_Lolth_Selvetarm_Ghaunadaur_Eilistraee_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Selvetarm_Symbol_DND3_FnP_img = require('./../../../../../images/gods/selvetarm-symbol-DND3_FnP.jpg')

module.exports = {
  [GOD_SELVETARM]: [
    {
      src: Selvetarm_Symbol_DND3_FnP_img,
      text: 'Символ Селветарма',
      source: {
        id: SOURCE_DND3_FnP,
        page: 114,
      },
    },
    Kiaransalee_Vhaeraun_Lolth_Selvetarm_Ghaunadaur_Eilistraee_DND3_FnP_data,
  ],
}
