const {ALIGNMENT_CE} = require('./../../aligmentList')
const {DOMAIN_NATURE} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_MALAR} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

// TODO
module.exports = {
  id: GOD_MALAR,
  nameEn: 'Malar',
  nameShort: {
    nominative: 'Малар',
    genitive: 'Малара',
    dative: 'Малару',
    accusative: 'Малара',
    instrumental: 'Маларом',
    prepositional: 'Маларе',
  },
  godOfWhat: [
    `охоты`,
  ],
  nameAlt: [
    `Лорд Зверей`,
    `Чернокровный`,
  ],
  description: `Малар — тёмная сторона природы. Кровь, стекающая с клыков, и взгляд охотника на жертву. Для его верующих охота — это момент между жизнью и смертью. Встреча хищника и жертвы, вопрос, кто выживет и, кто — нет. Люди считают, что Малара нельзя задобрить, и в нем нет жалости к другим — молятся ему только в случае охоты. Подобные прошения обычно бывают по двум причинам — желание стать сильнее и умелее, или облечься в его устрашающую мантию, отпугнуть иных хищников. Малар почитаем теми, кто наслаждается охотой, не бежит от крови и смакует страх своей жертвы.

Малар также почитаем многими ликантропами, не менее чем другими разумными хищниками. Друиды, следопыты, дикие и хищные — среди его приверженцев. Варвары восхваляют его за жестокость и свирепость. Служители носят наручи, украшенные когтями, выпирающими острыми концами вперед — они служат церемониальным оружием.`,
  alignmentId: ALIGNMENT_CE,
  symbolList: 'Когтистая лапа',
  domainIdList: DOMAIN_NATURE,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 34,
    },
  ],
}
