const {ALIGNMENT_N} = require('./../../aligmentList')
const {DOMAIN_KNOWLEDGE} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_GRUMBAR} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_GRUMBAR,
  nameEn: 'Grumbar',
  nameShort: {
    nominative: 'Грумбар',
    genitive: 'Грумбара',
    dative: 'Грумбару',
    accusative: 'Грумбара',
    instrumental: 'Грумбаром',
    prepositional: 'Грумбаре',
  },
  godOfWhat: [
    `земли`,
  ],
  alignmentId: ALIGNMENT_N,
  symbolList: 'Гора',
  domainIdList: DOMAIN_KNOWLEDGE,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 22,
  },
}
