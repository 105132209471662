const {ALIGNMENT_LG} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_BAHAMUT} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_PHB} = require('./../../sourceList')
const {bahamutDescriptionList} = require('./../../textCommonParts')
const {
  DOMAIN_LIFE,
  DOMAIN_PROTECTION,
  DOMAIN_WAR,
} = require('./../../domainList')

module.exports = {
  id: GOD_BAHAMUT,
  nameEn: 'Bahamut',
  nameShort: {
    nominative: `Бахамут`,
    genitive: `Бахамута`,
    dative: `Бахамуту`,
    accusative: `Бахамута`,
    instrumental: `Бахамутом`,
    prepositional: `Бахамуте`,
  },
  nameAlt: [
    'Ангел Семи Небес',
    'Платиновый дракон',
    'Король добрых драконов',
  ],
  worshipperRace: `драконов`,
  godOfWhat: [
    `добра`,
    `правосудия`,
  ],
  description: bahamutDescriptionList,
  alignmentId: ALIGNMENT_LG,
  symbolList: `Профиль драконьей головы`,
  domainIdList: [DOMAIN_LIFE, DOMAIN_PROTECTION, DOMAIN_WAR],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PHB,
    page: 297,
  },
}
