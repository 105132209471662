const {ALIGNMENT_CE} = require('./../../aligmentList')
const {DOMAIN_TRICKERY} = require('./../../domainList')
const {GENDER_FEMALE} = require('./../../genderList')
const {
  GOD_CORELLON_LARETHIAN,
  GOD_LOLTH,
} = require('./../../godIdList')
const {PANTHEON_DROW} = require('./../../pantheonList')
const {lolthDescriptionList} = require('./../../textCommonParts')
const {CREATURE_DROW_PRIESTESS_OF_LOLTH} = require('./../../creatureIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_LOLTH,
  nameEn: 'Lolth',
  nameShort: {
    nominative: `Ллос`,
    genitive: `Ллос`,
    dative: `Ллос`,
    accusative: `Ллос`,
    instrumental: `Ллос`,
    prepositional: `Ллос`,
  },
  godOfWhat: [
    `пауков`,
  ],
  nameAlt: [
    `Паучья Королева`,
    `Демоническая Королева Пауков`,
  ],
  description: [
    {
      header: 'Паучья Королева',
      text: `Ллос, Демоническая Королева Пауков, занимает господствующее положение в пантеоне дроу, безжалостно уничтожая всех, кто посягает на её положение. Её жрицы поступают также с культами богов-соперников их народа.`,
      source: {
        id: SOURCE_SCAG,
        page: 109,
      },
    },
    {
      header: `Ллос`,
      text: `Всеми ненавидимая богиня-мать пантеона дроу. Она властвует над Подземьем, пауками и злобными созданиями из демонических ям. Её главное стремление — соблазнить всех дроу, превратив в своих безжалостных фанатиков.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    {
      header: `Обучение верующих`,
      text: `_На обложке этой тонкой, качественно перетянутой книги, изображён паук на краю бездны_

(Отрывок из длинной речи Великой Владычицы Тарры, с которой та впервые выступила в Мензоберранзане, записано шпионом [Селдарин](GOD:${GOD_CORELLON_LARETHIAN}))

Вознесём хвалы Ллос, матери всех дроу. Её тенета связывают нас и душой и телом, н умерщвляют недостойных, прежде чем те успеют глотнуть воздуха.

Стоящие предо мной уже доказали что достойны. Именно вам надлежит преподавать закон Ллос новообращённым и любой ценой заставить их усвоить его. Многие из них страшатся нашей богини, как им и надлежит, однако же вера их пока ещё недостаточно крепка.

Они простоваты, и обращаться с ними нужно соответственно. Приказы любой [жрицы](CREATURE:${CREATURE_DROW_PRIESTESS_OF_LOLTH}) они должны выполнять беспрекословно, как если бы они исходили от самой богини. Они никогда не должны скрывать своих глаз и отвергать дар Ллос. Она должны всячески чтить дом, принявший их к себе и позволивший подняться из грязи. наши тайны они должны хранить под страхом смертной казни.

Казнь, если до этого дойдёт, должна быть публичной. Ллос благоволит тем, кто умеет сделать из пыток подлинное искусство.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
    ...lolthDescriptionList,
  ],
  alignmentId: ALIGNMENT_CE,
  symbolList: `Паук`,
  domainIdList: DOMAIN_TRICKERY,
  pantheonId: PANTHEON_DROW,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 297,
    },
    {
      id: SOURCE_SCAG,
      page: 24,
    },
  ],
}
