const {ALIGNMENT_LG} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const tyrTormIlmater = require('./../constants/tyrTormIlmater')
const {
  DOMAIN_PROTECTION,
  DOMAIN_WAR,
} = require('./../../domainList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')
const {
  GOD_TORM,
  GOD_TYR,
} = require('./../../godIdList')

module.exports = {
  id: GOD_TORM,
  nameEn: 'Torm',
  nameShort: {
    nominative: 'Торм',
    genitive: 'Торма',
    dative: 'Торму',
    accusative: 'Торма',
    instrumental: 'Тормом',
    prepositional: 'Торме',
  },
  godOfWhat: [
    `долга`,
    `верности`,
    `отваги`,
    `самопожертвования`,
  ],
  nameAlt: [
    `Верная Ярость`,
    `Истинный`,
    `Рука Праведности`,
  ],
  description: [
    {
      header: `Торм`,
      text: `Торм является богом долга и верности, почитаем теми, кто сталкивается с опасностью во имя высшего блага. Верующие в Торма считают, что могут обрести спасение в услужении, что каждый отказ выполнить свой долг умаляет достоинство Торма, и что каждый успех усиливает его величие. Те, кто приняли Торма в своем сердце должны стремиться выполнить свою заповедь, выйти в мир и быть активной силой добра, помогая отчаявшимся и искореняя несправедливость. Они должны стремиться к поддержанию мира и порядка, выступая против несправедливых законов. Последователи Торма выступают против развращения общества, всегда готовые быстро и жестоко нанести удар против каких-либо признаков гнили в сердцах смертных. В качестве вооруженной руки справедливости, последователь Торма, дарует быструю смерть предателям. Принимая во внимание эти принципы, неудивительно, что большинство людей-паладинов выбрали Торма в качестве своего покровителя.

Большинство храмов, посвященных Торму представляют из себя расположенные на высотах крепости, вгрызшиеся в землю, с конюшнями и кельями для жителей и странствующих рыцарей. Белые гранитные стены, статуи львов и фигуры в доспехах со знаками рыцарей, павших при исполнении обязанностей, стоят вдоль стен залов.

Торм считается правой рукой [Тира](GOD:${GOD_TYR}), его символом является белая перчатка на правую руку.

Он представляет собой мечевую руку [Тира](GOD:${GOD_TYR}), но это также символ терпимости. Торм часто изображается с его правой перчаткой раскрытой ладонью вперед, который прихожане называют Рукой Непоколебимости.

Это обозначает принцип, согласно которому, справедливость и правда должны сделать паузу, прежде чем действовать, чтобы взвесить, отстаивают ли их намерения идеалы Торма. Храмы, общественных структур, а также в дома верующих часто украшены изображениями Руки Непоколебимости, в качестве постоянного напоминания этого принципа.

Прихожане Торма выходцы из большинства слоев общества, ибо он приветствует любого, кто ищет лучшее в себе и других, любого отстаивающего свои принципы верности, ответственности, долга и доброты. Тех, кто готов жертвовать, чтобы удержать усиливающиеся влияния зла в мире. Верующие знают, что все они будут спотыкаться время от времени, следуя по стопам Торма, но его священники учат, что позор незначительного падения от благодати является гораздо менее серьезным, чем при отказе подняться себя до стандартов Торма.`,
      source: {
        id: SOURCE_SCAG,
        page: 40,
      },
    },
    tyrTormIlmater,
  ],
  alignmentId: ALIGNMENT_LG,
  symbolList: 'Правая белая латная рукавица',
  domainIdList: [DOMAIN_PROTECTION, DOMAIN_WAR],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 40,
    },
  ],
}
