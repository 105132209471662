const {ALIGNMENT_LE} = require('./../../aligmentList')
const {DOMAIN_WAR} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_ILNEVAL} = require('./../../godIdList')
const {PANTHEON_ORC} = require('./../../pantheonList')
const {PC_RACE_HALF_ORC} = require('./../../pcRaceIdList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_ILNEVAL,
  nameEn: 'Ilneval',
  nameShort: {
    nominative: `Илневал`,
    genitive: `Илневала`,
    dative: `Илневалу`,
    accusative: `Илневала`,
    instrumental: `Илневалом`,
    prepositional: `Илневале`,
  },
  godOfWhat: [
    `стратегии`,
    `орд`,
    `войны`,
  ],
  nameAlt: 'Мастер Войны',
  alignmentId: ALIGNMENT_LE,
  description: {
    header: 'Лидер орды',
    text: `Покровитель [полуорков](PC_RACE:${PC_RACE_HALF_ORC}) — бог войны Илневал. Среди пантеона он известен как Мастер Войны, и ему поклоняются те, кто верят в мудрость атаки превосходящими силами.`,
    source: {
      id: SOURCE_SCAG,
      page: 119,
    },
  },
  symbolList: `поднятый окровавленный меч`,
  domainIdList: DOMAIN_WAR,
  pantheonId: PANTHEON_ORC,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 25,
  },
}
