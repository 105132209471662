module.exports = {
  ...require('./savras'),
  ...require('./segojan_earthcaller'),
  ...require('./sehanine_moonbow'),
  ...require('./selune'),
  ...require('./selvetarm'),
  ...require('./shar'),
  ...require('./shargaas'),
  ...require('./sharindlar'),
  ...require('./sheela_peryroyl'),
  ...require('./shevarash'),
  ...require('./silvanus'),
  ...require('./solonor_thelandira'),
  ...require('./sune'),
}
