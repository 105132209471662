const {SOURCE_SCAG} = require('./../../sourceList')
const {PC_RACE_GNOME} = require('./../../pcRaceIdList')
const {
  GOD_GARL_GLITTERGOLD,
  GOD_GAERDAL_IRONHAND,
} = require('./../../godIdList')

module.exports = {
  header: 'Мудрые защитники',
  text: `Бдительный Защитник, [Гарл Златоблеск](GOD:${GOD_GARL_GLITTERGOLD}) — царь гномьих богов, божество юмора, огранки драгоценных камней, защиты и обмана. Его проделки призваны защитить [гномов](PC_RACE:${PC_RACE_GNOME}) и научить его жертв смирению и мудрости.

Помощник Гарла, [Гаэрдал Железнорукий](GOD:${GOD_GAERDAL_IRONHAND}) — военный бог [гномов](PC_RACE:${PC_RACE_GNOME}), отвечающий за бдительность и военную защиту.`,
  source: {
    id: SOURCE_SCAG,
    page: 116,
  },
}
