const godsOfFarPlaces = require('./../constants/godsOfFarPlaces')
const {ALIGNMENT_CG} = require('./../../aligmentList')
const {DOMAIN_KNOWLEDGE} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_DUGMAREN_BRIGHTMANTLE} = require('./../../godIdList')
const {PANTHEON_MORNDINSAMMAN} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_DUGMAREN_BRIGHTMANTLE,
  nameEn: 'Dugmaren Brightmantle',
  nameShort: {
    nominative: `Дагмарен Светлая Мантия`,
    genitive: `Дагмарена Светлой Мантии`,
    dative: `Дагмарену Светлой Мантии`,
    accusative: `Дагмарена Светлую Мантию`,
    instrumental: `Дагмареном Светлой Мантией`,
    prepositional: `Дагмарене Светлой Мантии`,
  },
  godOfWhat: [
    `исследований`,
    `изобретений`,
    `открытий`,
  ],
  nameAlt: [
    `Блуждающий Исследователь`,
    `Сияние в Глазах`,
  ],
  alignmentId: ALIGNMENT_CG,
  symbolList: 'Открытая книга',
  description: godsOfFarPlaces,
  domainIdList: DOMAIN_KNOWLEDGE,
  pantheonId: PANTHEON_MORNDINSAMMAN,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_SCAG,
      page: 23,
    },
    {
      id: SOURCE_SCAG,
      page: 106,
    },
  ],
}
