const {ALIGNMENT_CG} = require('./../../aligmentList')
const {GENDER_FEMALE} = require('./../../genderList')
const {GOD_SUNE} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  DOMAIN_LIFE,
  DOMAIN_LIGHT,
} = require('./../../domainList')
const {
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_SUNE,
  nameEn: 'Sune',
  nameShort: {
    nominative: 'Сьюни',
    genitive: 'Сьюни',
    dative: 'Сьюни',
    accusative: 'Сьюни',
    instrumental: 'Сьюни',
    prepositional: 'Сьюни',
  },
  godOfWhat: [
    `любви`,
    `красоты`,
  ],
  nameAlt: [
    `Огневласая Леди`,
    `Леди Любви`,
    `Принцесса Страсти`,
  ],
  description: `Сьюни Огневласая — божество страсти и удовольствий. Она божество красоты в любых её проявлениях — не просто красивых видов, но и чарующих звуков, богатых вкусов и ароматов, и конечно же удовольствия от прикосновений, от любовных утех, до ощущения гладкости шёлка на коже. Поклоняющиеся ей ищут этих удовольствий в жизни, не только ради развлечений, но и потому, что полученный опыт удовольствий — это и есть прикосновение самой Сьюни.

Последователи Сьюни обладают репутацией гедонистов, коими они и являются до определённой степени. Более того, её жрецы всячески поощряют красоту мира. Для этого они создают произведения искусства, выступая как покровители многообещающих талантов или вкладывая в купцов, которые торгуют роскошными вещами с отдалёнными местами, которые никогда не видели сатина или не пробовали пряного вина.

Её жрецы считают привлекательность своим великим призванием, а потому все они обучены манерам, моде и мастерству косметики. Жрецы Сьюни воистину столь талантливы в создании прекрасной внешности, что многие из них гордятся своей способностью представать в образах ошеломительно прекрасных представителей обоих полов.

Но красота не только на поверхности, говорят Суниты. Она происходит из самого сердца и показывает истинное лицо хозяина всему миру, будь оно добрым или злым. Последователи Сьюни верят в романтику, победу истинной любви над всем и всегда следуют зову сердца. Пары, которым суждено быть вместе, невозможная любовь, гадкие утята, становящиеся прекрасными лебедями — всё это сфера влияния Сьюни.

Храмы посвящённые Сьюни распространены в людских землях, и часто служат общественными купальнями и местами отдыха. Храмы обычно выглядят, как хорошо освещённые салоны с зеркалами, где люди могут привести свой внешний вид в порядок, на людей посмотреть и себя показать. Там, где нет храма или в больших городах, где до ближайшего храма приходится далеко идти, небольшие святилища Сьюни располагаются на углах улиц. Эти места выглядят как зеркала под небольшими навесами, где каждый может прочесть молитву, пока будет прихорашиваться. Рядом может находиться полка с парфюмами и косметикой, чтобы даже те, кто не может себе этого позволить, смогли бы привести себя в порядок и почувствовать себя красивыми.`,
  alignmentId: ALIGNMENT_CG,
  symbolList: 'Лицо прекрасной огневласой женщины',
  domainIdList: [DOMAIN_LIFE, DOMAIN_LIGHT],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_FEMALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 38,
    },
  ],
}
