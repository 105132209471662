const {ALIGNMENT_N} = require('./../../aligmentList')
const {DOMAIN_KNOWLEDGE} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_OGHMA} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {
  PC_CLASS_ARTIFICER,
  PC_CLASS_BARD,
  PC_CLASS_CLERIC,
  PC_CLASS_WIZARD,
} = require('./../../pcClassIdList')
const {
  SOURCE_GAME_BG_3,
  SOURCE_PHB,
  SOURCE_SCAG,
} = require('./../../sourceList')

module.exports = {
  id: GOD_OGHMA,
  nameEn: 'Oghma',
  nameShort: {
    nominative: 'Огма',
    genitive: 'Огмы',
    dative: 'Огме',
    accusative: 'Огму',
    instrumental: 'Огмой',
    prepositional: 'Огме',
  },
  godOfWhat: [
    `вдохновения`,
    `знаний`,
    `изобретательности`,
  ],
  nameAlt: [
    `Переплётчик`,
    `Лорд Знаний`,
  ],
  description: [
    {
      header: `Огма`,
      text: `Огма — божество вдохновения, изобретений и знания. Но наиболее значимо в нем знание в самой чистой и неприкосновенной форме — идее. Преданные ему молятся афоризмами, восхваляющими её: _«У идеи нет веса, но она может сдвигать горы. У неё нет статуса, но она может править империями. Знание — дар смертному разуму, значимее любого инструмента. Перед сотворением — был замысел»_.

Приверженцы Огмы распространяют знания и грамотность так широко, как это только возможно, считая, что разум не должен быть скован невежеством, а значит всегда сможет помочь своим товарищам в нужде. Неудивительно, что последователи Огмы противостоят тем, кто поощряет обман, жульничество и невежество.

Представители многих профессий почитают Переплётчика: [волшебники](PC_CLASS:${PC_CLASS_WIZARD}), картографы, художники, [барды](PC_CLASS:${PC_CLASS_BARD}), клерки, [изобретатели](PC_CLASS:${PC_CLASS_ARTIFICER}), мудрецы, писари и множество прочих профессий, которые открывают, сохраняют и создают знания и учения. Учение Огмы, в определённый момент, было одним из немногих организованных верований Фаэруна, которое установило ортодоксальность вероучения и создало сеть храмов, следующих этой ортодоксальности. Раздоры Смутного Времени разорвали эту сеть и теперь веру поддерживают в ряде отдельных храмов или небольших их объединениях так, так же, как и в остальных верованиях.`,
      source: {
        id: SOURCE_SCAG,
        page: 36,
      },
    },
    {
      header: `Огма`,
      text: `Огма — бог вдохновения и изобретательности, он несёт в мир знания через своих служителей — [бардов](PC_CLASS:${PC_CLASS_BARD}) и [жрецов](PC_CLASS:${PC_CLASS_CLERIC}). В отличие от прочих богов, Огма позволяет жрецам придерживаться каких угодно моральных принципов.`,
      source: {
        id: SOURCE_GAME_BG_3,
      },
    },
  ],
  note: 'Знание — величайший инструмент смертного разума, превосходящий всё, что сделано смертными руками. Прежде чем хоть что-то сможет существовать, должна существовать идея',
  alignmentId: ALIGNMENT_N,
  symbolList: 'Чистый свиток',
  domainIdList: DOMAIN_KNOWLEDGE,
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: [
    {
      id: SOURCE_PHB,
      page: 295,
    },
    {
      id: SOURCE_SCAG,
      page: 22,
    },
    {
      id: SOURCE_SCAG,
      page: 36,
    },
  ],
}
