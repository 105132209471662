const natureGods = require('./../constants/natureGods')
const {ALIGNMENT_CG} = require('./../../aligmentList')
const {DOMAIN_WAR} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_SOLONOR_THELANDIRA} = require('./../../godIdList')
const {PANTHEON_SELDARINE} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_SOLONOR_THELANDIRA,
  nameEn: 'Solonor Thelandira',
  nameShort: {
    nominative: `Солонор Теландир`,
    genitive: `Солонора Теландира`,
    dative: `Солонору Теландиру`,
    accusative: `Солонора Теландира`,
    instrumental: `Солонором Теландиром`,
    prepositional: `Солоноре Теландире`,
  },
  godOfWhat: [
    `лучников`,
    `стрельбы из лука`,
    `охоты`,
    `знания леса`,
  ],
  alignmentId: ALIGNMENT_CG,
  symbolList: `серебряная стрела с зелёным оперением`,
  description: natureGods,
  domainIdList: DOMAIN_WAR,
  pantheonId: PANTHEON_SELDARINE,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 24,
  },
}
