const {GENDER_MALE} = require('./../../genderList')
const {GOD_IMIX} = require('./../../godIdList')
const {SOURCE_VGTM} = require('./../../sourceList')

module.exports = {
  id: GOD_IMIX,
  nameEn: 'Imix',
  nameShort: {
    nominative: 'Имикс',
    genitive: 'Имикса',
    dative: 'Имиксу',
    accusative: 'Имикса',
    instrumental: 'Имиксом',
    prepositional: 'Имиксе',
  },
  godOfWhat: [
    `огненных тритонов`,
  ],
  nameAlt: [
    `Лорд Огня`,
    `Принц Злого Огня`,
  ],
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_VGTM,
    page: 177,
  },
}
