const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_HAELA,
} = require('./../../../../godIdList')
const {
  Haela_Moradin_Laduguer_Sharindlar_Marthammer_Thard_Vergadain_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Haela_Symbol_DND3_FnP_img = require('./../../../../../images/gods/haela-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_HAELA]: [
    {
      src: Haela_Symbol_DND3_FnP_img,
      text: 'Символ Хаэлы Светлого Топора',
      source: {
        id: SOURCE_DND3_FnP,
        page: 120,
      },
    },
    Haela_Moradin_Laduguer_Sharindlar_Marthammer_Thard_Vergadain_DND3_FnP_data,
  ],
}
