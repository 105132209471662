module.exports = {
  ...require('./gaerdal_ironhand'),
  ...require('./garl_glittergold'),
  ...require('./ghaunadaur'),
  ...require('./gond'),
  ...require('./gorm_gulthyn'),
  ...require('./grumbar'),
  ...require('./gruumsh'),
  ...require('./gwaeron_windstrom'),
}
