const {ALIGNMENT_LE} = require('./../../aligmentList')
const {DOMAIN_WAR} = require('./../../domainList')
const {firstFamilyDescription} = require('./../../textCommonParts')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_BAHGTRU} = require('./../../godIdList')
const {PANTHEON_ORC} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_BAHGTRU,
  nameEn: 'Bahgtru',
  nameShort: {
    nominative: `Богтру`,
    genitive: `Богтру`,
    dative: `Богтру`,
    accusative: `Богтру`,
    instrumental: `Богтру`,
    prepositional: `Богтру`,
  },
  godOfWhat: [
    `чистой, грубой силы`,
  ],
  description: firstFamilyDescription,
  alignmentId: ALIGNMENT_LE,
  symbolList: `сломанная бедренная кость`,
  domainIdList: DOMAIN_WAR,
  pantheonId: PANTHEON_ORC,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 25,
  },
}
