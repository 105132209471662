const ladiesOfTheGoldenHills = require('./../constants/ladiesOfTheGoldenHills')
const craftsAndInventions = require('./../constants/craftsAndInventions')
const {ALIGNMENT_NG} = require('./../../aligmentList')
const {DOMAIN_KNOWLEDGE} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_FLANDAL_STEELSKIN} = require('./../../godIdList')
const {PANTHEON_GNOME} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_FLANDAL_STEELSKIN,
  nameEn: 'Flandal Steelskin',
  nameShort: {
    nominative: `Фландал`,
    genitive: `Фландала`,
    dative: `Фландалу`,
    accusative: `Фландала`,
    instrumental: `Фландалом`,
    prepositional: `Фландале`,
  },
  godOfWhat: [
    `работы по металлу`,
    `горнодобычи`,
    `кузнечного ремесла`,
    `физических улучшений`,
    `доброго здравия`,
  ],
  nameAlt: [
    'Стальнокожий',
    'Стальной Кузнец',
  ],
  description: [
    craftsAndInventions,
    ladiesOfTheGoldenHills,
  ],
  alignmentId: ALIGNMENT_NG,
  symbolList: `пылающий молот`,
  domainIdList: DOMAIN_KNOWLEDGE,
  pantheonId: PANTHEON_GNOME,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 25,
  },
}
