import React from 'react'

import { godCollection } from '@/constants/godList'
import { aligmentCollection } from '@/constants/aligmentList'
import { pantheonCollection } from '@/constants/pantheonList'

import generateNameStr from '@/utils/generateNameStr'
import formatAltName from '@/utils/formatAltName'

import GodComponent from './GodComponent'

export default ({ id }) => {
  if (id) {
    const god = godCollection[id]

    const {
      description,
      job: {
        nominative: jobText,
      },
      alignmentId,
      genderId,
      pantheonId,
      ...rest
    } = god

    const { header, subHeader } = generateNameStr(god)

    const alignmentText = alignmentId
      ? aligmentCollection[alignmentId].name[genderId].nominative
      : ''

    const {name: pantheonName, nameAlt: pantheonNameAlt} = pantheonId
      ? pantheonCollection[pantheonId]
      : {name: 'Нет'}
    const pantheonText = pantheonNameAlt
      ? `${pantheonName} ${formatAltName(pantheonNameAlt)}`
      : pantheonName

    return (
      <GodComponent
        header={header}
        subHeader={subHeader}
        god={god}
        alignmentText={alignmentText}
        description={description}
        jobText={jobText}
        pantheonText={`Пантеон: ${pantheonText}`}
        {...rest}
      />
    )
  }

  return null
}
