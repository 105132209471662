const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_GRUMBAR,
} = require('./../../../../godIdList')
const {
  Akadi_Gargauth_Eldath_Finder_Grumbar_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Grumbar_Symbol_DND3_FnP_img = require('./../../../../../images/gods/grumbar-symbol-DND3_FnP.jpg')

module.exports = {
  [GOD_GRUMBAR]: [
    {
      src: Grumbar_Symbol_DND3_FnP_img,
      text: 'Символ Грумбара',
      source: {
        id: SOURCE_DND3_FnP,
        page: 97,
      },
    },
    Akadi_Gargauth_Eldath_Finder_Grumbar_DND3_FnP_data,
  ],
}
