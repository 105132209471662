const godsOfNature = require('./../constants/godsOfNature')
const ladiesOfTheGoldenHills = require('./../constants/ladiesOfTheGoldenHills')
const {ALIGNMENT_NG} = require('./../../aligmentList')
const {DOMAIN_LIGHT} = require('./../../domainList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_SEGOJAN_EARTHCALLER} = require('./../../godIdList')
const {PANTHEON_GNOME} = require('./../../pantheonList')
const {SOURCE_SCAG} = require('./../../sourceList')

module.exports = {
  id: GOD_SEGOJAN_EARTHCALLER,
  nameEn: 'Segojan Earthcaller',
  nameShort: {
    nominative: `Сегожан`,
    genitive: `Сегожана`,
    dative: `Сегожану`,
    accusative: `Сегожана`,
    instrumental: `Сегожаном`,
    prepositional: `Сегожане`,
  },
  godOfWhat: [
    `мёртвых`,
    `земли`,
    `подземных пространств`,
    `нор`,
    `живущих в земле растений и животных`,
  ],
  nameAlt: 'Зовущий Землю',
  description: [
    godsOfNature,
    ladiesOfTheGoldenHills,
  ],
  alignmentId: ALIGNMENT_NG,
  symbolList: `сияющий самоцвет`,
  domainIdList: DOMAIN_LIGHT,
  pantheonId: PANTHEON_GNOME,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_SCAG,
    page: 25,
  },
}
