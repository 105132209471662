const {ALIGNMENT_N} = require('./../../aligmentList')
const {GENDER_MALE} = require('./../../genderList')
const {GOD_EADRO} = require('./../../godIdList')
const {PANTHEON_FORGOTTEN_REALMS} = require('./../../pantheonList')
const {SOURCE_PHB} = require('./../../sourceList')
const {
  DOMAIN_NATURE,
  DOMAIN_TEMPEST,
} = require('./../../domainList')

module.exports = {
  id: GOD_EADRO,
  nameEn: 'Eadro',
  nameShort: {
    nominative: `Идро`,
    genitive: `Идро`,
    dative: `Идро`,
    accusative: `Идро`,
    instrumental: `Идро`,
    prepositional: `Идро`,
  },
  worshipperRace: `мерфолков`,
  godOfWhat: [
    `морей`,
  ],
  alignmentId: ALIGNMENT_N,
  symbolList: `Спиральный узор`,
  domainIdList: [DOMAIN_TEMPEST, DOMAIN_NATURE],
  pantheonId: PANTHEON_FORGOTTEN_REALMS,
  genderId: GENDER_MALE,
  source: {
    id: SOURCE_PHB,
    page: 297,
  },
}
