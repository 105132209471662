const {SOURCE_SCAG} = require('./../../sourceList')
const {PC_RACE_GNOME} = require('./../../pcRaceIdList')
const {
  GOD_FLANDAL_STEELSKIN,
  GOD_NEBELUN,
} = require('./../../godIdList')

module.exports = {
  header: 'Ремёсла и изобретения',
  text: `[Фландал Стальнокожий](GOD:${GOD_FLANDAL_STEELSKIN}), бог горнодобычи и кузнечного ремесла, известен как Стальной Кузнец. Он также является богом физических улучшений и доброго здравия. 

Бесстрашный [Небелун Назойливый](GOD:${GOD_NEBELUN}), бог изобретений и удачи, почитаем многими [гномами](PC_RACE:${PC_RACE_GNOME}), несмотря на то, что он не считается одним из Лордов Золотых Холмов. _«Небелунова голова!»_ — часто восклицают [гномы](PC_RACE:${PC_RACE_GNOME}), открыв или изобретя что-то.`,
  source: {
    id: SOURCE_SCAG,
    page: 116,
  },
}
