const { GENDER_FEMALE, GENDER_MALE, GENDER_MIDDLE } = require('./../../genderList')

module.exports = {
  [GENDER_MALE]: {
    nominative: `бог`,
    genitive: `бога`,
    dative: `богу`,
    accusative: `бога`,
    instrumental: `богом`,
    prepositional: `боге`,
  },
  [GENDER_FEMALE]: {
    nominative: `богиня`,
    genitive: `богини`,
    dative: `богине`,
    accusative: `богиню`,
    instrumental: `богиней`,
    prepositional: `богине`,
  },
  [GENDER_MIDDLE]: {
    nominative: `божество`,
    genitive: `божества`,
    dative: `божеству`,
    accusative: `божество`,
    instrumental: `божеством`,
    prepositional: `божестве`,
  },
}
