const {
  SOURCE_DND3_FnP,
} = require('./../../../../sourceList')
const {
  GOD_BAERVAN_WILDWANDERER,
} = require('./../../../../godIdList')
const {
  Baervan_Baravar_Callarduran_Flandal_Gaerdal_Garl_Segojan_Urdlen_DND3_FnP_data,
} = require('./../../../commonImageCollection')

const Baervan_Symbol_DND3_FnP_img = require('./../../../../../images/gods/baervan_wildwanderer-symbol-DND3_FnP.webp')

module.exports = {
  [GOD_BAERVAN_WILDWANDERER]: [
    {
      src: Baervan_Symbol_DND3_FnP_img,
      text: 'Символ Баэрвана Дикого Странника',
      source: {
        id: SOURCE_DND3_FnP,
        page: 132,
      },
    },
    Baervan_Baravar_Callarduran_Flandal_Gaerdal_Garl_Segojan_Urdlen_DND3_FnP_data,
  ],
}
